import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import CategorizeDragDrop from './CategorizeDragDrop';
import MatchDragDrop from './MatchDragDrop';
import LabelDragDrop from './LabelDragDrop';
import SequenceDragDrop from './SequenceDragDrop';
import {
  DRAGDROP_TYPE_CATEGORIZE,
  DRAGDROP_TYPE_MATCH_IMAGE,
  DRAGDROP_TYPE_MATCH_TEXT,
  DRAGDROP_TYPE_SEQUENCE,
} from '../constants';

const renderDragDrop = (pageNumber, data, lastEdit, mediaBaseUrl) => {
  const type = data.get('type');

  switch (type) {
    case DRAGDROP_TYPE_CATEGORIZE.key:
      return <CategorizeDragDrop data={data} pageNumber={pageNumber} />;
    case DRAGDROP_TYPE_MATCH_TEXT.key:
      return <MatchDragDrop data={data} pageNumber={pageNumber} />;
    case DRAGDROP_TYPE_MATCH_IMAGE.key:
      return (
        <LabelDragDrop
          pageNumber={pageNumber}
          data={data}
          lastEdit={lastEdit}
          mediaBaseUrl={mediaBaseUrl}
        />
      );
    case DRAGDROP_TYPE_SEQUENCE.key:
      return (
        <SequenceDragDrop
          data={data}
          lastEdit={lastEdit}
          mediaBaseUrl={mediaBaseUrl}
          pageNumber={pageNumber}
        />
      );
    default:
      return type;
  }
};

const DragDropSection = ({
  pageNumber,
  data,
  lastEdit,
  mediaBaseUrl,
}) => (
  <section key={`page_${pageNumber}_dragdrop`} className="drag-drop">
    <form onSubmit={e => e.preventDefault()}>
      { renderDragDrop(pageNumber, data, lastEdit, mediaBaseUrl) }
    </form>
  </section>
);

DragDropSection.propTypes = {
  data: PropTypes.instanceOf(Map),
  lastEdit: PropTypes.number.isRequired,
  mediaBaseUrl: PropTypes.string.isRequired,
  pageNumber: PropTypes.number.isRequired,
};

DragDropSection.defaultProps = {
  data: Map(),
};

export default DragDropSection;
