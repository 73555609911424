import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { activateFilePicker } from 'aes-lesson-driver/src/redux/View/actions';
import { changeSlideCaption, deleteSlide } from '../redux/Edits/actions';

class SlidesEditor extends React.Component {
  onChangeCaption(index, event) {
    const { dispatch } = this.props;
    const { target: { value } } = event;
    dispatch.changeSlideCaption(index, value);
  }

  selectFile(index, field) {
    const { dispatch } = this.props;
    dispatch.activateFilePicker(['slides', index, field]);
  }

  deleteSlide(index) {
    const { dispatch } = this.props;
    dispatch.deleteSlide(index);
  }

  render() {
    const { slides, pageNumber } = this.props;
    return (
      <section className="slides">
        <ul>
          {slides.map(
            (values, index) => {
              const number = parseFloat(values.get('number'));
              const audio = values.get('audio');
              const image = values.get('image');
              const caption = values.get('caption');
              return (
                /* eslint-disable-next-line react/no-array-index-key */
                <li key={`page_${pageNumber}_slide_${index}`}>
                  <span>{number}</span>
                  <div>
                    <button type="button" onClick={() => this.selectFile(index, 'audio')}>
                      {audio}
                    </button>
                    <button type="button" onClick={() => this.selectFile(index, 'image')}>
                      {image}
                    </button>
                    <label htmlFor={`caption_${index}`}>
                      Alt Text
                      <input
                        id={`caption_${index}`}
                        name={`caption_${index}`}
                        defaultValue={caption}
                        onChange={e => this.onChangeCaption(index, e)}
                      />
                    </label>
                  </div>
                  <button type="button" className="delete" onClick={() => this.deleteSlide(index)}>
                    <i className="fas fa-trash-alt" />
                  </button>
                </li>
              );
            },
          )}
        </ul>
      </section>
    );
  }
}

SlidesEditor.propTypes = {
  dispatch: PropTypes.object.isRequired,
  slides: PropTypes.instanceOf(List).isRequired,
  // False flag
  /* eslint-disable react/no-unused-prop-types */
  pageSetKey: PropTypes.string.isRequired,
  pageNumber: PropTypes.string.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, { pageSetKey, pageNumber }) => ({
  dispatch: {
    activateFilePicker: fieldPath => dispatch(activateFilePicker(fieldPath)),
    deleteSlide: index => dispatch(deleteSlide(pageSetKey, pageNumber, index)),
    changeSlideCaption: (index, caption) => {
      dispatch(changeSlideCaption(pageSetKey, pageNumber, index, caption));
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SlidesEditor);
