import { fromJS, OrderedMap } from 'immutable';
import modules from './AesModules';

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const listModules = () => new Promise(resolve => resolve(OrderedMap(modules)));

export const getModuleUnitOutline = () => fetch(process.env.REACT_APP_MODULE_OUTLINE_URL)
  .then(result => result.json())
  .then(json => fromJS(json));

export const listPageSets = (moduleKey, apiKey) => fetch(`${baseURL}/pagesets/${moduleKey}`, {
  headers: { 'X-API-KEY': apiKey },
}).then(response => response.json())
  .then(json => fromJS(json));

export const listPageSetFiles = (moduleKey, pageSetKey, apiKey) => (
  fetch(`${baseURL}/pagesets/${moduleKey}/${pageSetKey}/files`,
    { headers: { 'X-API-KEY': apiKey } })
    .then(response => response.json())
    .then(json => fromJS(json))
);

export const loadPageSet = (moduleKey, pageSetKey, apiKey) => fetch(`${baseURL}/pagesets/${moduleKey}/${pageSetKey}`, {
  headers: { 'X-API-KEY': apiKey },
}).then(response => response.json())
  .then(json => fromJS(json).get('page_set'));

export const savePageSet = (moduleKey, pageSetKey, pageSet, apiKey) => (
  fetch(`${baseURL}/pagesets/${moduleKey}/${pageSetKey}`, {
    method: 'PUT',
    headers: {
      'X-API-KEY': apiKey,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ page_set: pageSet.toJS() }),
  }).then(response => response.json())
    .then(json => fromJS(json))
);

export const duplicatePageSet = (
  moduleKey,
  pageSetKey,
  newModuleKey,
  newModuleTitle,
  newUnitKey,
  newUnitTitle,
  newPageSetKey,
  newPageSetTitle,
  apiKey,
) => fetch(`${baseURL}/pagesets`, {
  method: 'POST',
  headers: {
    'X-API-KEY': apiKey,
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    copy_from_module_key: moduleKey,
    copy_from_page_set_key: pageSetKey,
    module_key: newModuleKey,
    module_title: newModuleTitle,
    unit_key: newUnitKey,
    unit_title: newUnitTitle,
    page_set_key: newPageSetKey,
    page_set_title: newPageSetTitle,
  }),
}).then(response => response.json())
  .then(json => fromJS(json));

export const publishPageSet = (moduleKey, pageSetKey, apiKey, aes = false) => {
  const url = aes
    ? `${baseURL}/pagesets/${moduleKey}/${pageSetKey}/publish?aes=true`
    : `${baseURL}/pagesets/${moduleKey}/${pageSetKey}/publish`;
  return fetch(
    url,
    { headers: { 'X-API-KEY': apiKey } },
  ).then(response => response.json())
    .then(json => fromJS(json));
};

export const uploadFile = (pageSetKey, file, apiKey) => {
  const data = new FormData();
  data.append('file', file);
  data.append('s3path', `${pageSetKey.toLowerCase()}/${file.name}`);
  return fetch(`${baseURL}/file/upload`, {
    method: 'POST',
    headers: {
      'X-API-KEY': apiKey,
    },
    body: data,
  }).then(response => response.json())
    .then(json => fromJS(json));
};
