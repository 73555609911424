import React from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';
import { getAnswer, getAttemptsList, getIncorrectAnswers } from '../redux/View/selectors';
import { checkAnswers, setAnswer } from '../redux/View/actions';
import { shuffleList } from '../util';
import DragDropTerm from './DragDropTerm';

const renderImage = (term, lastEdit, mediaBaseUrl) => {
  const imgSrc = term.get('image');
  if (imgSrc) {
    return (
      <section className="figure">
        <div className="figure-wrapper">
          <img src={`${mediaBaseUrl}/${imgSrc}?t=${lastEdit}`} alt="figure" />
        </div>
      </section>
    );
  }
  return null;
};

class SequenceDragDrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTerm: 0,
      freezeActiveTerm: false,
    };
  }

  componentDidMount() {
    const { answers, data, dispatch } = this.props;
    if (answers.isEmpty()) {
      const terms = data.get('terms') || List();
      const answersSeed = shuffleList(terms.map((term, index) => term.set('index', index)));
      dispatch.setAnswers(answersSeed);
    }
  }

  onHoverTarget(initiator, target) {
    const { answers, dispatch } = this.props;
    const initiatorIndex = answers.findIndex(termData => termData.get('index') === initiator.index);
    const targetIndex = answers.findIndex(termData => termData.get('index') === target.index);
    const newAnswers = answers
      .splice(initiatorIndex, 1)
      .splice(targetIndex, 0, Map(initiator));
    dispatch.setAnswers(newAnswers);
  }

  setActiveTerm(index) {
    const { freezeActiveTerm } = this.state;
    if (!freezeActiveTerm) {
      this.setState({ activeTerm: index });
    }
  }

  freezeActiveTerm(freezeActiveTerm) {
    this.setState({ freezeActiveTerm });
  }

  renderAnswerStatus() {
    const { answerIsCorrect, dispatch } = this.props;

    if (answerIsCorrect) {
      return (
        <div className="message success">
          <span>correct</span>
          &nbsp;
          <i aria-hidden="true" className="fas fa-check-square" />
        </div>
      );
    }

    return (
      <button type="button" className="check-answer" onClick={() => dispatch.checkAnswers()}>
        Check Answer
      </button>
    );
  }

  renderTerms(terms) {
    const { answers, attempts } = this.props;
    const showCorrect = !attempts.isEmpty();
    return (
      <ul>
        {answers.map((answerData, position) => {
          const index = answerData.get('index');
          const termData = terms.get(index);
          const term = termData && termData.get('term');
          const isAnswered = showCorrect && index === position;
          if (term) {
            return (
              <DragDropTerm
                key={`term_${index}_${terms.size}`}
                index={index}
                term={term}
                isAnswered={isAnswered}
                onHoverTerm={() => this.setActiveTerm(index)}
                onHoverTarget={(initiator, target) => this.onHoverTarget(initiator, target)}
                onBeginDrag={() => {
                  this.setActiveTerm(index);
                  this.freezeActiveTerm(true);
                }}
                onEndDrag={() => this.freezeActiveTerm(false)}
              />
            );
          }
          return null;
        })}
      </ul>
    );
  }

  render() {
    const { data, lastEdit, mediaBaseUrl } = this.props;
    const { activeTerm } = this.state;
    const terms = data.get('terms');

    if (terms) {
      return (
        <div className="layout-7525">
          {renderImage(terms.get(activeTerm), lastEdit, mediaBaseUrl)}
          <section className="terms sequence">
            {this.renderTerms(terms)}
            {this.renderAnswerStatus()}
          </section>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state, { pageNumber }) => ({
  answers: getAnswer(state, pageNumber, ['dragdrop']) || List(),
  answerIsCorrect: getIncorrectAnswers(state, pageNumber, ['dragdrop']).isEmpty(),
  attempts: getAttemptsList(state, pageNumber, ['dragdrop']),
});

const mapDispatchToProps = (dispatch, { pageNumber }) => ({
  dispatch: {
    checkAnswers: () => {
      dispatch(checkAnswers(pageNumber, ['dragdrop']));
    },
    setAnswers: (answers) => {
      dispatch(setAnswer(pageNumber, ['dragdrop'], answers));
    },
  },
});

SequenceDragDrop.propTypes = {
  answers: PropTypes.instanceOf(List).isRequired,
  answerIsCorrect: PropTypes.bool.isRequired,
  attempts: PropTypes.instanceOf(List).isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  dispatch: PropTypes.object.isRequired,
  lastEdit: PropTypes.number.isRequired,
  mediaBaseUrl: PropTypes.string.isRequired,
  /* eslint-disable react/no-unused-prop-types */
  pageNumber: PropTypes.number.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

export default connect(mapStateToProps, mapDispatchToProps)(SequenceDragDrop);
