import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { connect } from 'react-redux';
import AesMarkdown from '../components/AesMarkdown';
import Question from '../components/Question';
import {
  getAttemptsMap,
  getAnswersMap,
  getAllIncorrectAnswers,
} from '../redux/View/selectors';
import { checkAnswers, setAnswer } from '../redux/View/actions';

class QuestionSection extends React.Component {
  getAnswerIsCorrect(index) {
    const { incorrectAnswers } = this.props;
    return !incorrectAnswers.has(`${index}`);
  }

  setAnswer(index, answer) {
    const { dispatch } = this.props;
    dispatch.setAnswer(index, answer);
  }

  checkAnswer(index) {
    const { dispatch } = this.props;
    const answerIsCorrect = this.getAnswerIsCorrect(index);
    if (!answerIsCorrect) {
      dispatch.checkAnswer(index);
    }
  }

  renderQuestion(index, questionData) {
    const {
      answers,
      assessmentMode,
      attempts,
      mediaBaseUrl,
    } = this.props;
    const number = questionData.get('number');
    const key = `question_${number}`;
    const answer = answers.get(`${index}`);
    const answerIsCorrect = this.getAnswerIsCorrect(index);
    const questionAttempts = attempts.get(`${index}`) || List();
    const onSetAnswer = value => this.setAnswer(index, value);
    const onCheckAnswer = () => this.checkAnswer(index);

    return (
      <Question
        key={key}
        answer={answer}
        answerIsCorrect={answerIsCorrect}
        attempts={questionAttempts}
        hideStatus={assessmentMode}
        mediaBaseUrl={mediaBaseUrl}
        questionData={questionData}
        onCheckAnswer={onCheckAnswer}
        onSetAnswer={onSetAnswer}
      />
    );
  }

  render() {
    const { markdown, mediaBaseUrl, questions } = this.props;
    return (
      <section className="questions">
        <AesMarkdown source={markdown} mediaBaseUrl={mediaBaseUrl} />
        <form autoComplete="off" onSubmit={e => e.preventDefault()}>
          {questions.map(
            (questionData, index) => this.renderQuestion(index, questionData),
          )}
        </form>
      </section>
    );
  }
}

QuestionSection.propTypes = {
  answers: PropTypes.instanceOf(Map).isRequired,
  assessmentMode: PropTypes.bool,
  attempts: PropTypes.instanceOf(Map).isRequired,
  incorrectAnswers: PropTypes.instanceOf(Map).isRequired,
  dispatch: PropTypes.object.isRequired,
  markdown: PropTypes.string.isRequired,
  mediaBaseUrl: PropTypes.string.isRequired,
  questions: PropTypes.instanceOf(List),
  /* eslint-disable react/no-unused-prop-types */
  pageNumber: PropTypes.number.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

QuestionSection.defaultProps = {
  assessmentMode: false,
  questions: List(),
};

const mapStateToProps = (state, { pageNumber }) => ({
  answers: getAnswersMap(state, pageNumber, ['questions']),
  attempts: getAttemptsMap(state, pageNumber, ['questions']),
  incorrectAnswers: getAllIncorrectAnswers(state, pageNumber),
});

const mapDispatchToProps = (dispatch, { pageNumber }) => ({
  dispatch: {
    setAnswer: (index, answer) => {
      dispatch(setAnswer(pageNumber, ['questions', `${index}`], answer));
    },
    checkAnswer: (index) => {
      dispatch(checkAnswers(pageNumber, ['questions', `${index}`]));
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionSection);
