export const DATA_LIST_MODULES_REQUEST = 'DATA_LIST_MODULES_REQUEST';
export const DATA_LIST_MODULES_SUCCESS = 'DATA_LIST_MODULES_SUCCESS';

export const DATA_LIST_PAGE_SETS_REQUEST = 'DATA_LIST_PAGE_SETS_REQUEST';
export const DATA_LIST_PAGE_SETS_SUCCESS = 'DATA_LIST_PAGE_SETS_SUCCESS';
export const DATA_LIST_PAGE_SETS_FAILURE = 'DATA_LIST_PAGE_SETS_FAILURE';

export const DATA_LIST_PAGE_SET_FILES_REQUEST = 'DATA_LIST_PAGE_SET_FILES_REQUEST';
export const DATA_LIST_PAGE_SET_FILES_SUCCESS = 'DATA_LIST_PAGE_SET_FILES_SUCCESS';
export const DATA_LIST_PAGE_SET_FILES_FAILURE = 'DATA_LIST_PAGE_SET_FILES_FAILURE';

export const DATA_LOAD_PAGE_SET_REQUEST = 'DATA_LOAD_PAGE_SET_REQUEST';
export const DATA_LOAD_PAGE_SET_SUCCESS = 'DATA_LOAD_PAGE_SET_SUCCESS';
export const DATA_LOAD_PAGE_SET_FAILURE = 'DATA_LOAD_PAGE_SET_FAILURE';

export const DATA_LOAD_MODULE_OUTLINE_SUCCESS = 'DATA_LOAD_MODULE_OUTLINE_SUCCESS';
export const DATA_LOAD_MODULE_OUTLINE_FAILURE = 'DATA_LOAD_MODULE_OUTLINE_FAILURE';

export const DATA_SET_ROOT_URL = 'DATA_SET_ROOT_URL';

export const listModules = () => ({ type: DATA_LIST_MODULES_REQUEST });
export const listModulesSucceeded = result => ({ type: DATA_LIST_MODULES_SUCCESS, result });

export const listPageSets = moduleKey => ({ type: DATA_LIST_PAGE_SETS_REQUEST, moduleKey });
export const listPageSetsSucceeded = (moduleKey, result) => ({
  type: DATA_LIST_PAGE_SETS_SUCCESS,
  moduleKey,
  result,
});
export const listPageSetsFailed = message => ({ type: DATA_LIST_PAGE_SETS_FAILURE, message });

export const listPageSetFiles = (moduleKey, pageSetKey) => ({
  type: DATA_LIST_PAGE_SET_FILES_REQUEST,
  moduleKey,
  pageSetKey,
});

export const listPageSetFilesSucceeded = (pageSetKey, result) => ({
  type: DATA_LIST_PAGE_SET_FILES_SUCCESS,
  pageSetKey,
  result,
});

export const listPageSetFilesFailed = message => ({
  type: DATA_LIST_PAGE_SET_FILES_FAILURE,
  message,
});

export const loadModuleOutlineSucceeded = moduleOutline => ({
  type: DATA_LOAD_MODULE_OUTLINE_SUCCESS,
  moduleOutline,
});

export const loadModuleOutlineFailed = error => ({
  type: DATA_LOAD_MODULE_OUTLINE_FAILURE,
  error,
});

export const loadPageSet = (moduleKey, pageSetKey) => ({
  type: DATA_LOAD_PAGE_SET_REQUEST,
  moduleKey,
  pageSetKey,
});

export const loadPageSetJson = () => ({ type: DATA_LOAD_PAGE_SET_REQUEST });

export const loadPageSetSucceeded = (pageSetKey, result) => ({
  type: DATA_LOAD_PAGE_SET_SUCCESS,
  pageSetKey,
  result,
});

export const loadPageSetFailed = message => ({ type: DATA_LOAD_PAGE_SET_FAILURE, message });

export const setRootUrl = rootUrl => ({ type: DATA_SET_ROOT_URL, rootUrl });
