import { Map } from 'immutable';
import {
  DATA_LIST_MODULES_SUCCESS,
  DATA_LIST_PAGE_SETS_SUCCESS,
  DATA_LIST_PAGE_SETS_FAILURE,
  DATA_LOAD_MODULE_OUTLINE_SUCCESS,
  DATA_LOAD_PAGE_SET_SUCCESS,
  DATA_LOAD_PAGE_SET_FAILURE,
  DATA_LIST_PAGE_SET_FILES_SUCCESS,
  DATA_LIST_PAGE_SET_FILES_FAILURE,
  DATA_SET_ROOT_URL,
} from './actions';

const initialState = Map({
  modulesLoaded: false,
  modules: Map(),
  modulePageSets: Map(),
  pageSets: Map(),
  files: Map(),
});

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case (DATA_SET_ROOT_URL):
      return state.set('rootUrl', action.rootUrl);
    case (DATA_LIST_MODULES_SUCCESS):
      return state
        .set('modulesLoaded', true)
        .set('modules', action.result);

    case (DATA_LIST_PAGE_SETS_SUCCESS):
      return state
        .remove('modulePageSetsError')
        .set('pageSetsLoaded', true)
        .setIn(['modulePageSets', action.moduleKey], action.result);

    case (DATA_LIST_PAGE_SETS_FAILURE):
      return state.set('modulePageSetsError', action.message);

    case (DATA_LIST_PAGE_SET_FILES_SUCCESS):
      return state
        .remove('fileError')
        .setIn(['files', action.pageSetKey], action.result);

    case (DATA_LIST_PAGE_SET_FILES_FAILURE):
      return state.set('fileError', action.message);

    case (DATA_LOAD_MODULE_OUTLINE_SUCCESS):
      return state.set('moduleOutline', action.moduleOutline);

    case (DATA_LOAD_PAGE_SET_SUCCESS):
      return state
        .remove('pageSetError')
        .setIn(['pageSets', action.pageSetKey], action.result);

    case (DATA_LOAD_PAGE_SET_FAILURE):
      return state.set('pageSetError', action.message);

    default:
      return state;
  }
};

export default dataReducer;
