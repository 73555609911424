import { List, OrderedMap } from 'immutable';

/**
 * Perform Fisher-Yates-Knuth on Immutable.List
 * @param immutableList
 * @returns {[Immutable.List]}
 */
export const shuffleList = immutableList => immutableList.withMutations((mutableList) => {
  let tmp;
  let j;

  for (let i = mutableList.size; i > 1;) {
    j = Math.floor(Math.random() * i);
    i -= 1;
    tmp = mutableList.get(i);
    mutableList.set(i, mutableList.get(j));
    mutableList.set(j, tmp);
  }
});

/**
 * Perform Fisher-Yates-Knuth on Immutable.Map
 * @param immutableMap
 * @returns {[Immutable.List]}
 */
export const shuffleMap = immutableMap => OrderedMap(shuffleList(List(immutableMap)));

export const getUrlParameter = (param) => {
  const pattern = RegExp(`${param}=([^&]*)`, 'i');
  const match = window.location.search.match(pattern);
  return match && match.length > 1 ? match[1] : '';
};
