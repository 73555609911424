import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import Slide from './Slide';

const MediaSection = ({
  activeSlide,
  pageData,
  lastEdit,
  mediaBaseUrl,
}) => {
  const imgSrc = pageData.get('image');
  const alt = pageData.get('alt_text') || 'figure';
  const videoSrc = pageData.get('video');
  const slides = pageData.get('slides');
  return (
    <section className="media">
      { imgSrc
        ? <img src={`${mediaBaseUrl}/${imgSrc}?t=${lastEdit}`} alt={alt} />
        : null
      }
      { videoSrc
        // TODO: add <track> for A11Y compliance
        // eslint-disable-next-line jsx-a11y/media-has-caption
        ? <video src={`${mediaBaseUrl}/${videoSrc}?t=${lastEdit}`} controls />
        : null
      }
      { slides
        ? (
          <Slide
            slides={slides}
            activeSlide={activeSlide}
            lastEdit={lastEdit}
            mediaBaseUrl={mediaBaseUrl}
          />
        )
        : null
      }
    </section>
  );
};

MediaSection.propTypes = {
  activeSlide: PropTypes.number,
  pageData: PropTypes.instanceOf(Map).isRequired,
  lastEdit: PropTypes.number.isRequired,
  mediaBaseUrl: PropTypes.string.isRequired,
};

MediaSection.defaultProps = {
  activeSlide: 0,
};

export default MediaSection;
