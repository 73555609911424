import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import './Picker.scss';

const Picker = ({ children, options, onOptionClick }) => (
  <section className="picker">
    <ul className="option-list">
      {options.map(option => (
        <li key={option.get('value')}>
          <button type="button" value={option.get('value')} onClick={e => onOptionClick(e)}>
            {option.get('name')}
          </button>
        </li>
      ))
      }
    </ul>
    <div className="actions">
      {children}
    </div>
  </section>
);

Picker.propTypes = {
  children: PropTypes.node,
  onOptionClick: PropTypes.func.isRequired,
  options: PropTypes.instanceOf(List).isRequired,
};

Picker.defaultProps = {
  children: null,
};

export default Picker;
