import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dismissModal } from 'aes-lesson-driver/src/redux/View/actions';
import Modal from 'aes-lesson-driver/src/containers/Modal';
import { changeTitles } from '../redux/Edits/actions';
import { getPageSet } from '../redux/Edits/selectors';
import './CreatorModals.scss';

class TitleEditor extends React.Component {
  constructor(props) {
    super(props);
    const { unitTitle, pageSetTitle } = props;
    this.state = {
      unitTitle,
      pageSetTitle,
    };
  }

  onChangeUnitTitle({ target: { value } }) {
    this.setState({ unitTitle: value });
  }

  onChangePageSetTitle({ target: { value } }) {
    this.setState({ pageSetTitle: value });
  }

  onClickSave(event) {
    const { unitTitle, pageSetTitle } = this.state;
    const { dispatch } = this.props;
    event.preventDefault();
    dispatch.changeTitles(unitTitle, pageSetTitle);
    dispatch.dismiss();
  }

  render() {
    const { unitTitle, pageSetTitle } = this.state;
    return (
      <Modal>
        <section className="title-editor">
          <label htmlFor="unit_title">
            Unit Title:
            <input id="unit_title" value={unitTitle} onChange={e => this.onChangeUnitTitle(e)} />
          </label>
          <label htmlFor="pageset_title">
            Lesson Title:
            <input id="pageset_title" value={pageSetTitle} onChange={e => this.onChangePageSetTitle(e)} />
          </label>
          <div className="actions">
            <button type="button" onClick={e => this.onClickSave(e)}>
              Save
            </button>
          </div>
        </section>
      </Modal>
    );
  }
}

TitleEditor.propTypes = {
  dispatch: PropTypes.object.isRequired,
  unitTitle: PropTypes.string.isRequired,
  pageSetTitle: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { pageSetKey }) => {
  const pageSet = getPageSet(state, pageSetKey);
  const unitTitle = pageSet.get('unit_title');
  const pageSetTitle = pageSet.get('page_set_title');
  return {
    unitTitle,
    pageSetTitle,
  };
};

const mapDispatchToProps = (dispatch, { pageSetKey }) => ({
  dispatch: {
    dismiss: () => dispatch(dismissModal()),
    changeTitles: (unitTitle, pageSetTitle) => dispatch(
      changeTitles(pageSetKey, pageSetTitle, unitTitle),
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TitleEditor);
