import { fromJS, Map } from 'immutable';
import {
  VIEW_MODAL_ACTIVATE,
  VIEW_MODAL_DISMISS,
  VIEW_SCORM_COPY_DATA,
  VIEW_SCORM_LOAD_ANSWERS,
  VIEW_SCORM_LOAD_PAGE,
  VIEW_SET_ACTIVE_SLIDE,
  VIEW_SET_ANSWER,
  VIEW_SET_ATTEMPTS,
  VIEW_SET_AUTOPLAY,
  VIEW_SET_INITIAL_LOAD,
  VIEW_UNSET_ANSWER,
} from './actions';

const searchParams = new URLSearchParams(window.location.search);

const initialState = fromJS({
  activeModal: null,
  activeSlide: 0,
  autoPlay: !searchParams.get('disable_autoplay'),
  initialLoad: true,
  scorm: {},
});

const viewReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIEW_MODAL_ACTIVATE:
      return state.set('activeModal', Map(action.data));
    case VIEW_MODAL_DISMISS:
      return state.set('activeModal', null);
    case VIEW_SET_ACTIVE_SLIDE:
      return state.set('activeSlide', action.index);
    case VIEW_SET_ANSWER:
      return state.setIn(['answers', `${action.pageNumber}`].concat(action.answerPath), action.answer);
    case VIEW_SET_ATTEMPTS:
      return state.setIn(['attempts', `${action.pageNumber}`].concat(action.answerPath), action.attempts);
    case VIEW_SET_AUTOPLAY:
      return state.set('autoPlay', action.value);
    case VIEW_SET_INITIAL_LOAD:
      return state.set('initialLoad', action.value);
    case VIEW_UNSET_ANSWER:
      return state.deleteIn(['answers', `${action.pageNumber}`].concat(action.answerPath));
    case VIEW_SCORM_COPY_DATA:
      return state.set('answers', state.getIn(['scorm', 'answers']));
    case VIEW_SCORM_LOAD_ANSWERS:
      return state.setIn(['scorm', 'answers'], action.answers);
    case VIEW_SCORM_LOAD_PAGE:
      return state.setIn(['scorm', 'pageNumber'], action.pageNumber);
    default:
      return state;
  }
};

export default viewReducer;
