import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import MarkdownSection from './MarkdownSection';
import MediaSection from './MediaSection';
import './PageContent.scss';
import QuestionSection from '../containers/QuestionSection';
import DragDropSection from '../containers/DragDropSection';

export const LAYOUT_PAGE_5050 = { key: 'Page5050', title: '50/50' };
export const LAYOUT_PAGE_7525 = { key: 'Page7525', title: '75/25' };
export const LAYOUT_QUESTION = { key: 'Question', title: 'Question' };
export const LAYOUT_QUESTION_IMAGE = { key: 'QuestionImage', title: 'Question With Image' };
export const LAYOUT_DRAG_DROP = { key: 'DragDrop', title: 'Drag & Drop' };

export const LAYOUTS = [
  LAYOUT_PAGE_5050,
  LAYOUT_PAGE_7525,
  LAYOUT_QUESTION,
  LAYOUT_QUESTION_IMAGE,
  LAYOUT_DRAG_DROP,
];

const renderLayout = (pageData, pageNumber, mediaBaseUrl, activeSlide, assessmentMode) => {
  const layout = pageData.get('layout');
  const image = pageData.get('image');
  const lastEdit = pageData.get('last_edited') || Date.now();

  switch (layout) {
    case (LAYOUT_PAGE_5050.key):
      return (
        <div className="main layout-5050">
          <MediaSection
            pageData={pageData}
            lastEdit={lastEdit}
            mediaBaseUrl={mediaBaseUrl}
            activeSlide={activeSlide}
          />
          <MarkdownSection source={pageData.get('markdown')} mediaBaseUrl={mediaBaseUrl} />
        </div>
      );

    case (LAYOUT_PAGE_7525.key):
      return (
        <div className="main layout-7525">
          <MediaSection
            pageData={pageData}
            lastEdit={lastEdit}
            mediaBaseUrl={mediaBaseUrl}
            activeSlide={activeSlide}
          />
          <MarkdownSection source={pageData.get('markdown')} mediaBaseUrl={mediaBaseUrl} />
        </div>
      );

    case (LAYOUT_QUESTION.key):
      if (image) {
        return (
          <div className="main layout-5050">
            <MediaSection
              pageData={pageData}
              lastEdit={lastEdit}
              mediaBaseUrl={mediaBaseUrl}
              activeSlide={activeSlide}
            />
            <QuestionSection
              markdown={pageData.get('markdown')}
              mediaBaseUrl={mediaBaseUrl}
              pageNumber={pageNumber}
              questions={pageData.get('questions')}
              assessmentMode={assessmentMode}
            />
          </div>
        );
      }
      return (
        <div className="main layout-full">
          <QuestionSection
            markdown={pageData.get('markdown')}
            mediaBaseUrl={mediaBaseUrl}
            pageNumber={pageNumber}
            questions={pageData.get('questions')}
            assessmentMode={assessmentMode}
          />
        </div>
      );

    case (LAYOUT_QUESTION_IMAGE.key):
      return (
        <div className="main layout-5050 question">
          <MediaSection
            pageData={pageData}
            lastEdit={lastEdit}
            mediaBaseUrl={mediaBaseUrl}
            activeSlide={activeSlide}
          />
          <QuestionSection
            markdown={pageData.get('markdown')}
            mediaBaseUrl={mediaBaseUrl}
            pageNumber={pageNumber}
            questions={pageData.get('questions')}
            assessmentMode={assessmentMode}
          />
        </div>
      );

    case (LAYOUT_DRAG_DROP.key):
      return (
        <div className="main layout-full">
          <MarkdownSection mediaBaseUrl={mediaBaseUrl} source={pageData.get('markdown')} />
          <DragDropSection
            pageNumber={pageNumber}
            data={pageData.get('dragdrop')}
            lastEdit={lastEdit}
            mediaBaseUrl={mediaBaseUrl}
          />
        </div>
      );

    default:
      return (
        <div className="main layout-full">
          <MarkdownSection source={pageData.get('markdown')} mediaBaseUrl={mediaBaseUrl} />
        </div>
      );
  }
};

const PageContent = ({
  children,
  activeSlide,
  assessmentMode,
  pageData,
  pageNumber,
  mediaBaseUrl,
}) => (
  <div className="page-content">
    {renderLayout(pageData, pageNumber, mediaBaseUrl, activeSlide, assessmentMode)}
    {children}
  </div>
);

PageContent.propTypes = {
  children: PropTypes.node,
  activeSlide: PropTypes.number,
  assessmentMode: PropTypes.bool,
  mediaBaseUrl: PropTypes.string.isRequired,
  pageData: PropTypes.instanceOf(Map).isRequired,
  pageNumber: PropTypes.number.isRequired,
};

PageContent.defaultProps = {
  activeSlide: 0,
  assessmentMode: false,
  children: null,
};

export default PageContent;
