import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { connect } from 'react-redux';
import { getAnswersMap, getIncorrectAnswers } from '../redux/View/selectors';
import { checkAnswers, setAnswer, unsetAnswer } from '../redux/View/actions';
import DragDropTerm from './DragDropTerm';
import DragDropContainer from './DragDropContainer';
import { shuffleList } from '../util';

class CategorizeDragDrop extends React.Component {
  constructor(props) {
    super(props);

    const { data } = this.props;
    const categories = data.get('categories', Map());

    const choices = categories.reduce(
      (catAcc, values, key) => {
        const terms = values.get('terms', List());
        return catAcc.concat(
          terms.reduce((termAcc, term, index) => termAcc.concat({ key, index, term }), List()),
        );
      },
      List(),
    );
    this.state = { choices: shuffleList(choices) };
  }

  onDrop(category, term, target) {
    const { dispatch } = this.props;
    if (target && target.id) {
      dispatch.setAnswer(category, term.index, target.id);
    } else {
      dispatch.unsetAnswer(category, term.index);
    }
  }

  renderAnswerStatus() {
    const { answerIsCorrect, dispatch } = this.props;

    if (answerIsCorrect) {
      return (
        <div className="message success">
          <span>correct</span>
          &nbsp;
          <i aria-hidden="true" className="fas fa-check-square" />
        </div>
      );
    }

    return (
      <button type="button" className="check-answer" onClick={() => dispatch.checkAnswers()}>
        Check Answer
      </button>
    );
  }

  renderTerm(category, index, term, isAnswered) {
    return (
      <DragDropTerm
        key={`term_${category}_${index}`}
        index={index}
        term={term}
        isAnswered={isAnswered}
        onDrop={(initiator, target) => this.onDrop(category, initiator, target)}
      />
    );
  }

  renderChoices(categories, answers) {
    const { choices } = this.state;

    const rendered = choices
      .filter(c => !answers.hasIn([c.key, `${c.index}`]))
      .map(c => this.renderTerm(c.key, c.index, c.term, false));

    if (rendered.isEmpty()) {
      return this.renderAnswerStatus();
    }
    return (
      <ul>
        { rendered }
      </ul>
    );
  }

  renderCategory(key, title, categories, answers) {
    const catKey = `category_${key}`;
    const categoryAnswers = answers.reduce(
      (acc, terms, category) => acc.concat(
        terms
          .filter(value => value === key)
          .map((value, index) => ({ category, index }))
          .values(),
      ),
      List(),
    );
    return (
      <div className="category" key={catKey}>
        <DragDropContainer id={key}>
          <h3>{title}</h3>
          <ul>
            {categoryAnswers.map((value) => {
              const { category, index } = value;
              const term = categories.getIn([category, 'terms', index]);
              return this.renderTerm(category, parseInt(index, 10), term, true);
            })}
          </ul>
        </DragDropContainer>
      </div>
    );
  }

  render() {
    const { answers, data } = this.props;
    const categories = data.get('categories');
    if (categories) {
      return (
        <Fragment>
          <section className="terms categorize">
            {this.renderChoices(categories, answers)}
          </section>
          <section className="flex categories">
            {categories
              .map((values, key) => this.renderCategory(key, values.get('title'), categories, answers))
              .toList()
            }
          </section>
        </Fragment>
      );
    }
    return null;
  }
}

CategorizeDragDrop.propTypes = {
  answers: PropTypes.instanceOf(Map).isRequired,
  answerIsCorrect: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  dispatch: PropTypes.object.isRequired,
  /* eslint-disable react/no-unused-prop-types */
  pageNumber: PropTypes.number.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

const mapStateToProps = (state, { pageNumber }) => ({
  answers: getAnswersMap(state, pageNumber, ['dragdrop']) || Map(),
  answerIsCorrect: getIncorrectAnswers(state, pageNumber, ['dragdrop']).isEmpty(),
});

const mapDispatchToProps = (dispatch, { pageNumber }) => ({
  dispatch: {
    checkAnswers: () => {
      dispatch(checkAnswers(pageNumber, ['dragdrop']));
    },
    setAnswer: (category, index, answer) => {
      dispatch(setAnswer(pageNumber, ['dragdrop', category, `${index}`], answer));
    },
    unsetAnswer: (category, index) => {
      dispatch(unsetAnswer(pageNumber, ['dragdrop', category, `${index}`]));
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CategorizeDragDrop);
