import React from 'react';
import PropTypes from 'prop-types';
import { fromJS, List, Map } from 'immutable';

const NEW_CATEGORY = fromJS({ title: '', terms: [] });

class CategorizeDragDropEditor extends React.Component {
  componentDidMount() {
    const { data, onChange } = this.props;
    const categories = data.get('categories');
    if (!categories) {
      onChange(data.set('categories', Map({
        A: NEW_CATEGORY,
        B: NEW_CATEGORY,
      })));
    }
  }

  onChangeTerm(event, categoryKey, index) {
    const { target: { value } } = event;
    const { data, onChange } = this.props;

    onChange(data.setIn(['categories', categoryKey, 'terms', index], value));
  }

  onChangeTitle(event, categoryKey) {
    const { target: { value } } = event;
    const { data, onChange } = this.props;

    onChange(data.setIn(['categories', categoryKey, 'title'], value));
  }

  onDeleteTerm(categoryKey, index) {
    const { data, onChange } = this.props;

    onChange(data.deleteIn(['categories', categoryKey, 'terms', index]));
  }

  addCategory() {
    const { data, onChange } = this.props;
    onChange(data.update('categories', (categories) => {
      const letter = String.fromCharCode(65 + categories.size);
      return categories.set(letter, NEW_CATEGORY);
    }));
  }

  addTerm(categoryKey) {
    const { data, onChange } = this.props;
    onChange(data.updateIn(['categories', categoryKey, 'terms'], List(), terms => terms.push('')));
  }

  renderCategory(values, key) {
    const title = values.get('title');
    const terms = values.get('terms', List());
    const catKey = `category_editor_${key}`;
    return (
      <section key={catKey} className="category">
        <h3><input defaultValue={title} onChange={e => this.onChangeTitle(e, key)} /></h3>
        <ul>
          {terms.map(
            (term, index) => (
              /* eslint-disable-next-line react/no-array-index-key */
              <li key={`${catKey}_${index}_${terms.size}`}>
                <input defaultValue={term} onChange={e => this.onChangeTerm(e, key, index)} />
                <button
                  type="button"
                  className="delete"
                  onClick={() => this.onDeleteTerm(key, index)}
                >
                  <i className="fas fa-trash-alt" />
                </button>
              </li>
            ),
          )}
          <li>
            <button type="button" onClick={() => this.addTerm(key)}>
              Add Term
            </button>
          </li>
        </ul>
      </section>
    );
  }

  render() {
    const { data } = this.props;
    const categories = data.get('categories');
    if (categories) {
      return (
        <div className="flex">
          {categories
            .map((values, key) => this.renderCategory(values, key))
            .toList()
          }
          {categories.size < 4
            ? (
              <section>
                <button type="button" onClick={() => this.addCategory()}>Add Category</button>
              </section>
            )
            : null
          }
        </div>
      );
    }
    return null;
  }
}

CategorizeDragDropEditor.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CategorizeDragDropEditor;
