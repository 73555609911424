import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dismissModal } from '../redux/View/actions';

const Modal = ({ children, dispatch }) => (
  <div aria-labelledby="modal_title" aria-modal className="modal">
    <div className="modal-body">
      {children}
      <button
        aria-label="close"
        className="modal-close"
        onClick={(e) => {
          e.preventDefault();
          dispatch.dismiss();
        }}
        type="button"
      >
        ✕
      </button>
    </div>
  </div>
);

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
  dispatch: {
    dismiss: () => dispatch(dismissModal()),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
