import {
  all,
  call,
  debounce,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';

import { Map } from 'immutable';

import {
  listPageSets as dataListPageSets,
  loadPageSet as dataLoadPageSet,
  loadPageSetSucceeded as dataLoadPageSetSucceeded,
} from 'aes-lesson-driver/src/redux/Data/actions';
import { dismissModal } from 'aes-lesson-driver/src/redux/View/actions';
import {
  getPageSet,
} from 'aes-lesson-driver/src/redux/Data/selectors';
import { QUESTION_TYPE_MULTIPLE_CHOICE, QUESTION_TYPE_TRUE_FALSE } from 'aes-lesson-driver/src/constants';
import {
  EDITS_CHANGE_PAGE_MARKDOWN_REQUEST,
  EDITS_DUPLICATE_PAGE_SET_REQUEST,
  EDITS_LOAD_PAGE_SET_REQUEST,
  EDITS_SAVE_PAGE_SET_REQUEST,
  EDITS_UPLOAD_GLOSSARY_WORD_FILE_REQUEST,
  EDITS_UPLOAD_PAGE_SET_FILE_REQUEST,
  EDITS_CREATE_QUESTION_REQUEST,
  EDITS_CHANGE_QUESTION_REQUEST,
  EDITS_DELETE_QUESTION_REQUEST,
  EDITS_CHANGE_DRAG_DROP_REQUEST,
  changePageFileField,
  changePageMarkdownComplete,
  duplicatePageSetFailed,
  loadPageSetSucceeded,
  loadPageSetFailed,
  savePageSetFailed,
  savePageSetSucceded,
  uploadPageSetFileFailed,
  uploadPageSetFileSucceeded,
  createQuestionComplete,
  changeQuestionComplete,
  changeDragDropComplete,
  deleteQuestionComplete,
} from './actions';

import {
  getApiKey,
} from '../Authentication/selectors';

import {
  duplicatePageSet,
  loadPageSet,
  savePageSet,
  uploadFile,
} from '../../services/API';
import { getPageQuestions } from './selectors';

export function* doLoadPageSet({ moduleKey, pageSetKey }) {
  let result = yield select(getPageSet, pageSetKey);
  if (result && !result.isEmpty()) {
    yield put(loadPageSetSucceeded(pageSetKey, result));
  } else {
    try {
      const apiKey = yield select(getApiKey);
      result = yield call(loadPageSet, moduleKey, pageSetKey, apiKey);
      const error = result.get('error');
      if (error) {
        yield put(loadPageSetFailed(error));
      } else {
        yield put(loadPageSetSucceeded(pageSetKey, result));
        yield put(dataLoadPageSetSucceeded(pageSetKey, result));
      }
    } catch (error) {
      yield put(loadPageSetFailed('Error contacting API'));
    }
  }
}

export function* doSavePageSet({ moduleKey, pageSetKey, pageSet }) {
  try {
    const apiKey = yield select(getApiKey);
    const result = yield call(savePageSet, moduleKey, pageSetKey, pageSet, apiKey);
    const error = result.get('error');
    if (error) {
      yield put(savePageSetFailed(error));
    } else {
      yield put(savePageSetSucceded(pageSetKey, result));
      yield put(dataLoadPageSet(moduleKey, pageSetKey));
    }
  } catch (error) {
    yield put(savePageSetFailed('Error contacting API'));
  }
}

export function* doDuplicatePageSet({
  moduleKey,
  pageSetKey,
  newModuleKey,
  newModuleTitle,
  newUnitKey,
  newUnitTitle,
  newPageSetKey,
  newPageSetTitle,
}) {
  try {
    const apiKey = yield select(getApiKey);
    const result = yield call(
      duplicatePageSet,
      moduleKey,
      pageSetKey,
      newModuleKey,
      newModuleTitle,
      newUnitKey,
      newUnitTitle,
      newPageSetKey,
      newPageSetTitle,
      apiKey,
    );
    const error = result.get('error');
    const newPath = `/${newModuleKey}/${newPageSetKey}/`;
    if (error) {
      yield put(duplicatePageSetFailed(error));
    } else {
      yield put(dismissModal());
      yield put(dataListPageSets(newModuleKey));
      yield put(dataLoadPageSet(newModuleKey, newPageSetKey));
      window.location.hash = newPath;
    }
  } catch (error) {
    yield put(duplicatePageSetFailed(error.message));
  }
}

export function* doChangePageMarkdown({ pageSetKey, pageNumber, markdown }) {
  yield put(changePageMarkdownComplete(pageSetKey, pageNumber, markdown));
}

export function* doChangeQuestion({
  pageSetKey,
  pageNumber,
  questionIndex,
  question,
}) {
  yield put(changeQuestionComplete(pageSetKey, pageNumber, questionIndex, question));
}

export function* doChangeDragDrop({ pageSetKey, pageNumber, dragdrop }) {
  yield put(changeDragDropComplete(pageSetKey, pageNumber, dragdrop));
}

export function* doUploadGlossaryWordFile({
  pageSetKey,
  file,
}) {
  try {
    const apiKey = yield select(getApiKey);
    const result = yield call(uploadFile, pageSetKey, file, apiKey);
    const error = result.get('error');
    if (error) {
      yield put(uploadPageSetFileFailed(error));
    } else {
      yield put(uploadPageSetFileSucceeded(pageSetKey, file.name));
    }
  } catch (error) {
    yield put(uploadPageSetFileFailed('Error contacting API'));
  }
}

export function* doUploadPageSetFile({
  pageSetKey,
  pageNumber,
  fieldName,
  file,
}) {
  try {
    const apiKey = yield select(getApiKey);
    const result = yield call(uploadFile, pageSetKey, file, apiKey);
    const error = result.get('error');
    if (error) {
      yield put(uploadPageSetFileFailed(error));
    } else {
      yield put(uploadPageSetFileSucceeded(pageSetKey, file.name));
      if (pageNumber && fieldName) {
        yield put(changePageFileField(pageSetKey, pageNumber, fieldName, file.name));
      }
    }
  } catch (error) {
    yield put(uploadPageSetFileFailed('Error contacting API'));
  }
}

export function* doCreateQuestion({
  pageSetKey,
  pageNumber,
  questionNumber,
  questionType,
}) {
  const pageQuestions = yield select(getPageQuestions, pageSetKey, pageNumber);
  const questionIndex = pageQuestions.findIndex(question => (
    questionNumber === parseFloat(question.get('number'))
  ));
  const newQuestion = Map({ number: questionNumber, text: '', type: questionType })
    .withMutations((map) => {
      switch (questionType) {
        case QUESTION_TYPE_MULTIPLE_CHOICE.key:
          return map
            .set('answer', 'A')
            .set('choice_a', '')
            .set('choice_b', '')
            .set('choice_c', '')
            .set('randomize', 'Yes');
        case QUESTION_TYPE_TRUE_FALSE.key:
          return map.set('answer', 'T');
        default: return map;
      }
    });
  yield put(createQuestionComplete(pageSetKey, pageNumber, questionIndex, newQuestion));
}

export function* doDeleteQuestion({
  pageSetKey,
  pageNumber,
  questionNumber,
}) {
  const pageQuestions = yield select(getPageQuestions, pageSetKey, pageNumber);
  const questionIndex = pageQuestions.findIndex(question => (
    questionNumber === parseFloat(question.get('number'))
  ));

  yield put(deleteQuestionComplete(pageSetKey, pageNumber, questionIndex));
}

export function* watchChangePageMarkdown() {
  yield debounce(100, EDITS_CHANGE_PAGE_MARKDOWN_REQUEST, doChangePageMarkdown);
}

export function* watchChangeDragDrop() {
  yield debounce(100, EDITS_CHANGE_DRAG_DROP_REQUEST, doChangeDragDrop);
}

export function* watchChangeQuestion() {
  yield debounce(100, EDITS_CHANGE_QUESTION_REQUEST, doChangeQuestion);
}

export function* watchCreateQuestion() {
  yield takeLatest(EDITS_CREATE_QUESTION_REQUEST, doCreateQuestion);
}

export function* watchDeleteQuestion() {
  yield takeLatest(EDITS_DELETE_QUESTION_REQUEST, doDeleteQuestion);
}

export function* watchDuplicatePageSet() {
  yield takeLeading(EDITS_DUPLICATE_PAGE_SET_REQUEST, doDuplicatePageSet);
}

export function* watchLoadPageSet() {
  yield takeLatest(EDITS_LOAD_PAGE_SET_REQUEST, doLoadPageSet);
}

export function* watchSavePageSet() {
  yield takeLatest(EDITS_SAVE_PAGE_SET_REQUEST, doSavePageSet);
}

export function* watchUploadPageSetFile() {
  yield takeLatest(EDITS_UPLOAD_PAGE_SET_FILE_REQUEST, doUploadPageSetFile);
}

export function* watchUploadGlossaryWordFile() {
  yield takeLatest(EDITS_UPLOAD_GLOSSARY_WORD_FILE_REQUEST, doUploadGlossaryWordFile);
}

export default function* rootSaga() {
  yield all([
    watchChangeDragDrop(),
    watchChangePageMarkdown(),
    watchChangeQuestion(),
    watchCreateQuestion(),
    watchDeleteQuestion(),
    watchDuplicatePageSet(),
    watchLoadPageSet(),
    watchSavePageSet(),
    watchUploadGlossaryWordFile(),
    watchUploadPageSetFile(),
  ]);
}
