import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

const BooleanFields = ({
  answer,
  attempts,
  onSetAnswer,
  questionData,
}) => {
  const number = Math.floor(questionData.get('number'));
  const correctAnswer = questionData.get('answer');
  const trueDisabled = correctAnswer !== 'T' && attempts.contains('T');
  const falseDisabled = correctAnswer !== 'F' && attempts.contains('F');

  return (
    <Fragment>
      <label htmlFor={`${number}_T`}>
        <input
          id={`${number}_T`}
          name={number}
          type="radio"
          checked={answer === 'T'}
          disabled={trueDisabled}
          onChange={() => onSetAnswer('T')}
        />
        { trueDisabled
          ? <span><del>True</del></span>
          : <span>True</span>
        }
      </label>
      <label htmlFor={`${number}_F`}>
        <input
          id={`${number}_F`}
          name={number}
          type="radio"
          checked={answer === 'F'}
          disabled={falseDisabled}
          onChange={() => onSetAnswer('F')}
        />
        { falseDisabled
          ? <span><del>False</del></span>
          : <span>False</span>
        }
      </label>
    </Fragment>
  );
};

BooleanFields.propTypes = {
  answer: PropTypes.string,
  attempts: PropTypes.instanceOf(List),
  onSetAnswer: PropTypes.func.isRequired,
  questionData: PropTypes.instanceOf(Map).isRequired,
};

BooleanFields.defaultProps = {
  answer: '',
  attempts: List(),
};

export default BooleanFields;
