import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import {
  DRAGDROP_TYPE_CATEGORIZE,
  DRAGDROP_TYPE_MATCH_IMAGE,
  DRAGDROP_TYPE_MATCH_TEXT,
  DRAGDROP_TYPE_SEQUENCE,
} from 'aes-lesson-driver/src/constants';
import LabelDragDropEditor from './LabelDragDropEditor';
import CategorizeDragDropEditor from './CategorizeDragDropEditor';
import MatchDragDropEditor from './MatchDragDropEditor';
import SequenceDragDropEditor from '../containers/SequenceDragDropEditor';

class DragDropEditor extends React.Component {
  onDeleteTerm(index) {
    const { dragdrop, onChange } = this.props;
    onChange(dragdrop.deleteIn(['terms', index]));
  }

  renderOptions() {
    const {
      dragdrop,
      lastEdit,
      mediaBaseUrl,
      onChange,
    } = this.props;
    const type = dragdrop.get('type');

    switch (type) {
      case DRAGDROP_TYPE_MATCH_TEXT.key:
        return (
          <MatchDragDropEditor
            data={dragdrop}
            onChange={onChange}
            onDeleteTerm={index => this.onDeleteTerm(index)}
          />
        );

      case DRAGDROP_TYPE_MATCH_IMAGE.key:
        return (
          <LabelDragDropEditor
            data={dragdrop}
            lastEdit={lastEdit}
            mediaBaseUrl={mediaBaseUrl}
            onChange={onChange}
            onDeleteTerm={index => this.onDeleteTerm(index)}
          />
        );

      case DRAGDROP_TYPE_CATEGORIZE.key:
        return <CategorizeDragDropEditor data={dragdrop} onChange={onChange} />;

      case DRAGDROP_TYPE_SEQUENCE.key:
        return (
          <SequenceDragDropEditor
            data={dragdrop}
            mediaBaseUrl={mediaBaseUrl}
            onChange={onChange}
            onDeleteTerm={index => this.onDeleteTerm(index)}
          />
        );

      default:
        return null;
    }
  }

  render() {
    return (
      <section className="drag-drop">
        { this.renderOptions() }
      </section>
    );
  }
}

DragDropEditor.propTypes = {
  lastEdit: PropTypes.number.isRequired,
  mediaBaseUrl: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  dragdrop: PropTypes.instanceOf(Map).isRequired,
};

export default DragDropEditor;
