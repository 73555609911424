import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import { listPageSets } from 'aes-lesson-driver/src/redux/Data/actions';
import { getModulePageSets } from 'aes-lesson-driver/src/redux/Data/selectors';
import LoadingIndicator from 'aes-lesson-driver/src/components/LoadingIndicator';
import PageSetsList from '../components/PageSetsList';
import { getApiKey } from '../redux/Authentication/selectors';
import { publishPageSet } from '../services/API';
import DownloadModal from '../components/DownloadModal';

class PageSetsMenu extends React.Component {
  constructor(props) {
    super(props);
    this.targetElement = React.createRef();
    this.state = {
      downloading: false,
      downloadLink: '',
      downloadError: '',
    };
  }

  componentDidMount() {
    const { dispatch, moduleKey, pageSetsLoaded } = this.props;
    if (!pageSetsLoaded) {
      dispatch.listPageSets(moduleKey);
    } else {
      this.scrollToTargetElement();
    }
  }

  componentDidUpdate() {
    this.scrollToTargetElement();
  }

  scrollToTargetElement() {
    const { targetElement: { current } } = this;

    if (current) {
      current.scrollIntoView();
    }
  }

  clearDownload() {
    this.setState({ downloading: false, downloadLink: '', downloadError: '' });
  }

  downloadZip(pageSetKey, aes = false) {
    const { apiKey, moduleKey } = this.props;
    const setError = downloadError => this.setState({ downloadError });
    this.setState({ downloading: true });

    publishPageSet(moduleKey, pageSetKey, apiKey, aes)
      .then((result) => {
        const { REACT_APP_MEDIA_BASE_URL } = process.env;
        const status = result.get('status');
        const path = result.get('path');
        if (status === 'OK') {
          this.setState({
            downloading: false,
            downloadLink: `${REACT_APP_MEDIA_BASE_URL}/${path}`,
          });
        } else {
          setError(`${JSON.stringify(result.toJS())}`);
        }
      })
      // eslint-disable-next-line no-alert
      .catch(reason => setError(`${JSON.stringify(reason)}`));
  }

  render() {
    const {
      pageSets,
      pageSetsLoaded,
      moduleKey,
      apiKey,
      history: {
        location: { hash },
      },
    } = this.props;
    const {
      downloading,
      downloadLink,
      downloadError,
    } = this.state;
    const targetKey = hash.slice(1);
    const onClickDownload = pageSetKey => this.downloadZip(pageSetKey);
    const onClickAesDownload = pageSetKey => this.downloadZip(pageSetKey, true);

    return (
      <div className="page-sets-menu">
        <h1>
          Module:
          { moduleKey }
        </h1>
        <Link to="/">Choose a different module</Link>
        <h2>Choose a lesson to edit:</h2>
        { pageSetsLoaded
          ? (
            <PageSetsList
              ref={this.targetElement}
              moduleKey={moduleKey}
              targetKey={targetKey}
              pageSets={pageSets}
              isLessons
              apiKey={apiKey}
              onClickDownload={onClickDownload}
              onClickAesDownload={onClickAesDownload}
            />
          )
          : <LoadingIndicator />
        }
        <h2>Choose a branch to edit:</h2>
        { pageSetsLoaded
          ? (
            <PageSetsList
              ref={this.targetElement}
              moduleKey={moduleKey}
              targetKey={targetKey}
              pageSets={pageSets}
              apiKey={apiKey}
              onClickDownload={onClickDownload}
              onClickAesDownload={onClickAesDownload}
            />
          )
          : <LoadingIndicator />
        }
        <DownloadModal
          downloadError={downloadError}
          downloadLink={downloadLink}
          downloading={downloading}
          onClickDismiss={() => this.clearDownload()}
        />
      </div>
    );
  }
}

PageSetsMenu.propTypes = {
  dispatch: PropTypes.object.isRequired,
  moduleKey: PropTypes.string.isRequired,
  pageSets: PropTypes.instanceOf(List).isRequired,
  pageSetsLoaded: PropTypes.bool.isRequired,
  apiKey: PropTypes.string.isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      hash: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = (state, { match: { params: { moduleKey } } }) => {
  const pageSets = getModulePageSets(state, moduleKey);
  const pageSetsLoaded = !pageSets.isEmpty();
  const apiKey = getApiKey(state);

  return {
    moduleKey,
    pageSets,
    pageSetsLoaded,
    apiKey,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch: {
    listPageSets: moduleKey => dispatch(listPageSets(moduleKey)),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PageSetsMenu);
