import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

const Slide = ({
  slides, activeSlide, lastEdit, mediaBaseUrl,
}) => {
  const slide = slides.get(activeSlide);

  if (slide) {
    const imgSrc = slide.get('image');
    const imgAlt = slide.get('caption') || 'figure';

    return <img src={`${mediaBaseUrl}/${imgSrc}?t=${lastEdit}`} alt={imgAlt} />;
  }
  return null;
};

Slide.propTypes = {
  slides: PropTypes.instanceOf(List).isRequired,
  activeSlide: PropTypes.number.isRequired,
  lastEdit: PropTypes.number.isRequired,
  mediaBaseUrl: PropTypes.string.isRequired,
};


export default Slide;
