import { Map, List } from 'immutable';
import {
  EDITS_LOAD_PAGE_SET_SUCCESS,
  EDITS_LOAD_PAGE_SET_FAILURE,
  EDITS_CHANGE_PAGE_ALT_TEXT,
  EDITS_CHANGE_PAGE_MARKDOWN_COMPLETE,
  EDITS_CHANGE_PAGE_FILE_FIELD,
  EDITS_CHANGE_PAGE_LAYOUT,
  EDITS_CHANGE_SLIDE_CAPTION,
  EDITS_CREATE_PAGE,
  EDITS_CREATE_GLOSSARY_WORD,
  EDITS_UPDATE_GLOSSARY_WORD,
  EDITS_INCREMENT_QUESTION_NUMBER,
  EDITS_DECREMENT_QUESTION_NUMBER,
  EDITS_CREATE_QUESTION_COMPLETE,
  EDITS_CHANGE_QUESTION_COMPLETE,
  EDITS_CHANGE_DRAG_DROP_COMPLETE,
  EDITS_DELETE_QUESTION_COMPLETE,
  EDITS_SAVE_PAGE_SET_SUCCESS,
  EDITS_CREATE_SLIDE,
  EDITS_DELETE_SLIDE,
  EDITS_DELETE_PAGE,
  EDITS_MOVE_QUESTION, EDITS_CHANGE_TITLES,
} from './actions';

const initialState = Map({
  pageSets: Map(),
});

const renumberPages = pages => pages.map((page, index) => {
  const pageNumberString = `${index + 1}`;
  const oldKey = page.get('page_key') || 'PAGE_1';
  const newKey = oldKey.slice(0, oldKey.lastIndexOf('_') + 1) + pageNumberString;

  return page
    .set('page_number', pageNumberString)
    .set('page_key', newKey);
});

const renumberQuestions = (pages) => {
  let lastNumber = 0;

  return pages.map((page) => {
    if (page.has('questions')) {
      return page.update('questions', questions => questions.map((question) => {
        lastNumber += 1;
        return question.set('number', lastNumber);
      }));
    }

    return page;
  });
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case (EDITS_LOAD_PAGE_SET_SUCCESS):
      return state
        .remove('pageSetError')
        .setIn(['pageSets', action.pageSetKey], action.result);

    case (EDITS_LOAD_PAGE_SET_FAILURE):
      return state.set('pageSetError', action.message);

    case (EDITS_CHANGE_PAGE_ALT_TEXT):
      return state.setIn(
        ['pageSets', action.pageSetKey, 'pages', action.pageNumber - 1, 'alt_text'],
        action.text,
      );

    case (EDITS_CHANGE_PAGE_MARKDOWN_COMPLETE):
      return state.setIn(
        ['pageSets', action.pageSetKey, 'pages', action.pageNumber - 1, 'markdown'],
        action.markdown,
      );

    case (EDITS_CHANGE_PAGE_FILE_FIELD):
      return state.updateIn(['pageSets', action.pageSetKey], pageSet => (
        pageSet
          .setIn(['pages', action.pageNumber - 1].concat(action.fieldPath), action.fileName)
          .set('last_edited', Date.now() / 1000)
      ));

    case (EDITS_CHANGE_PAGE_LAYOUT):
      return state.setIn(
        ['pageSets', action.pageSetKey, 'pages', action.pageNumber - 1, 'layout'],
        action.layout,
      );

    case (EDITS_CREATE_PAGE):
      return state
        .updateIn(['pageSets', action.pageSetKey, 'pages'], pages => (
          pages.insert(action.pageNumber - 1, Map({
            layout: action.layout,
            markdown: '# Page Title',
            page_key: `${action.pageSetKey}_${action.pageNumber}`,
            page_number: `${action.pageNumber}`,
          })))
          .update(renumberPages));

    case (EDITS_DELETE_PAGE):
      return state
        .deleteIn(['pageSets', action.pageSetKey, 'pages', action.pageNumber - 1])
        .updateIn(['pageSets', action.pageSetKey, 'pages'], renumberPages);

    case (EDITS_CREATE_GLOSSARY_WORD):
      return state.updateIn(
        ['pageSets', action.pageSetKey, 'glossary'],
        List(),
        glossary => glossary.push(action.glossaryWord),
      );

    case (EDITS_UPDATE_GLOSSARY_WORD):
      return state.setIn(
        ['pageSets', action.pageSetKey, 'glossary', action.glossaryWordIndex],
        action.glossaryWord,
      );

    case (EDITS_INCREMENT_QUESTION_NUMBER):
      return state.updateIn(
        ['pageSets',
          action.pageSetKey,
          'pages',
          action.pageNumber - 1,
          'questions',
          action.questionIndex,
          'number',
        ],
        number => parseFloat(number) + 1,
      );

    case (EDITS_DECREMENT_QUESTION_NUMBER):
      return state.updateIn(
        ['pageSets',
          action.pageSetKey,
          'pages',
          action.pageNumber - 1,
          'questions',
          action.questionIndex,
          'number',
        ],
        number => parseFloat(number) - action.count,
      );

    case (EDITS_MOVE_QUESTION):
      return state.updateIn(
        ['pageSets',
          action.pageSetKey,
          'pages',
          action.pageNumber - 1,
          'questions',
        ],
        (questions) => {
          const { questionIndex, delta } = action;
          const targetIndex = questionIndex + delta;
          // update question numbers for new positions
          const sourceQuestion = questions.get(questionIndex)
            .update('number', number => parseFloat(number) + delta);
          const targetQuestion = questions.get(targetIndex)
            .update('number', number => parseFloat(number) - delta);
          // swap question positions
          return questions
            .set(questionIndex, targetQuestion)
            .set(targetIndex, sourceQuestion);
        },
      );

    case (EDITS_CREATE_QUESTION_COMPLETE):
      return state
        .updateIn(
          ['pageSets',
            action.pageSetKey,
            'pages',
            action.pageNumber - 1,
            'questions',
          ],
          List(),
          questions => questions.insert(
            action.questionIndex < 0 ? questions.size : action.questionIndex,
            action.question,
          ),
        )
        .updateIn(['pageSets', action.pageSetKey, 'pages'], renumberQuestions);

    case (EDITS_DELETE_QUESTION_COMPLETE):
      return state
        .deleteIn(
          ['pageSets',
            action.pageSetKey,
            'pages',
            action.pageNumber - 1,
            'questions',
            action.questionIndex,
          ],
        )
        .updateIn(['pageSets', action.pageSetKey, 'pages'], renumberQuestions);

    case (EDITS_CHANGE_QUESTION_COMPLETE):
      return state.setIn(
        ['pageSets',
          action.pageSetKey,
          'pages',
          action.pageNumber - 1,
          'questions',
          action.questionIndex,
        ],
        action.question,
      );

    case (EDITS_CHANGE_DRAG_DROP_COMPLETE):
      return state.setIn(
        ['pageSets',
          action.pageSetKey,
          'pages',
          action.pageNumber - 1,
          'dragdrop',
        ],
        action.dragdrop,
      );

    case (EDITS_SAVE_PAGE_SET_SUCCESS):
      return state.setIn(
        ['pageSets', action.pageSetKey, 'last_edited'],
        action.result.get('last_edited'),
      );

    case (EDITS_CREATE_SLIDE):
      return state.updateIn(
        ['pageSets', action.pageSetKey, 'pages', action.pageNumber - 1, 'slides'],
        List(),
        slides => slides
          .insert(action.slideNumber - 1, Map({
            audio: action.audio,
            image: action.image,
            caption: action.caption,
          }))
          .map((slide, index) => slide.set('number', index + 1)),
      );

    case (EDITS_DELETE_SLIDE):
      return state.deleteIn([
        'pageSets',
        action.pageSetKey,
        'pages',
        action.pageNumber - 1,
        'slides',
        action.slideIndex,
      ]).map((slide, index) => slide.set('number', index + 1));

    case (EDITS_CHANGE_SLIDE_CAPTION):
      return state.setIn(
        [
          'pageSets',
          action.pageSetKey,
          'pages',
          action.pageNumber - 1,
          'slides',
          action.slideIndex,
          'caption',
        ],
        action.caption,
      );

    case (EDITS_CHANGE_TITLES):
      return state.updateIn(
        ['pageSets', action.pageSetKey],
        pageSet => pageSet
          .set('unit_title', action.unitTitle)
          .set('page_set_title', action.pageSetTitle)
          .set('title', action.pageSetTitle),
      );

    default:
      return state;
  }
};

export default dataReducer;
