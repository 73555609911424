import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'aes-lesson-driver/src/redux/logger';
import reducers from './reducers';
import sagas from './sagas';

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    reducers,
    applyMiddleware(
      sagaMiddleware,
      logger,
    ),
  );

  sagaMiddleware.run(sagas);

  return store;
};

export default configureStore;
