import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { connect } from 'react-redux';
import { shuffleList } from '../util';
import DragDropContainer from './DragDropContainer';
import DragDropTerm from './DragDropTerm';
import { getAnswersMap, getIncorrectAnswers } from '../redux/View/selectors';
import { checkAnswers, setAnswer, unsetAnswer } from '../redux/View/actions';

class MatchDragDrop extends React.Component {
  constructor(props) {
    super(props);
    const { data } = props;
    const terms = data.get('terms', List())
      .map((term, index) => term.set('index', index));
    this.state = { shuffledTerms: shuffleList(terms) };
  }

  onDrop(term, target) {
    const { dispatch, answers } = this.props;
    if (target && target.id) {
      const answer = parseInt(target.id, 10);
      const existingTerm = answers.keyOf(answer);
      if (typeof existingTerm !== 'undefined') {
        dispatch.unsetAnswer(existingTerm);
      }
      dispatch.setAnswer(term.index, answer);
    } else {
      dispatch.unsetAnswer(term.index);
    }
  }

  renderAnswerStatus() {
    const { answerIsCorrect, dispatch } = this.props;

    if (answerIsCorrect) {
      return (
        <div className="message success">
          <span>correct</span>
          &nbsp;
          <i aria-hidden="true" className="fas fa-check-square" />
        </div>
      );
    }

    return (
      <button type="button" className="check-answer" onClick={() => dispatch.checkAnswers()}>
        Check Answer
      </button>
    );
  }

  renderTerm(index, term, answered) {
    if (term) {
      return (
        <DragDropTerm
          key={term}
          index={index}
          term={term}
          isAnswered={answered}
          isDetached={answered}
          onDrop={(initiator, target) => this.onDrop(initiator, target)}
        />
      );
    }
    return null;
  }

  renderTermList(terms, answers) {
    const termList = terms.reduce((acc, data) => {
      const index = data.get('index');
      const term = data.get('term');
      // Only render terms that aren't already answered
      if (!answers.has(`${index}`)) {
        return acc.push(this.renderTerm(index, term, false));
      }
      return acc;
    }, List());

    if (termList.isEmpty()) {
      return this.renderAnswerStatus();
    }

    return <ul>{termList}</ul>;
  }

  renderDefinitions(terms, answers) {
    return (terms.map((data, index) => {
      const termKey = answers.keyOf(index);
      const termIndex = typeof termKey !== 'undefined' ? parseInt(termKey, 10) : -1;
      const term = termIndex > -1 ? terms.get(termIndex).get('term') : null;
      const definition = data.get('definition');
      return (
        /* eslint-disable-next-line react/no-array-index-key */
        <DragDropContainer key={`definition_${index}`} id={`${index}`}>
          <div className="definition">
            <p>{definition}</p>
            <div className="term">
              {this.renderTerm(termIndex, term, true)}
            </div>
          </div>
        </DragDropContainer>
      );
    }));
  }

  render() {
    const { answers, data } = this.props;
    const { shuffledTerms } = this.state;
    const terms = data.get('terms');

    if (terms) {
      return (
        <div className="flex">
          <section className="terms">
            {this.renderTermList(shuffledTerms, answers)}
          </section>
          <section className="definitions">
            {this.renderDefinitions(terms, answers)}
          </section>
        </div>
      );
    }
    return null;
  }
}

MatchDragDrop.propTypes = {
  answers: PropTypes.instanceOf(Map).isRequired,
  answerIsCorrect: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  dispatch: PropTypes.object.isRequired,
  /* eslint-disable react/no-unused-prop-types */
  pageNumber: PropTypes.number.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

const mapStateToProps = (state, { pageNumber }) => ({
  answers: getAnswersMap(state, pageNumber, ['dragdrop']) || Map(),
  answerIsCorrect: getIncorrectAnswers(state, pageNumber, ['dragdrop']).isEmpty(),
});

const mapDispatchToProps = (dispatch, { pageNumber }) => ({
  dispatch: {
    checkAnswers: () => {
      dispatch(checkAnswers(pageNumber, ['dragdrop']));
    },
    setAnswer: (index, answer) => {
      dispatch(setAnswer(pageNumber, ['dragdrop', `${index}`], answer));
    },
    unsetAnswer: index => dispatch(unsetAnswer(pageNumber, ['dragdrop', `${index}`])),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchDragDrop);
