import React from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';

const DragDropTarget = ({
  connectDropTarget,
  isAnswered,
  left,
  top,
  term,
}) => connectDropTarget(
  <div
    key={`${term}_target`}
    className={`target ${isAnswered ? 'answered' : ''}`}
    style={{ left, top }}
  >
    <span>{term}</span>
  </div>,
);

const dndSpec = {
  drop: ({
    index,
    term,
    left,
    top,
  }) => ({
    index,
    term,
    left,
    top,
  }),
};

const dndCollect = connect => ({
  connectDropTarget: connect.dropTarget(),
});

DragDropTarget.propTypes = {
  connectDropTarget: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isAnswered: PropTypes.bool,
  left: PropTypes.string.isRequired,
  term: PropTypes.string.isRequired,
  top: PropTypes.string.isRequired,
};

DragDropTarget.defaultProps = {
  isAnswered: false,
};

export default DropTarget(['TERM'], dndSpec, dndCollect)(DragDropTarget);
