import React from 'react';
import PropTypes from 'prop-types';


const AudioPlayer = ({
  audio,
  autoPlay,
  lastEdit,
  mediaBaseUrl,
  onEnded,
}) => (
  // TODO: add <track /> for A11Y compliance
  // eslint-disable-next-line jsx-a11y/media-has-caption
  <audio
    src={`${mediaBaseUrl}/${audio}?t=${lastEdit}`}
    autoPlay={autoPlay}
    onEnded={autoPlay ? onEnded : undefined}
    controls
  />
);

AudioPlayer.propTypes = {
  audio: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
  lastEdit: PropTypes.number,
  mediaBaseUrl: PropTypes.string.isRequired,
  onEnded: PropTypes.func,
};

AudioPlayer.defaultProps = {
  autoPlay: false,
  lastEdit: 0,
  onEnded: () => {},
};

export default AudioPlayer;
