/* eslint-disable import/no-named-as-default */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { HashRouter, Route } from 'react-router-dom';
import { DndProvider, Preview } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/cjs/HTML5toTouch';
import DragDropPreview from 'aes-lesson-driver/src/components/DragDropPreview';
import { setAutoplay } from 'aes-lesson-driver/src/redux/View/actions';
import { getAuthenticated } from '../redux/Authentication/selectors';
import SignIn from './SignIn';
import ModulesMenu from './ModulesMenu';
import PageSetsMenu from './PageSetsMenu';
import PageSet from './PageSet';
import PageSetEditor from './PageSetEditor';
import 'aes-lesson-driver/src/containers/App.scss';


export const App = ({ authenticated, dispatch }) => {
  useEffect(() => { dispatch.disableAutoPlay(); }, []);
  if (!authenticated) {
    return (
      <SignIn />
    );
  }
  return (
    <div className="App">
      <HashRouter>
        <Route exact path="/" component={ModulesMenu} />
        <Route exact path="/:moduleKey" component={PageSetsMenu} />
        <Route exact path="/:moduleKey/:pageSetKey" component={PageSet} />
        <Route exact path="/:moduleKey/:pageSetKey/:pageNumber" component={PageSet} />
        <Route exact path="/:moduleKey/:pageSetKey/:pageNumber/edit" component={PageSetEditor} />
      </HashRouter>
      <Preview>
        <DragDropPreview />
      </Preview>
    </div>
  );
};

App.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  dispatch: PropTypes.shape({ disableAutoPlay: PropTypes.func }).isRequired,
};

const mapStateToProps = state => ({
  authenticated: getAuthenticated(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch: {
    disableAutoPlay: () => dispatch(setAutoplay(false)),
  },
});


const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

const DndApp = () => (
  <DndProvider options={HTML5toTouch}>
    <ConnectedApp />
  </DndProvider>
);

export default connect(mapStateToProps)(DndApp);
