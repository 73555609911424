import { Map, List } from 'immutable';

export const getPageSet = ({ edits }, pageSetKey) => edits.getIn(['pageSets', pageSetKey]) || Map();

export const getPageSetHasChanges = ({ edits, data }, pageSetKey) => {
  const dataPageSet = data.getIn(['pageSets', pageSetKey]);
  const editPageSet = edits.getIn(['pageSets', pageSetKey]);

  return !!editPageSet && !editPageSet.equals(dataPageSet);
};

export const getPageQuestions = ({ edits }, pageSetKey, pageNumber) => (
  edits.getIn(['pageSets', pageSetKey, 'pages', pageNumber - 1, 'questions']) || List()
);

export const getGlossaryWords = ({ edits }, pageSetKey) => (
  edits.getIn(['pageSets', pageSetKey, 'glossary']) || List()
);

export const getGlossaryWordIndex = (state, pageSetKey, glossaryWordKey) => (
  getGlossaryWords(state, pageSetKey)
    .findIndex(entry => entry.get('key') === glossaryWordKey)
);

export const getQuestionPages = ({ edits }, pageSetKey) => {
  const pages = edits.getIn(['pageSets', pageSetKey, 'pages']) || List();

  return pages.filter(page => !!page.get('questions'));
};
