import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { activateFilePicker } from 'aes-lesson-driver/src/redux/View/actions';

class SequenceDragDropEditor extends React.Component {
  onChangeTerm(event, index) {
    const { target: { value } } = event;
    const { data, onChange } = this.props;

    onChange(data.setIn(['terms', index, 'term'], value));
  }

  selectFile(index) {
    const { dispatch } = this.props;
    dispatch.activateFilePicker(['dragdrop', 'terms', index, 'image']);
  }

  addTerm() {
    const { data, onChange } = this.props;
    const terms = data.get('terms') || List();
    onChange(data.set('terms', terms.push(Map({ term: '', image: '' }))));
  }

  render() {
    const { data, onDeleteTerm, mediaBaseUrl } = this.props;
    const terms = data.get('terms') || List();
    return (
      <section className="drag-drop">
        <ul>
          {terms.map(
            (values, index) => {
              const term = values.get('term');
              const imgSrc = values.get('image');
              return (
              /* eslint-disable-next-line react/no-array-index-key */
                <li key={`term_${index}_${terms.size}`}>
                  <textarea
                    rows={3}
                    defaultValue={term}
                    onChange={e => this.onChangeTerm(e, index)}
                  />
                  <button type="button" onClick={() => this.selectFile(index)}>
                    {imgSrc
                      ? <img src={`${mediaBaseUrl}/${imgSrc}`} alt={term} />
                      : <i className="fas fa-image" />
                        }
                  </button>

                  <button type="button" className="delete" onClick={() => onDeleteTerm(index)}>
                    <i className="fas fa-trash-alt" />
                  </button>
                </li>
              );
            },
          )}
          <li>
            <button type="button" onClick={() => this.addTerm()}>
              Add Term
            </button>
          </li>
        </ul>
      </section>
    );
  }
}

SequenceDragDropEditor.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  dispatch: PropTypes.object.isRequired,
  mediaBaseUrl: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDeleteTerm: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  dispatch: {
    activateFilePicker: fieldPath => dispatch(activateFilePicker(fieldPath)),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SequenceDragDropEditor);
