import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../containers/Modal';

const ImageModal = ({ src, alt }) => (
  <Modal>
    <article>
      <img src={src} alt={alt} />
    </article>
  </Modal>
);

ImageModal.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

ImageModal.defaultProps = {
  alt: 'figure',
};

export default ImageModal;
