import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { getMediaBaseUrl, getPageSet } from 'aes-lesson-driver/src/redux/Data/selectors';
import {
  getActiveModal,
  getActiveSlide, getAutoPlay,
} from 'aes-lesson-driver/src/redux/View/selectors';
import { loadPageSet } from 'aes-lesson-driver/src/redux/Data/actions';
import {
  activateDuplicator,
  checkAnswers,
  setActiveSlide, setAutoplay,
} from 'aes-lesson-driver/src/redux/View/actions';
import LoadingIndicator from 'aes-lesson-driver/src/components/LoadingIndicator';
import Page from 'aes-lesson-driver/src/components/Page';
import ModalFactory from './ModalFactory';
import { getAllowedModules } from '../redux/Data/selectors';

export class PageSet extends Component {
  componentDidMount() {
    const {
      dispatch,
      pageSetLoaded,
      pageSetKey,
      moduleKey,
    } = this.props;
    if (!pageSetLoaded) {
      dispatch.loadPageSet(moduleKey, pageSetKey);
    }
  }

  componentDidUpdate(prevProps) {
    const { activeSlide, dispatch, pageNumber } = this.props;
    const { pageNumber: prevPageNumber } = prevProps;
    if (activeSlide > 0 && pageNumber !== prevPageNumber) {
      dispatch.resetActiveSlide();
    }
  }

  onDuplicateClick() {
    const { dispatch } = this.props;
    dispatch.activateDuplicator();
  }

  render() {
    const {
      activeModal,
      activeSlide,
      allowEdit,
      autoPlay,
      dispatch,
      mediaBaseUrl,
      moduleKey,
      pageSetLoaded,
      pageSet,
      pageSetKey,
      pageNumber,
    } = this.props;
    const baseUrl = `/${moduleKey}/${pageSetKey}`;
    const exitLink = `/${moduleKey}#${pageSetKey}`;
    const editLink = allowEdit && `${baseUrl}/${pageNumber}/edit`;
    const duplicateButton = (
      <button type="button" className="duplicate" onClick={() => this.onDuplicateClick()}>
        Duplicate
      </button>
    );

    return (
      <div className="page-set-container">
        {!pageSetLoaded
          ? <LoadingIndicator />
          : (
            <Page
              activeSlide={activeSlide}
              autoPlay={autoPlay}
              baseUrl={baseUrl}
              checkAnswers={() => dispatch.checkAnswers(pageNumber)}
              editLink={editLink}
              exitLink={exitLink}
              pageSet={pageSet}
              pageNumber={pageNumber}
              mediaBaseUrl={mediaBaseUrl}
              extraHeaders={duplicateButton}
              setActiveSlide={dispatch.setActiveSlide}
              setAutoPlay={dispatch.setAutoPlay}
              showHeader
            />
          )
        }
        {
          activeModal
            ? (
              <ModalFactory
                data={activeModal}
                pageNumber={pageNumber}
                pageSetKey={pageSetKey}
              />
            )
            : null
        }
      </div>
    );
  }
}

PageSet.propTypes = {
  activeModal: PropTypes.instanceOf(Map),
  activeSlide: PropTypes.number,
  allowEdit: PropTypes.bool.isRequired,
  autoPlay: PropTypes.bool.isRequired,
  dispatch: PropTypes.object.isRequired,
  mediaBaseUrl: PropTypes.string.isRequired,
  moduleKey: PropTypes.string.isRequired,
  pageSet: PropTypes.instanceOf(Map).isRequired,
  pageSetKey: PropTypes.string.isRequired,
  pageSetLoaded: PropTypes.bool.isRequired,
  pageNumber: PropTypes.number.isRequired,
};

PageSet.defaultProps = {
  activeModal: null,
  activeSlide: 0,
};

const mapStateToProps = (
  state,
  {
    history,
    match: { params: { moduleKey, pageSetKey, pageNumber } },
  },
) => {
  const allowedModules = getAllowedModules(state);
  const allowEdit = allowedModules.has(moduleKey);
  const mediaBaseUrl = getMediaBaseUrl(state, pageSetKey);
  const pageSet = getPageSet(state, pageSetKey);
  const pageSetLoaded = !pageSet.isEmpty();
  const activeModal = getActiveModal(state);
  const activeSlide = getActiveSlide(state);
  const autoPlay = getAutoPlay(state);
  const pageCount = pageSetLoaded ? pageSet.get('pages').size : 0;
  let pageNumberInt = parseInt(pageNumber, 10) || 1;

  // If page number is out of range, use last page. This happens if pages are added but not saved.
  if (pageSetLoaded && pageNumberInt > pageCount) {
    pageNumberInt = pageCount;
    setTimeout(() => history.replace(`/${moduleKey}/${pageSetKey}/${pageNumberInt}`), 100);
  }

  return {
    activeModal,
    activeSlide,
    allowEdit,
    autoPlay,
    mediaBaseUrl,
    moduleKey,
    pageSet,
    pageSetLoaded,
    pageSetKey,
    pageNumber: pageNumberInt,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch: {
    activateDuplicator: () => dispatch(activateDuplicator()),
    loadPageSet: (moduleKey, pageSetKey) => dispatch(loadPageSet(moduleKey, pageSetKey)),
    resetActiveSlide: () => dispatch(setActiveSlide(0)),
    checkAnswers: pageNumber => dispatch(checkAnswers(pageNumber)),
    setActiveSlide: value => dispatch(setActiveSlide(value)),
    setAutoPlay: value => dispatch(setAutoplay(value)),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PageSet);
