import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import AesLink, { uriTransformer } from '../containers/AesLink';

const AesMarkdown = ({ source, mediaBaseUrl }) => {
  const transformLinkUri = uri => uriTransformer(uri, mediaBaseUrl);
  return (
    <ReactMarkdown
      source={source}
      transformLinkUri={transformLinkUri}
      renderers={{
        link: AesLink,
      }}
    />
  );
};

AesMarkdown.propTypes = {
  source: PropTypes.string.isRequired,
  mediaBaseUrl: PropTypes.string.isRequired,
};

export default AesMarkdown;
