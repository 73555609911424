import { fromJS } from 'immutable';

const getDriver = () => {
  /* eslint-disable-next-line no-console */
  const stubFunc = () => console.error('SCORM NOT IMPLEMENTED');
  const api = window.API_1484_11
    || window.parent.API_1484_11
    || {
      GetValue: stubFunc,
      SetValue: stubFunc,
      Commit: stubFunc,
      Terminate: stubFunc,
    };

  return {
    GetValue: key => api.GetValue(key),
    SetValue: (key, value) => {
      api.SetValue(key, value);
      return api;
    },
    Commit: () => {
      api.Commit();
      return api;
    },
    Terminate: (value) => {
      api.Terminate(value);
      return api;
    },
  };
};

export const getAnswers = () => {
  const stringValue = getDriver().GetValue('aes.cbuilder.answers') || '{}';
  const objectValue = JSON.parse(stringValue);
  return fromJS(objectValue);
};


export const getPageNumber = () => getDriver()
  .GetValue('aes.cbuilder.page');

export const getBookmark = () => getDriver()
  .GetValue('old_bookmark');

export const clearBookmark = () => getDriver()
  .SetValue('old_bookmark', false);

export const setAnswers = (answers) => {
  const objectValue = answers.toJS();
  const stringValue = JSON.stringify(objectValue);
  getDriver().SetValue('aes.cbuilder.answers', stringValue);
};

export const setPageNumber = page => getDriver()
  .SetValue('aes.cbuilder.page', page);

export const setComplete = () => getDriver()
  .SetValue('cmi.completion_status', 'completed');

export const exit = () => getDriver()
  .Commit()
  .Terminate();
