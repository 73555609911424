import React from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';

class DragDropContainer extends React.Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
  }

  render() {
    const { connectDropTarget, children } = this.props;
    return connectDropTarget(<div ref={this.container}>{children}</div>);
  }
}

const dndSpec = {
  drop: ({ id }, monitor, component) => {
    const dropOffset = monitor.getClientOffset();
    const containerRect = component.container.current.getBoundingClientRect();
    const offsetX = dropOffset ? dropOffset.x - containerRect.left : 0;
    const offsetY = dropOffset ? dropOffset.y - containerRect.top : 0;
    return {
      id,
      offsetX,
      offsetY,
      containerRect,
    };
  },
};

const dndCollect = connect => ({
  connectDropTarget: connect.dropTarget(),
});

DragDropContainer.propTypes = {
  children: PropTypes.node.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  /* eslint-disable react/no-unused-prop-types */
  id: PropTypes.string.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

export default DropTarget(['TERM'], dndSpec, dndCollect)(DragDropContainer);
