import { combineReducers } from 'redux';
import data from 'aes-lesson-driver/src/redux/Data/reducer';
import view from 'aes-lesson-driver/src/redux/View/reducer';
import authentication from './Authentication/reducer';
import edits from './Edits/reducer';


export default combineReducers({
  authentication,
  data,
  edits,
  view,
});
