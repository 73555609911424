import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import {
  QUESTION_TYPE_FILL_IN_BLANK,
  QUESTION_TYPE_MULTIPLE_CHOICE,
  QUESTION_TYPE_OPEN_ENTRY,
  QUESTION_TYPE_TRUE_FALSE,
} from 'aes-lesson-driver/src/constants';

class QuestionEditor extends React.Component {
  onChangeAnswer(event) {
    const { target: { value } } = event;
    const { question, onChange } = this.props;

    onChange(question.set('answer', value));
  }

  onChangeChoice(event) {
    const { target: { name, value } } = event;
    const { question, onChange } = this.props;
    onChange(question.set(name, value));
  }

  onChangeRandomize(event) {
    const { target: { checked } } = event;
    const { question, onChange } = this.props;
    onChange(question.set('randomize', checked ? 'Yes' : 'No'));
  }

  onChangeText(event) {
    const { target: { value } } = event;
    const { question, onChange } = this.props;

    onChange(question.set('text', value));
  }

  getChoices() {
    const { question } = this.props;
    return question.filter((value, key) => key.startsWith('choice_'));
  }

  addChoice() {
    const { question, onChange } = this.props;
    const lastKey = question.findLastKey((value, key) => key.startsWith('choice_'));
    const lastLetterCode = lastKey.charCodeAt(lastKey.length - 1);
    const nextLetter = String.fromCharCode(lastLetterCode + 1);

    onChange(question.set(`choice_${nextLetter}`, ''));
  }

  deleteChoice(choiceKey) {
    const { question, onChange } = this.props;
    let letterCode = 97; // a
    onChange(question
      .delete(choiceKey)
      .mapKeys((key) => {
        if (key.startsWith('choice_')) {
          const newKey = `choice_${String.fromCharCode(letterCode)}`;
          letterCode += 1;
          return newKey;
        }
        return key;
      }));
  }

  renderOptions() {
    const { question } = this.props;
    const type = question.get('type');
    const number = question.get('number');
    const answer = question.get('answer');

    switch (type) {
      case QUESTION_TYPE_OPEN_ENTRY.key:
        return (
          <textarea rows={5} defaultValue={answer} onChange={e => this.onChangeAnswer(e)} />
        );

      case QUESTION_TYPE_FILL_IN_BLANK.key:
        return (
          <input type="text" defaultValue={answer} onChange={e => this.onChangeAnswer(e)} />
        );

      case QUESTION_TYPE_TRUE_FALSE.key:
        return (
          <fieldset>
            <label htmlFor={`${number}_T`}>
              <input
                id={`${number}_T`}
                name={number}
                type="radio"
                value="T"
                checked={answer === 'T'}
                onChange={e => this.onChangeAnswer(e)}
              />
              <span>True</span>
            </label>
            <label htmlFor={`${number}_F`}>
              <input
                id={`${number}_F`}
                name={number}
                type="radio"
                value="F"
                checked={answer === 'F'}
                onChange={e => this.onChangeAnswer(e)}
              />
              <span>False</span>
            </label>
          </fieldset>
        );

      case QUESTION_TYPE_MULTIPLE_CHOICE.key:
        return (
          <fieldset>
            {this.getChoices().map((text, key) => {
              const fullKey = `${number}_${key}`;
              const value = key.slice(-1).toUpperCase();
              return (
                <label key={fullKey} htmlFor={fullKey}>
                  <input
                    id={fullKey}
                    name={number}
                    type="radio"
                    value={value}
                    checked={value === answer}
                    onChange={e => this.onChangeAnswer(e)}
                  />
                  <span>
                    <input
                      id={`${fullKey}_text`}
                      name={key}
                      type="text"
                      defaultValue={text}
                      onChange={e => this.onChangeChoice(e)}
                    />
                    <button type="button" className="delete" onClick={() => this.deleteChoice(key)}>
                      <i className="fas fa-trash-alt" />
                    </button>
                  </span>
                </label>
              );
            }).toList()}
            <div className="question-actions">
              <label htmlFor={`${number}_randomize`}>
                <input
                  id={`${number}_randomize`}
                  type="checkbox"
                  checked={question.get('randomize') === 'Yes'}
                  onChange={e => this.onChangeRandomize(e)}
                />
                <span>Randomize</span>
              </label>
              <button type="button" onClick={() => this.addChoice()}>Add Choice</button>
            </div>
          </fieldset>
        );

      default:
        return null;
    }
  }

  render() {
    const {
      question,
      isFirst,
      isLast,
      onDeleteClick,
      onMoveUpClick,
      onMoveDownClick,
    } = this.props;
    const text = question.get('text');
    const number = parseFloat(question.get('number'));
    return (
      <section className="question">
        <h2>
          <span>
            Question #
            {number}
          </span>
          { !isFirst
            ? (
              <button type="button" className="move" onClick={() => onMoveUpClick()}>
                <i className="fas fa-arrow-up" />
              </button>
            )
            : null
          }
          { !isLast
            ? (
              <button type="button" className="move" onClick={() => onMoveDownClick()}>
                <i className="fas fa-arrow-down" />
              </button>
            )
            : null
          }
          <button type="button" className="delete" onClick={() => onDeleteClick(number)}>
            <i className="fas fa-trash-alt" />
          </button>
        </h2>
        <textarea defaultValue={text} onChange={e => this.onChangeText(e)} />
        { this.renderOptions() }
      </section>
    );
  }
}

QuestionEditor.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onMoveUpClick: PropTypes.func.isRequired,
  onMoveDownClick: PropTypes.func.isRequired,
  question: PropTypes.instanceOf(Map).isRequired,
};

QuestionEditor.defaultProps = {
  isFirst: false,
  isLast: false,
};

export default QuestionEditor;
