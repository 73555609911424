import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

class MatchDragDropEditor extends React.Component {
  onChangeTerm(event, index) {
    const { target: { value } } = event;
    const { data, onChange } = this.props;

    onChange(data.setIn(['terms', index, 'term'], value));
  }

  onChangeDefinition(event, index) {
    const { target: { value } } = event;
    const { data, onChange } = this.props;

    onChange(data.setIn(['terms', index, 'definition'], value));
  }

  addTerm() {
    const { data, onChange } = this.props;
    const terms = data.get('terms') || List();
    onChange(data.set('terms', terms.push(Map({ term: '', definition: '' }))));
  }

  render() {
    const { data, onDeleteTerm } = this.props;
    const terms = data.get('terms') || List();
    return (
      <section className="drag-drop">
        <ul>
          {terms.map(
            (values, index) => {
              const term = values.get('term');
              const definition = values.get('definition');
              return (
              /* eslint-disable-next-line react/no-array-index-key */
                <li key={`term_${index}`}>
                  <input
                    type="text"
                    defaultValue={term}
                    onChange={e => this.onChangeTerm(e, index)}
                  />
                  :
                  <input
                    type="text"
                    defaultValue={definition}
                    onChange={e => this.onChangeDefinition(e, index)}
                  />
                  <button type="button" className="delete" onClick={() => onDeleteTerm(index)}>
                    <i className="fas fa-trash-alt" />
                  </button>
                </li>
              );
            },
          )}
          <li>
            <button type="button" onClick={() => this.addTerm()}>
              Add Term
            </button>
          </li>
        </ul>
      </section>
    );
  }
}

MatchDragDropEditor.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onDeleteTerm: PropTypes.func.isRequired,
};

export default MatchDragDropEditor;
