// TODO: Fetch from backend.
const AesModules = {
  // iCEV Modules
  HST_01: 'HST LESSON 1',
  HST_02: 'HST LESSON 2',
  HST_03: 'HST LESSON 3',
  HST_04: 'HST LESSON 4',
  HST_05: 'HST LESSON 5',
  HST_06: 'HST LESSON 6',
  HST_07: 'HST LESSON 7',
  // AES Modules
  MAADMISCO: 'Admissions, Transfers, and Discharges',
  FRAIR: 'Airway - EMR',
  EMSAIR: 'Airway - EMT',
  MDSURGPCO: 'Ambulatory Surgery',
  HSANTMYCO: 'Anatomy and Physiology',
  HSBHEALTH: 'Behavioral Health',
  MABMECHCO: 'Body Mechanics',
  HSSTATSCO: 'Client Status',
  HSCPR2019: 'CPR/BLS 2019',
  HSCOMMUCO: 'Communications',
  MAELECTCO: 'Electrocardiography',
  HSEMERGCO: 'Emergency Care',
  EMSOPR: 'EMS Operations (EMT)',
  MAHASSTCO: 'Health Assistant, The',
  HSCARERCO: 'Health Care Careers',
  HSSYSTMCO: 'Healthcare Systems',
  HSHUMANCO: 'Human Growth and Development',
  HSINFECCO: 'Infection Control',
  HSLEGALCO: 'Legal and Ethical Responsibilities',
  FRMED: 'Medical - EMR',
  EMSMED: 'Medical - EMT',
  HSMATHECO: 'Medical Mathematics',
  HSMTERMCO: 'Medical Terminology',
  MANELIMCO: 'Nutrition and Elimination',
  FRASMT: 'Patient Assessment - EMR',
  EMSASMT: 'Patient Assessment - EMT',
  MACMFRTCO: 'Patient Comfort',
  HSPQUALCO: 'Personal Qualities',
  MDPHARMCO: 'Pharmacology (Medical Assistant)',
  EMSPRM: 'Pharmacology - EMT',
  MAPHLEBCO: 'Phlebotomy',
  MDPEXAMCO: 'Physical Exams',
  FRPRP: 'Preparatory - EMR',
  EMSPRP: 'Preparatory - EMT',
  CNAREHABCO: 'Rehabilitation and Restorative Care',
  EMSSHK: 'Shock and Resuscitation',
  MAPOPULCO: 'Special Populations',
  FRSPE: 'Special Populations - EMR',
  EMSPOP: 'Special Populations - EMT',
  MASPECCCO: 'Specimen Collection and Testing',
  MASURGPCO: 'Surgical Patient, The',
  FRTMA: 'Trauma - EMR',
  EMSTMA: 'Trauma - EMT',
  HSNUTRICO: 'Wellness and Nutrition',
  // ALL SAMPLE MODULES BELOW
  HSINFECCO_ES: 'Infection Control (Spanish)',
  ZZSTYLE: 'AES Style Guide',
  ALL_SAMPLE: 'ALL SAMPLES',
  ZZDRAGDROP: 'Drag Drop Samples',
  BHTEST: 'Test Copy Behavioral Health',
};

export default AesModules;
