import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import './ModuleList.scss';

const ModuleList = ({ modules }) => (
  <div className="list modules">
    <div className="row">
      <strong>Module Name</strong>
      <strong>Module Key</strong>
    </div>
    {modules.map((name, key) => (
      /* eslint-disable-next-line react/no-array-index-key */
      <div key={key} className="row module">
        <Link to={key}>{name}</Link>
        <Link to={key}>{key}</Link>
      </div>
    )).toList()}
  </div>
);

ModuleList.propTypes = {
  modules: PropTypes.instanceOf(Map).isRequired,
};

export default ModuleList;
