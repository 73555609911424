import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js';
import { Icon } from '@mdi/react';

import './NavBar.scss';

const renderNextOrCheck = (allQuestionsAreCorrect, nextLink, showAnswerPrompt) => (
  allQuestionsAreCorrect
    ? <Link to={nextLink} className="navbar-link next"><Icon path={mdiArrowRight} /></Link>
    : (
      <button type="button" className="navbar-link next" onClick={showAnswerPrompt}>
        <Icon path={mdiArrowRight} />
      </button>
    )
);

const NavBar = ({
  allQuestionsAreCorrect,
  children,
  showAnswerPrompt,
  exitLink,
  nextLink,
  prevLink,
  pageNumber,
  pageCount,
  showPages,
}) => (
  <nav className="navbar">
    { exitLink
      ? <Link to={exitLink} className="navbar-link exit">Exit</Link>
      : null
    }
    { children }
    { prevLink
      ? <Link to={prevLink} className="navbar-link prev"><Icon path={mdiArrowLeft} /></Link>
      : <button type="button" className="navbar-link prev" disabled><Icon path={mdiArrowLeft} /></button>
    }
    { showPages
      ? <span className="pages">{`${pageNumber}/${pageCount}`}</span>
      : null
    }

    { nextLink
      ? renderNextOrCheck(allQuestionsAreCorrect, nextLink, showAnswerPrompt)
      : <button type="button" className="navbar-link disabled next" disabled><Icon path={mdiArrowRight} /></button>
    }
  </nav>
);

NavBar.propTypes = {
  allQuestionsAreCorrect: PropTypes.bool,
  children: PropTypes.node,
  showAnswerPrompt: PropTypes.func,
  exitLink: PropTypes.string,
  nextLink: PropTypes.string,
  prevLink: PropTypes.string,
  pageNumber: PropTypes.number,
  pageCount: PropTypes.number,
  showPages: PropTypes.bool,
};

NavBar.defaultProps = {
  allQuestionsAreCorrect: true,
  children: null,
  showAnswerPrompt: () => {},
  exitLink: '',
  nextLink: '',
  prevLink: '',
  pageNumber: 1,
  pageCount: 1,
  showPages: true,
};

export default NavBar;
