import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { listModules } from 'aes-lesson-driver/src/redux/Data/actions';
import { getModules } from 'aes-lesson-driver/src/redux/Data/selectors';
import LoadingIndicator from 'aes-lesson-driver/src/components/LoadingIndicator';
import ModuleList from '../components/ModuleList';

class ModulesMenu extends React.Component {
  componentDidMount() {
    const { dispatch, modulesLoaded } = this.props;
    if (!modulesLoaded) {
      dispatch.listModules();
    }
  }

  render() {
    const { modules, modulesLoaded } = this.props;
    return (
      <div className="modules-menu">
        <h1>Choose A Module</h1>
        { modulesLoaded
          ? <ModuleList modules={modules} />
          : <LoadingIndicator />
        }
      </div>
    );
  }
}

ModulesMenu.propTypes = {
  dispatch: PropTypes.object.isRequired,
  modules: PropTypes.instanceOf(Map).isRequired,
  modulesLoaded: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const modules = getModules(state);
  const modulesLoaded = !modules.isEmpty();

  return {
    modules,
    modulesLoaded,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch: {
    listModules: () => dispatch(listModules()),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModulesMenu);
