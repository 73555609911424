export const DRAGDROP_TYPE_CATEGORIZE = { key: 'CATEGORIZETEXT', title: 'Categorize Text' };
export const DRAGDROP_TYPE_MATCH_TEXT = { key: 'MATCHTEXTTOTEXT', title: 'Match Text to Text' };
export const DRAGDROP_TYPE_MATCH_IMAGE = { key: 'MATCHTEXTTOIMAGE', title: 'Match Text to Image' };
export const DRAGDROP_TYPE_SEQUENCE = { key: 'SEQUENCETEXT', title: 'Sequence Text' };

export const DRAGDROP_TYPES = [
  DRAGDROP_TYPE_MATCH_TEXT,
  DRAGDROP_TYPE_MATCH_IMAGE,
  DRAGDROP_TYPE_CATEGORIZE,
  DRAGDROP_TYPE_SEQUENCE,
];

export const QUESTION_TYPE_FILL_IN_BLANK = { key: 'FIB', title: 'Fill in Blank' };
export const QUESTION_TYPE_OPEN_ENTRY = { key: 'OE', title: 'Open Entry' };
export const QUESTION_TYPE_MULTIPLE_CHOICE = { key: 'MC', title: 'Multiple Choice' };
export const QUESTION_TYPE_TRUE_FALSE = { key: 'TF', title: 'True or False' };

export const QUESTION_TYPES = [
  QUESTION_TYPE_FILL_IN_BLANK,
  QUESTION_TYPE_OPEN_ENTRY,
  QUESTION_TYPE_MULTIPLE_CHOICE,
  QUESTION_TYPE_TRUE_FALSE,
];
