import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import DragDropTerm from './DragDropTerm';
import DragDropTarget from './DragDropTarget';
import { getAnswersMap, getIncorrectAnswers } from '../redux/View/selectors';
import { checkAnswers, setAnswer, unsetAnswer } from '../redux/View/actions';

class LabelDragDrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: { loaded: false },
    };
  }

  onDrop(term, target) {
    const { data, dispatch } = this.props;

    if (target && typeof target.index !== 'undefined') {
      const terms = data.get('terms');
      const answer = terms.get(target.index).set('index', target.index);
      // if the target doesn't return position values, it's already been set to something else
      if (target.left) {
        dispatch.setAnswer(term.index, answer);
      }
    } else {
      dispatch.unsetAnswer(term.index);
    }
  }

  onImageLoad({ target: { naturalWidth, naturalHeight } }) {
    this.setState({ image: { loaded: true, naturalWidth, naturalHeight } });
  }

  getTargetIsAnswered(index) {
    const { answers } = this.props;
    return !answers.filter(value => value.get('index') === index).isEmpty();
  }

  getTermPosition(data) {
    const {
      image: {
        naturalWidth,
        naturalHeight,
      },
    } = this.state;
    const leftPx = parseFloat(data.get('left') || 0);
    const topPx = parseFloat(data.get('top') || 0);
    const leftPct = leftPx / naturalWidth * 100;
    const topPct = topPx / naturalHeight * 100;
    return { left: `${leftPct}%`, top: `${topPct}%` };
  }

  renderAnswerStatus() {
    const { answerIsCorrect, dispatch } = this.props;

    if (answerIsCorrect) {
      return (
        <div className="message success">
          <span>correct</span>
          &nbsp;
          <i aria-hidden="true" className="fas fa-check-square" />
        </div>
      );
    }

    return (
      <button type="button" className="check-answer" onClick={() => dispatch.checkAnswers()}>
        Check Answer
      </button>
    );
  }

  renderTerms(terms, answeredTerms) {
    const { answers } = this.props;
    return terms.reduce((acc, data, index) => {
      const term = data.get('term');
      const answer = answers.get(`${index}`);
      const style = answer
        ? this.getTermPosition(answer)
        : {};
      if (answeredTerms === !!answer) {
        return acc.push(
          <DragDropTerm
            key={term}
            index={index}
            term={term}
            isAnswered={answeredTerms}
            isDetached={answeredTerms}
            onDrop={(initiator, target) => this.onDrop(initiator, target)}
            style={style}
          />,
        );
      }
      return acc;
    }, List());
  }

  renderTermList(terms) {
    const termList = this.renderTerms(terms, false);
    if (termList.isEmpty()) {
      return this.renderAnswerStatus();
    }
    return (
      <ul>
        {termList}
      </ul>
    );
  }

  renderDropTargets(terms) {
    const {
      image: {
        loaded,
      },
    } = this.state;
    const { baseClass } = this.props;
    if (loaded) {
      return (
        terms.map(
          (data, index) => {
            const key = `${baseClass}-point-${index}`;
            const term = data.get('term');
            const isAnswered = this.getTargetIsAnswered(index);
            const position = this.getTermPosition(data);

            return (
              <DragDropTarget
                key={key}
                index={index}
                term={term}
                isAnswered={isAnswered}
                left={position.left}
                top={position.top}
              />
            );
          },
        )
      );
    }
    return null;
  }

  render() {
    const { data, lastEdit, mediaBaseUrl } = this.props;
    const terms = data.get('terms') || List();
    const imgSrc = data.get('image');
    return (
      <div className="layout-7525">
        <section className="figure">
          <div className="figure-wrapper">
            { imgSrc
              ? (
                <img
                  src={`${mediaBaseUrl}/${imgSrc}?t=${lastEdit}`}
                  alt="figure"
                  onLoad={e => this.onImageLoad(e)}
                />
              )
              : null
            }
            { this.renderDropTargets(terms) }
            { this.renderTerms(terms, true) }
          </div>
        </section>
        <section className="terms">
          { this.renderTermList(terms) }
        </section>
      </div>
    );
  }
}


LabelDragDrop.propTypes = {
  answers: PropTypes.instanceOf(Map).isRequired,
  answerIsCorrect: PropTypes.bool.isRequired,
  baseClass: PropTypes.string,
  data: PropTypes.instanceOf(Map).isRequired,
  dispatch: PropTypes.object.isRequired,
  lastEdit: PropTypes.number.isRequired,
  mediaBaseUrl: PropTypes.string.isRequired,
  /* eslint-disable react/no-unused-prop-types */
  pageNumber: PropTypes.number.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

LabelDragDrop.defaultProps = {
  baseClass: 'label',
};

const mapStateToProps = (state, { pageNumber }) => ({
  answers: getAnswersMap(state, pageNumber, ['dragdrop']) || Map(),
  answerIsCorrect: getIncorrectAnswers(state, pageNumber, ['dragdrop']).isEmpty(),
});

const mapDispatchToProps = (dispatch, { pageNumber }) => ({
  dispatch: {
    checkAnswers: () => {
      dispatch(checkAnswers(pageNumber, ['dragdrop']));
    },
    setAnswer: (index, answer) => {
      dispatch(setAnswer(pageNumber, ['dragdrop', `${index}`], answer));
    },
    unsetAnswer: (index) => {
      dispatch(unsetAnswer(pageNumber, ['dragdrop', `${index}`]));
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LabelDragDrop);
