import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { authenticate } from '../../services/Authentication';
import {
  AUTHENTICATION_REQUEST,
  succeeded,
  failed,
} from './actions';


export function* doAuthentication({ email, password, googleToken }) {
  try {
    const result = yield call(authenticate, email, password, googleToken);
    const error = result.get('error');
    if (error) {
      yield put(failed(error));
    } else {
      yield put(succeeded(result));
    }
  } catch (error) {
    yield put(failed('Error contacting authentication server'));
  }
}

export function* watchAuthenticationRequest() {
  yield takeLatest(AUTHENTICATION_REQUEST, doAuthentication);
}

export default function* rootSaga() {
  yield all([
    watchAuthenticationRequest(),
  ]);
}
