import React from 'react';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';

const DragDropTerm = (props) => {
  const {
    connectDragSource,
    connectDropTarget,
    isAnswered,
    isDetached,
    isDragging,
    onHoverTerm,
    style,
    term,
  } = props;
  const answered = isAnswered ? 'answered' : '';
  const dragging = isDragging ? 'dragging' : '';
  const className = `term ${dragging} ${answered}`;
  const element = isDetached
    ? (
      <div key={term} className={className} style={style} onMouseEnter={onHoverTerm}>
        {term}
      </div>
    )
    : (
      <li key={term} className={className} style={style} onMouseEnter={onHoverTerm}>
        {term}
      </li>
    );
  return connectDragSource(connectDropTarget(element));
};

const dragSpec = {
  beginDrag: ({
    index,
    isDetached,
    onBeginDrag,
    term,
  }) => {
    if (onBeginDrag) {
      onBeginDrag({ index, term });
    }
    return { index, term, isDetached };
  },
  endDrag: ({
    index,
    onDrop,
    onEndDrag,
    term,
  }, monitor) => {
    if (onEndDrag) {
      onEndDrag({ index, term });
    }
    if (onDrop) {
      if (monitor.didDrop()) {
        onDrop({ index, term }, monitor.getDropResult());
      } else {
        onDrop({ index, term }, null);
      }
    }
  },
};

const dragCollect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});

const dropCollect = connect => ({
  connectDropTarget: connect.dropTarget(),
});

const dropSpec = {
  drop: ({ index, term }) => ({ index, term }),
  hover: ({ index, term, onHoverTarget }, monitor) => {
    const { index: draggedIndex, term: draggedTerm } = monitor.getItem();
    if (index !== draggedIndex && onHoverTarget) {
      onHoverTarget({ index: draggedIndex, term: draggedTerm }, { index, term });
    }
  },
};

DragDropTerm.propTypes = {
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  isAnswered: PropTypes.bool,
  isDetached: PropTypes.bool,
  isDragging: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onBeginDrag: PropTypes.func,
  onDrop: PropTypes.func,
  onEndDrag: PropTypes.func,
  onHoverTarget: PropTypes.func,
  onHoverTerm: PropTypes.func,
  style: PropTypes.object,
  term: PropTypes.string.isRequired,
};

DragDropTerm.defaultProps = {
  onBeginDrag: () => {},
  onDrop: () => {},
  onEndDrag: () => {},
  onHoverTarget: () => {},
  onHoverTerm: () => {},
  style: {},
};

export default DropTarget(['TERM'], dropSpec, dropCollect)(
  DragSource('TERM', dragSpec, dragCollect)(DragDropTerm),
);
