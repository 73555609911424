import { fromJS } from 'immutable';

const authUrl = process.env.REACT_APP_AUTHENTICATION_URL;

export const authenticate = (email, password, googleToken) => {
  const body = googleToken ? {
    code: googleToken,
    provider: 'google',
  } : {
    email,
    password,
    role: 'aesadmin',
  };
  return fetch(authUrl, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(response => response.json())
    .then(json => fromJS(json));
};

export default authenticate;
