import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, fromJS } from 'immutable';
import { dismissModal } from 'aes-lesson-driver/src/redux/View/actions';
import { getPageSetFiles } from 'aes-lesson-driver/src/redux/Data/selectors';
import { listPageSetFiles } from 'aes-lesson-driver/src/redux/Data/actions';
import Modal from 'aes-lesson-driver/src/containers/Modal';
import { changePageFileField, uploadPageSetFile } from '../redux/Edits/actions';
import { getPageSet } from '../redux/Edits/selectors';
import Picker from '../components/Picker';

const FILE_TYPE_EXTENSIONS = fromJS({
  image: ['gif', 'jpg', 'png', 'webp'],
  audio: ['ogg', 'm4a', 'mp3', 'wav', 'weba'],
  video: ['avi', 'mp4', 'webm'],
});

export const createOptionsByType = (files, type) => (
  files
    .filter((file) => {
      const extension = file.slice(file.lastIndexOf('.') + 1).toLowerCase();
      return FILE_TYPE_EXTENSIONS.get(type).contains(extension);
    })
    .map(fileName => fromJS({ value: fileName, name: fileName }))
);

class FilePicker extends React.Component {
  componentDidMount() {
    const {
      dispatch,
      files,
      moduleKey,
      pageSetKey,
    } = this.props;

    if (files.isEmpty()) {
      dispatch.listPageSetFiles(moduleKey, pageSetKey);
    }
  }

  onClickFile(event) {
    const {
      dispatch,
      fieldPath,
      pageSetKey,
      pageNumber,
    } = this.props;
    const fileName = event.target.value;

    event.preventDefault();
    dispatch.changePageFileField(pageSetKey, pageNumber, fieldPath, fileName);
    dispatch.dismiss();
  }

  onSelectFile(event) {
    const {
      dispatch,
      fieldPath,
      pageSetKey,
      pageNumber,
    } = this.props;
    const input = event.target;

    event.preventDefault();
    if (input.files.length > 0) {
      dispatch.uploadPageSetFile(pageSetKey, pageNumber, fieldPath, input.files[0]);
      dispatch.dismiss();
      input.value = '';
    }
  }

  render() {
    const { fieldPath, files } = this.props;
    const type = fieldPath[fieldPath.length - 1];
    const options = createOptionsByType(files, type);
    return (
      <Modal>
        <Picker options={options} onOptionClick={e => this.onClickFile(e)}>
          <label htmlFor="file" className="button">
            Upload new&nbsp;
            {type}
            <input
              type="file"
              id="file"
              name="file"
              onChange={e => this.onSelectFile(e)}
            />
          </label>
          <button type="button" onClick={e => this.onClickFile(e)}>
            Remove&nbsp;
            {type}
          </button>
        </Picker>
      </Modal>
    );
  }
}

FilePicker.propTypes = {
  dispatch: PropTypes.object.isRequired,
  fieldPath: PropTypes.array.isRequired,
  files: PropTypes.instanceOf(List).isRequired,
  moduleKey: PropTypes.string.isRequired,
  pageSetKey: PropTypes.string.isRequired,
  pageNumber: PropTypes.number.isRequired,
};

const mapStateToProps = (state, props) => {
  const { fieldPath, pageSetKey, pageNumber } = props;
  const pageSet = getPageSet(state, pageSetKey);
  const moduleKey = pageSet.get('module_key');
  const files = getPageSetFiles(state, pageSetKey);
  return {
    fieldPath,
    files,
    moduleKey,
    pageSetKey,
    pageNumber,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch: {
    changePageFileField: (pageSetKey, pageNumber, fieldPath, fileName) => {
      dispatch(changePageFileField(pageSetKey, pageNumber, fieldPath, fileName));
    },
    dismiss: () => dispatch(dismissModal()),
    listPageSetFiles: (moduleKey, pageSetKey) => dispatch(listPageSetFiles(moduleKey, pageSetKey)),
    uploadPageSetFile: (pageSetKey, pageNumber, fieldPath, file) => {
      dispatch(uploadPageSetFile(pageSetKey, pageNumber, fieldPath, file));
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FilePicker);
