import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

class FillInBlankField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { fieldValue: '' };
  }

  onChange({ target: { value } }) {
    const { onSetAnswer } = this.props;
    this.setState({ fieldValue: value });
    onSetAnswer(value);
  }

  renderAttempts() {
    const { attempts, questionData } = this.props;
    const correctAnswer = questionData.get('answer');
    if (!attempts.isEmpty()) {
      return (
        <ol className="attempts">
          {attempts.map((value, index) => (
            value !== correctAnswer
              /* eslint-disable-next-line react/no-array-index-key */
              ? <li key={`${index}_${value}`}><del>{value}</del></li>
              : null
          ))}
        </ol>
      );
    }
    return null;
  }

  render() {
    const { answer, questionData } = this.props;
    const { fieldValue } = this.state;
    const value = (answer !== fieldValue) ? answer : fieldValue;
    const number = Math.floor(questionData.get('number'));
    const inputId = `question_${number}_input`;

    return (
      <Fragment>
        <label htmlFor={inputId}>
          <span className="sr-only">answer</span>
          <input id={inputId} value={value || ''} onChange={e => this.onChange(e)} />
        </label>
        {this.renderAttempts()}
      </Fragment>
    );
  }
}

FillInBlankField.propTypes = {
  answer: PropTypes.string,
  attempts: PropTypes.instanceOf(List),
  onSetAnswer: PropTypes.func.isRequired,
  questionData: PropTypes.instanceOf(Map).isRequired,
};

FillInBlankField.defaultProps = {
  answer: '',
  attempts: List(),
};

export default FillInBlankField;
