import { List, Map } from 'immutable';
import {
  DRAGDROP_TYPE_CATEGORIZE,
  DRAGDROP_TYPE_MATCH_IMAGE,
  DRAGDROP_TYPE_MATCH_TEXT,
  DRAGDROP_TYPE_SEQUENCE,
  QUESTION_TYPE_FILL_IN_BLANK,
  QUESTION_TYPE_MULTIPLE_CHOICE,
  QUESTION_TYPE_OPEN_ENTRY,
  QUESTION_TYPE_TRUE_FALSE,
} from '../../constants';
import { getUrlParameter } from '../../util';

export const getActiveModal = ({ view }) => view.get('activeModal');

export const getActiveSlide = ({ view }) => view.get('activeSlide');

export const getAnswer = ({ view }, pageNumber, answerPath) => (
  view.getIn(['answers', `${pageNumber}`].concat(answerPath))
);

export const getAnswersMap = ({ view }, pageNumber, answerPath) => (
  view.getIn(['answers', `${pageNumber}`].concat(answerPath)) || Map()
);

export const getAllAnswers = ({ view }) => view.get('answers');

export const getAttemptsMap = ({ view }, pageNumber, answerPath) => (
  view.getIn(['attempts', `${pageNumber}`].concat(answerPath)) || Map()
);

export const getAttemptsList = ({ view }, pageNumber, answerPath) => (
  view.getIn(['attempts', `${pageNumber}`].concat(answerPath)) || List()
);

export const getAutoPlay = ({ view }) => !!view.get('autoPlay');

export const getInitialLoad = ({ view }) => view.get('initialLoad');

export const getPageData = ({ data }, pageNumber) => {
  const pageSets = data.get('pageSets');
  const pageSet = pageSets && pageSets.first();
  if (pageSet) {
    return pageSet.getIn(['pages', pageNumber - 1]);
  }
  return Map();
};

export const getScormData = ({ view }) => view.get('scorm');

export const getCorrectAnswers = (state, pageNumber, questionPath) => {
  const pageData = getPageData(state, pageNumber);
  const question = pageData.getIn(questionPath);
  const type = question.get('type');
  switch (type) {
    case DRAGDROP_TYPE_CATEGORIZE.key:
      return question.get('categories', Map())
        .map((values, category) => (
          values.get('terms')
            .reduce((acc, term, index) => (
              acc.set(`${index}`, category)
            ), Map())
        ));
    case DRAGDROP_TYPE_MATCH_IMAGE.key:
      return question.get('terms', List())
        .reduce((acc, term, index) => (
          acc.set(`${index}`, term.set('index', index))
        ), Map());
    case DRAGDROP_TYPE_MATCH_TEXT.key:
      return question.get('terms', List())
        .reduce((acc, term, index) => acc.set(`${index}`, index), Map());
    case DRAGDROP_TYPE_SEQUENCE.key:
      return question.get('terms', List())
        .map((values, index) => values.set('index', index));
    case QUESTION_TYPE_OPEN_ENTRY.key:
      return 'NA';
    case QUESTION_TYPE_FILL_IN_BLANK.key:
      return question.get('answer', '')
        .split(',')[0].trim();
    case QUESTION_TYPE_TRUE_FALSE.key:
    case QUESTION_TYPE_MULTIPLE_CHOICE.key:
      return question.get('answer');
    default:
      return false;
  }
};

export const getIncorrectAnswers = (state, pageNumber, questionPath) => {
  const { view } = state;
  const pageData = getPageData(state, pageNumber);
  const answers = view.getIn(['answers', `${pageNumber}`].concat(questionPath));
  const question = pageData.getIn(questionPath) || Map();
  const type = question.get('type');

  switch (type) {
    case DRAGDROP_TYPE_CATEGORIZE.key:
      return question.get('categories', Map())
        .reduce((acc, values, category) => {
          const terms = values.get('terms', List())
            .map((text, index) => Map({
              text,
              index,
            }));
          const incorrectTerms = terms.filterNot(
            (term, index) => answers && answers.getIn([category, `${index}`]) === category,
          );
          if (!incorrectTerms.isEmpty()) {
            return acc.set(category, incorrectTerms);
          }
          return acc;
        }, Map());
    case DRAGDROP_TYPE_MATCH_IMAGE.key:
      return question.get('terms', List())
        .reduce((acc, term, index) => {
          const answer = (answers && answers.get(`${index}`)) || Map();
          if (answer.get('index') !== index) {
            return acc.set(`${index}`, answer);
          }
          return acc;
        }, Map());
    case DRAGDROP_TYPE_MATCH_TEXT.key:
      return question.get('terms', List())
        .reduce((acc, term, index) => {
          const answer = (answers && answers.get(`${index}`));
          if (answer !== index) {
            return acc.set(`${index}`, answer);
          }
          return acc;
        }, Map());
    case DRAGDROP_TYPE_SEQUENCE.key:
      return answers
        ? Map(answers.filterNot((value, index) => (value.get('index') === index)))
        : Map({ error: 'empty' });
    case QUESTION_TYPE_FILL_IN_BLANK.key:
      return answers && List(question.get('answer')
        .split(','))
        .map(str => str.trim()
          .toLowerCase())
        .includes(answers.trim().toLowerCase()) ? Map() : Map({ answers });
    case QUESTION_TYPE_OPEN_ENTRY.key:
      return answers
        ? List()
        : Map({ error: 'empty' });
    case QUESTION_TYPE_TRUE_FALSE.key:
    case QUESTION_TYPE_MULTIPLE_CHOICE.key:
      return answers === question.get('answer')
        ? Map()
        : Map({ answers });
    default:
      return Map({ error: 'unknown question type' });
  }
};

export const getAllIncorrectAnswers = (state, pageNumber) => {
  const page = getPageData(state, pageNumber);

  if (page.has('questions')) {
    return page.get('questions')
      .reduce((acc, value, index) => {
        const incorrect = getIncorrectAnswers(state, pageNumber, ['questions', `${index}`]);
        if (!incorrect.isEmpty()) {
          return acc.set(`${index}`, incorrect);
        }
        return acc;
      }, Map());
  }

  if (page.has('dragdrop')) {
    return getIncorrectAnswers(state, pageNumber, ['dragdrop']);
  }

  return Map();
};

export const getAllQuestionsAreCorrect = (state, pageNumber) => (
  getUrlParameter('token') === 'TEACHER'
  || getAllIncorrectAnswers(state, pageNumber).isEmpty()
);

export const getBookmarkAnswers = (state, bookmark) => {
  const { data } = state;
  const pageSet = data.get('pageSets').first();
  if (pageSet) {
    const pages = pageSet.get('pages');
    const pageCount = pages.size;
    const lastPage = bookmark > pageCount ? pageCount : bookmark;
    const answers = pages.reduce((answersMap, page, pageIndex) => {
      if (pageIndex < lastPage) { // only return answers up to last page visited
        const pageNumber = pageIndex + 1;

        if (page?.has('questions')) {
          const questionAnswers = page.get('questions')
            .reduce((acc, value, index) => {
              const answer = getCorrectAnswers(state, pageNumber, ['questions', `${index}`]);
              return acc.set(`${index}`, answer);
            }, Map());
          return answersMap.setIn([`${pageNumber}`, 'questions'], questionAnswers);
        }

        if (page?.has('dragdrop')) {
          const dragDropAnswers = getCorrectAnswers(state, pageNumber, ['dragdrop']);
          return answersMap.setIn([`${pageNumber}`, 'dragdrop'], dragDropAnswers);
        }
      }

      return answersMap; // if no questions, or page is greater than last visited, return unchanged
    }, Map());

    return answers;
  }
  return Map();
};
