import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import {
  getPageSet,
  getAllPageSets,
} from 'aes-lesson-driver/src/redux/Data/selectors';
import Modal from 'aes-lesson-driver/src/containers/Modal';
import { getAllowedModules } from '../redux/Data/selectors';
import { duplicatePageSet } from '../redux/Edits/actions';
import './CreatorModals.scss';


class PageSetDuplicator extends React.Component {
  constructor(props) {
    super(props);

    const { pageSet } = props;
    const moduleKey = pageSet.get('module_key');
    const newModuleTitle = pageSet.get('module_title');
    const newUnitKey = pageSet.get('unit_key');
    const newUnitTitle = pageSet.get('unit_title');

    this.state = {
      existingModuleKey: moduleKey,
      newModuleKey: moduleKey,
      newModuleTitle,
      newUnitKey,
      newUnitTitle,
      newPageSetKey: '',
      newPageSetTitle: '',
      error: '',
    };
  }

  onSelectModule(event) {
    const { modules } = this.props;
    const { value: existingModuleKey } = event.target;
    const newModuleKey = existingModuleKey;
    const newModuleTitle = modules.get(existingModuleKey);

    this.setState({
      existingModuleKey,
      newModuleKey,
      newModuleTitle,
    });
  }

  onChangeModuleKey(event) {
    const { modules } = this.props;
    const { value: newModuleKey } = event.target;
    if (modules.has(newModuleKey)) {
      this.setState({
        newModuleKey,
        existingModuleKey: newModuleKey,
        newModuleTitle: modules.get(newModuleKey),
      });
    } else {
      this.setState({ newModuleKey, existingModuleKey: '' });
    }
  }

  onChangeModuleTitle(event) {
    const { existingModuleKey } = this.state;
    const { value: newModuleTitle } = event.target;
    if (existingModuleKey) {
      this.setState({
        existingModuleKey: '',
        newModuleKey: '',
        newModuleTitle,
      });
    } else {
      this.setState({ newModuleTitle });
    }
  }

  onChangeUnitKey(event) {
    const { value: newUnitKey } = event.target;
    this.setState({ newUnitKey });
  }

  onChangeUnitTitle(event) {
    const { value: newUnitTitle } = event.target;
    this.setState({ newUnitTitle });
  }

  onChangePageSetKey(event) {
    const { value: newPageSetKey } = event.target;
    this.setState({ newPageSetKey });
  }

  onChangePageSetTitle(event) {
    const { value: newPageSetTitle } = event.target;
    this.setState({ newPageSetTitle });
  }

  onClickDuplicate(event) {
    const { dispatch, pageSet } = this.props;
    const {
      newModuleKey,
      newModuleTitle,
      newUnitKey,
      newUnitTitle,
      newPageSetKey,
      newPageSetTitle,
    } = this.state;
    const moduleKey = pageSet.get('module_key');
    const pageSetKey = pageSet.get('page_set_key');
    event.preventDefault();
    dispatch.duplicate(
      moduleKey,
      pageSetKey,
      newModuleKey,
      newModuleTitle,
      newUnitKey,
      newUnitTitle,
      newPageSetKey,
      newPageSetTitle,
    );
  }

  render() {
    const {
      existingModuleKey,
      newUnitKey,
      newUnitTitle,
      newPageSetKey,
      newPageSetTitle,
      error,
    } = this.state;
    const { modules, pageSets } = this.props;
    const pageSetTaken = pageSets.has(newPageSetKey);
    const isFormValid = !pageSetTaken
      && !!(newUnitTitle && newUnitKey && newPageSetKey);

    return (
      <Modal>
        <section className="pageset-duplicator">
          <label htmlFor="module_select">
            Module:
            <select
              id="module_select"
              name="module_select"
              value={existingModuleKey}
              onChange={e => this.onSelectModule(e)}
            >
              <option value="">- Select -</option>
              {modules.map((name, key) => <option key={name} value={key}>{name}</option>).toList()}
            </select>
          </label>
          <label htmlFor="unit_key">
            Unit Key:
            <input
              id="unit_key"
              name="unit_key"
              value={newUnitKey}
              onChange={e => this.onChangeUnitKey(e)}
              required
            />
          </label>
          <label htmlFor="unit_title">
            Unit Name:
            <input
              id="unit_title"
              name="unit_title"
              value={newUnitTitle}
              onChange={e => this.onChangeUnitTitle(e)}
              required
            />
          </label>
          <label htmlFor="pageset_key">
            Lesson Key:
            <input
              id="pageset_key"
              name="pageset_key"
              value={newPageSetKey}
              onChange={e => this.onChangePageSetKey(e)}
              required
            />
          </label>
          { pageSetTaken
            ? <div className="error">Lesson Key already in use</div>
            : null }
          <label htmlFor="pageset_title">
            Lesson Name:
            <input
              id="pageset_title"
              name="pageset_title"
              value={newPageSetTitle}
              onChange={e => this.onChangePageSetTitle(e)}
              placeholder="enter title -or- blank for branch"
            />
          </label>
          { error
            ? <div className="error">{error}</div>
            : null }
          <div className="actions">
            <button
              type="button"
              onClick={e => this.onClickDuplicate(e)}
              disabled={!isFormValid}
            >
              Duplicate Lesson
            </button>
          </div>
        </section>
      </Modal>
    );
  }
}

PageSetDuplicator.propTypes = {
  dispatch: PropTypes.object.isRequired,
  modules: PropTypes.instanceOf(Map).isRequired,
  pageSet: PropTypes.instanceOf(Map).isRequired,
  pageSets: PropTypes.instanceOf(Map).isRequired,
};

const mapStateToProps = (state, { pageSetKey }) => {
  const pageSet = getPageSet(state, pageSetKey);
  const modules = getAllowedModules(state);
  const pageSets = getAllPageSets(state);

  return {
    modules,
    pageSet,
    pageSets,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch: {
    duplicate: (
      moduleKey,
      pageSetKey,
      newModuleKey,
      newModuleTitle,
      newUnitKey,
      newUnitTitle,
      newPageSetKey,
      newPageSetTitle,
    ) => dispatch(duplicatePageSet(
      moduleKey,
      pageSetKey,
      newModuleKey,
      newModuleTitle,
      newUnitKey,
      newUnitTitle,
      newPageSetKey,
      newPageSetTitle,
    )),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PageSetDuplicator);
