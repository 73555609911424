import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { getMediaBaseUrl, getPageSetFiles } from 'aes-lesson-driver/src/redux/Data/selectors';
import { dismissModal } from 'aes-lesson-driver/src/redux/View/actions';
import { listPageSetFiles } from 'aes-lesson-driver/src/redux/Data/actions';
import AudioPlayer from 'aes-lesson-driver/src/components/AudioPlayer';
import Modal from 'aes-lesson-driver/src/containers/Modal';
import AesMarkdownEditor from '../components/AesMarkdownEditor';
import {
  getGlossaryWordIndex,
  getGlossaryWords,
  getPageSet,
} from '../redux/Edits/selectors';
import {
  createGlossaryWord,
  updateGlossaryWord,
  uploadGlossaryWordFile,
} from '../redux/Edits/actions';
import './GlossaryWordEditor.scss';

class GlossaryWordEditor extends React.Component {
  constructor(props) {
    super(props);
    const { glossaryWord } = props;
    this.state = { glossaryWord };
  }

  componentDidMount() {
    const { dispatch, files } = this.props;
    if (files.isEmpty()) {
      dispatch.listPageSetFiles();
    }
  }

  onChangeMarkdown(markdown) {
    const { glossaryWord } = this.state;

    this.setState({ glossaryWord: glossaryWord.set('text', markdown) });
  }

  onChangeWord(event) {
    const word = event.target.value;
    const { glossaryWord } = this.state;

    event.preventDefault();
    this.setState({ glossaryWord: glossaryWord.set('word', word) });
  }

  onClickSave(event) {
    let { glossaryWord } = this.state;
    const { callback, dispatch, glossaryWordIndex } = this.props;

    event.preventDefault();

    if (glossaryWordIndex < 0) {
      const key = glossaryWord.get('word')
        .toUpperCase()
        .replace(/\s+/g, '_')
        .replace(/\W+/g, '');
      glossaryWord = glossaryWord.set('key', key);
      dispatch.createGlossaryWord(glossaryWord);
    } else {
      dispatch.updateGlossaryWord(glossaryWordIndex, glossaryWord);
    }

    dispatch.dismiss();

    if (callback) {
      callback(glossaryWord);
    }
  }

  onSelectFile(event, fieldName) {
    const { dispatch } = this.props;
    const { glossaryWord } = this.state;
    const input = event.target;

    event.preventDefault();

    if (input.files.length > 0) {
      const file = input.files[0];
      this.setState({ glossaryWord: glossaryWord.set(fieldName, file.name) });
      dispatch.uploadGlossaryWordFile(input.files[0]);
      input.value = '';
    }
  }

  canSave() {
    const { glossaryWord } = this.state;
    return !!glossaryWord.get('word') && !!glossaryWord.get('text');
  }

  render() {
    const { files, mediaBaseUrl } = this.props;
    const { glossaryWord } = this.state;
    const audio = glossaryWord.get('audio');
    const audioFile = files.find(file => file === audio);
    const image = glossaryWord.get('image');
    const imageFile = files.find(file => file === image);
    const markdown = glossaryWord.get('text');
    const word = glossaryWord.get('word');
    return (
      <Modal>
        <article className="glossary-word-editor">
          <div className="title-row">
            <label htmlFor="word" id="modal_title">
              Word:
              <input type="text" name="word" id="word" value={word} onChange={e => this.onChangeWord(e)} />
            </label>
            <label htmlFor="audio" className="button">
              Change Audio
              <input
                type="file"
                id="audio"
                name="audio"
                onChange={e => this.onSelectFile(e, 'audio')}
              />
            </label>
            <label htmlFor="image" className="button">
              Change Image
              <input
                type="file"
                id="image"
                name="image"
                onChange={e => this.onSelectFile(e, 'image')}
              />
            </label>
            <button
              type="button"
              className="save"
              disabled={!this.canSave()}
              onClick={e => this.onClickSave(e)}
            >
              Save
            </button>
          </div>
          <div className="flex">
            <div>
              <AesMarkdownEditor
                initialValue={markdown}
                mediaBaseUrl={mediaBaseUrl}
                onChange={value => this.onChangeMarkdown(value)}
              />
              {audioFile
                ? <AudioPlayer mediaBaseUrl={mediaBaseUrl} audio={audioFile} />
                : null
              }
            </div>
            {imageFile
              ? <img src={`${mediaBaseUrl}/${imageFile}`} alt={word} />
              : null
            }
          </div>
        </article>
      </Modal>
    );
  }
}

GlossaryWordEditor.propTypes = {
  callback: PropTypes.func,
  dispatch: PropTypes.object.isRequired,
  files: PropTypes.instanceOf(List).isRequired,
  glossaryWord: PropTypes.instanceOf(Map).isRequired,
  glossaryWordIndex: PropTypes.number.isRequired,
  mediaBaseUrl: PropTypes.string.isRequired,
  // false flag
  /* eslint-disable react/no-unused-prop-types */
  glossaryWordKey: PropTypes.string.isRequired,
  pageSetKey: PropTypes.string.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

GlossaryWordEditor.defaultProps = {
  callback: null,
};

const mapStateToProps = (state, { pageSetKey, glossaryWordKey }) => {
  const pageSet = getPageSet(state, pageSetKey);
  const moduleKey = pageSet.get('module_key');
  const files = getPageSetFiles(state, pageSetKey);
  const mediaBaseUrl = getMediaBaseUrl(state, pageSetKey);
  const glossaryWords = getGlossaryWords(state, pageSetKey);
  const glossaryWordIndex = !glossaryWords.isEmpty()
    ? getGlossaryWordIndex(state, pageSetKey, glossaryWordKey)
    : -1;
  const glossaryWord = glossaryWordIndex > -1
    ? glossaryWords.get(glossaryWordIndex)
    : Map({ word: 'Word', text: 'Definition' });

  return {
    files,
    glossaryWord,
    glossaryWordIndex,
    mediaBaseUrl,
    moduleKey,
  };
};

const mapDispatchToProps = (dispatch, { moduleKey, pageSetKey }) => ({
  dispatch: {
    createGlossaryWord: glossaryWord => dispatch(createGlossaryWord(pageSetKey, glossaryWord)),
    listPageSetFiles: () => dispatch(listPageSetFiles(moduleKey, pageSetKey)),
    updateGlossaryWord: (glossaryWordIndex, glossaryWord) => {
      dispatch(updateGlossaryWord(pageSetKey, glossaryWordIndex, glossaryWord));
    },
    uploadGlossaryWordFile: file => dispatch(uploadGlossaryWordFile(pageSetKey, file)),
    dismiss: () => dispatch(dismissModal()),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GlossaryWordEditor);
