import React from 'react';
import PropTypes from 'prop-types';
import AesMarkdown from './AesMarkdown';

const MarkdownSection = ({ source, mediaBaseUrl }) => (
  <section className="text">
    <AesMarkdown source={source} mediaBaseUrl={mediaBaseUrl} />
  </section>
);

MarkdownSection.propTypes = {
  source: PropTypes.string.isRequired,
  mediaBaseUrl: PropTypes.string.isRequired,
};

export default MarkdownSection;
