import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromJS, List } from 'immutable';
import { dismissModal } from 'aes-lesson-driver/src/redux/View/actions';
import { getPageSetFiles } from 'aes-lesson-driver/src/redux/Data/selectors';
import { listPageSetFiles } from 'aes-lesson-driver/src/redux/Data/actions';
import Modal from 'aes-lesson-driver/src/containers/Modal';
import { uploadPageSetFile } from '../redux/Edits/actions';
import Picker from '../components/Picker';
import { getPageSet } from '../redux/Edits/selectors';
import './FormLinkCreator.scss';

const createOptions = files => (
  files
    .filter((file) => {
      const extension = file.slice(file.lastIndexOf('.') + 1).toLowerCase();
      return extension === 'pdf';
    })
    .map(fileName => fromJS({ value: fileName, name: fileName }))
);

class FormLinkCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      linkText: '',
    };
  }

  componentDidMount() {
    const { dispatch, files } = this.props;
    if (files.isEmpty()) {
      dispatch.listPageSetFiles();
    }
  }

  onChangeLinkText(event) {
    const linkText = event.target.value;

    event.preventDefault();
    this.setState({ linkText });
  }

  onClickSave(event) {
    const { linkText, fileName } = this.state;
    const { callback, dispatch } = this.props;

    event.preventDefault();

    dispatch.dismiss();

    callback({ linkText, fileName });
  }

  onClickFile(event) {
    const { target: { value } } = event;
    this.setState({ fileName: value });
  }

  onSelectFile(event) {
    const { dispatch } = this.props;
    const input = event.target;

    event.preventDefault();

    if (input.files.length > 0) {
      const file = input.files[0];
      this.setState({ fileName: file.name });
      dispatch.uploadFile(input.files[0]);
      input.value = '';
    }
  }

  canSave() {
    const { linkText, fileName } = this.state;
    return !!linkText && !!fileName;
  }

  render() {
    const { linkText, fileName } = this.state;
    const { files } = this.props;
    const options = createOptions(files);
    return (
      <Modal>
        <section className="form-link-creator">
          <div className="title-row">
            <label htmlFor="word" id="modal_title">
              Link Text:
              <input type="text" name="word" id="word" value={linkText} onChange={e => this.onChangeLinkText(e)} />
            </label>
          </div>
          {fileName
            ? (
              <Fragment>
                <div className="selected-form">{`Form: ${fileName}`}</div>
                <div className="actions">
                  <button
                    type="button"
                    className="save"
                    disabled={!this.canSave()}
                    onClick={e => this.onClickSave(e)}
                  >
                    Insert Link
                  </button>
                </div>
              </Fragment>
            ) : (
              <Picker options={options} onOptionClick={e => this.onClickFile(e)}>
                <label htmlFor="file" className="button">
                  Upload new form
                  <input
                    type="file"
                    accept="application/pdf"
                    id="file"
                    name="file"
                    onChange={e => this.onSelectFile(e)}
                  />
                </label>
              </Picker>
            )
          }
        </section>
      </Modal>
    );
  }
}

FormLinkCreator.propTypes = {
  callback: PropTypes.func.isRequired,
  dispatch: PropTypes.object.isRequired,
  files: PropTypes.instanceOf(List).isRequired,
  // false flag
  /* eslint-disable react/no-unused-prop-types */
  moduleKey: PropTypes.string.isRequired,
  pageSetKey: PropTypes.string.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

const mapStateToProps = (state, { pageSetKey }) => {
  const files = getPageSetFiles(state, pageSetKey);
  const pageSet = getPageSet(state, pageSetKey);
  const moduleKey = pageSet.get('module_key');
  return {
    files,
    moduleKey,
  };
};

const mapDispatchToProps = (dispatch, { moduleKey, pageSetKey }) => ({
  dispatch: {
    listPageSetFiles: () => dispatch(listPageSetFiles(moduleKey, pageSetKey)),
    uploadFile: file => dispatch(uploadPageSetFile(pageSetKey, null, null, file)),
    dismiss: () => dispatch(dismissModal()),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FormLinkCreator);
