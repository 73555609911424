import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { getGlossaryWord, getMediaBaseUrl } from '../redux/Data/selectors';
import AudioPlayer from '../components/AudioPlayer';
import AesMarkdown from '../components/AesMarkdown';
import Modal from './Modal';

const renderContent = (glossaryWord, mediaBaseUrl) => {
  const audio = glossaryWord.get('audio');
  const image = glossaryWord.get('image');
  const markdown = glossaryWord.get('text');
  const title = glossaryWord.get('word');
  return (
    <article className="glossary-word">
      <h3 id="modal_title">{title}</h3>
      <div className="flex">
        <div>
          <AesMarkdown mediaBaseUrl={mediaBaseUrl} source={markdown} />
          {audio
            ? <AudioPlayer mediaBaseUrl={mediaBaseUrl} audio={audio} />
            : null
          }
        </div>
        {image
          ? <img src={`${mediaBaseUrl}/${image}`} alt={title} />
          : null
        }
      </div>
    </article>
  );
};

const GlossaryWord = ({ glossaryWord, mediaBaseUrl }) => (
  <Modal>
    {glossaryWord
      ? renderContent(glossaryWord, mediaBaseUrl)
      : (
        <article>
          <h3 id="modal_title">Error</h3>
          <p>Glossary Term not found.</p>
        </article>
      )
    }
  </Modal>
);

GlossaryWord.propTypes = {
  glossaryWord: PropTypes.instanceOf(Map),
  mediaBaseUrl: PropTypes.string.isRequired,
  // false flag
  /* eslint-disable react/no-unused-prop-types */
  glossaryWordKey: PropTypes.string.isRequired,
  pageSetKey: PropTypes.string.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

GlossaryWord.defaultProps = {
  glossaryWord: null,
};

const mapStateToProps = (state, { pageSetKey, glossaryWordKey }) => {
  const mediaBaseUrl = getMediaBaseUrl(state, pageSetKey);
  const glossaryWord = getGlossaryWord(state, pageSetKey, glossaryWordKey);

  return {
    mediaBaseUrl,
    glossaryWord,
  };
};

export default connect(mapStateToProps)(GlossaryWord);
