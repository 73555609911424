import {
  all,
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import {
  clearBookmark,
  exit,
  getAnswers as getScormAnswers,
  getBookmark,
  getPageNumber,
  setAnswers,
  setComplete,
  setPageNumber,
} from '../../services/scorm';
import {
  loadAnswers,
  loadPageNumber,
  setAnswer,
  setAttempts,
  unsetAnswer,
  VIEW_CHECK_ANSWERS,
  VIEW_SCORM_EXIT,
  VIEW_SCORM_SAVE_DATA,
  VIEW_SCORM_SET_COMPLETE,
} from './actions';
import {
  getAllAnswers,
  getAnswer,
  getAttemptsList, getBookmarkAnswers,
  getCorrectAnswers,
  getIncorrectAnswers,
  getPageData,
} from './selectors';
import { DATA_LOAD_PAGE_SET_SUCCESS } from '../Data/actions';
import {
  DRAGDROP_TYPE_CATEGORIZE,
  DRAGDROP_TYPE_SEQUENCE,
  QUESTION_TYPE_FILL_IN_BLANK,
} from '../../constants';

export function* doCheckAnswers({ pageNumber, answerPath }) {
  const answer = yield select(getAnswer, pageNumber, answerPath);
  const attempts = yield select(getAttemptsList, pageNumber, answerPath);
  const incorrectAnswers = yield select(getIncorrectAnswers, pageNumber, answerPath);
  const pageData = yield select(getPageData, pageNumber);
  const layout = pageData.get('layout');

  if (!incorrectAnswers.isEmpty()) {
    const rootPath = answerPath[0];

    if (rootPath === 'questions') {
      yield put(unsetAnswer(pageNumber, answerPath));
    } else {
      const type = pageData.getIn(['dragdrop', 'type']);

      if (type === DRAGDROP_TYPE_CATEGORIZE.key) {
        const unsetActions = incorrectAnswers.reduce((acc, terms, category) => (
          acc.concat(
            terms.map(term => (
              put(unsetAnswer(pageNumber, answerPath.concat([category, `${term.get('index')}`])))
            ))
              .toArray(),
          )), []);
        yield all(unsetActions);
      } else if (type !== DRAGDROP_TYPE_SEQUENCE.key) {
        const unsetActions = incorrectAnswers.map((value, key) => (
          put(unsetAnswer(pageNumber, [rootPath, key]))
        ))
          .toObject();
        yield all(unsetActions);
      }
    }
  }

  if (typeof answer !== 'undefined') {
    const giveAnswer = layout === 'Question'
      && pageData.getIn(answerPath.concat('type')) === QUESTION_TYPE_FILL_IN_BLANK.key
      && attempts.size > 2;

    yield put(setAttempts(pageNumber, answerPath, attempts.push(answer)));
    if (giveAnswer) {
      const correctAnswer = yield select(getCorrectAnswers, pageNumber, answerPath);
      yield put(setAnswer(pageNumber, answerPath, correctAnswer));
    }
  }
}

export function* doLoadScormData() {
  const bookmark = yield call(getBookmark);

  if (bookmark) {
    const answers = yield select(getBookmarkAnswers, bookmark);
    yield put(loadPageNumber(bookmark));
    yield put(loadAnswers(answers));
    yield call(clearBookmark);
  } else {
    const pageNumber = yield call(getPageNumber);
    const answers = yield call(getScormAnswers);

    if (pageNumber) {
      yield put(loadPageNumber(pageNumber));
    }
    if (answers) {
      yield put(loadAnswers(answers));
    }
  }
}

export function* doSaveScormData({ pageNumber }) {
  const answers = yield select(getAllAnswers);
  if (answers) {
    yield call(setAnswers, answers);
  }
  yield call(setPageNumber, pageNumber);
}

export function* doSetComplete() {
  yield call(setComplete);
}

export function* doExit() {
  yield call(exit);
}

export function* watchCheckAnswers() {
  yield takeLatest(VIEW_CHECK_ANSWERS, doCheckAnswers);
}

export function* watchDataLoad() {
  yield takeLatest(DATA_LOAD_PAGE_SET_SUCCESS, doLoadScormData);
}

export function* watchSaveScormData() {
  yield takeLatest(VIEW_SCORM_SAVE_DATA, doSaveScormData);
}

export function* watchComplete() {
  yield takeLatest(VIEW_SCORM_SET_COMPLETE, doSetComplete);
}

export function* watchExit() {
  yield takeLatest(VIEW_SCORM_EXIT, doExit);
}

export default function* rootSaga() {
  yield all([
    watchDataLoad(),
    watchSaveScormData(),
    watchComplete(),
    watchExit(),
    watchCheckAnswers(),
  ]);
}
