import { getModules } from 'aes-lesson-driver/src/redux/Data/selectors';
import { Map } from 'immutable';
import { isAesUser } from '../Authentication/selectors';

const AES_ONLY_MODULE_KEYS = Map({
  MAADMISCO: 'Admissions, Transfers, and Discharges',
  FRAIR: 'Airway - EMR',
  EMSAIR: 'Airway - EMT',
  MDSURGPCO: 'Ambulatory Surgery',
  HSANTMYCO: 'Anatomy and Physiology',
  HSBHEALTH: 'Behavioral Health',
  MABMECHCO: 'Body Mechanics',
  HSSTATSCO: 'Client Status',
  HSCPR2019: 'CPR/BLS 2019',
  HSCOMMUCO: 'Communications',
  MAELECTCO: 'Electrocardiography',
  HSEMERGCO: 'Emergency Care',
  EMSOPR: 'EMS Operations (EMT)',
  MAHASSTCO: 'Health Assistant, The',
  HSCARERCO: 'Health Care Careers',
  HSSYSTMCO: 'Healthcare Systems',
  HSHUMANCO: 'Human Growth and Development',
  HSINFECCO: 'Infection Control',
  HSLEGALCO: 'Legal and Ethical Responsibilities',
  FRMED: 'Medical - EMR',
  EMSMED: 'Medical - EMT',
  HSMATHECO: 'Medical Mathematics',
  HSMTERMCO: 'Medical Terminology',
  MANELIMCO: 'Nutrition and Elimination',
  FRASMT: 'Patient Assessment - EMR',
  EMSASMT: 'Patient Assessment - EMT',
  MACMFRTCO: 'Patient Comfort',
  HSPQUALCO: 'Personal Qualities',
  MDPHARMCO: 'Pharmacology (Medical Assistant)',
  EMSPRM: 'Pharmacology - EMT',
  MAPHLEBCO: 'Phlebotomy',
  MDPEXAMCO: 'Physical Exams',
  FRPRP: 'Preparatory - EMR',
  EMSPRP: 'Preparatory - EMT',
  CNAREHABCO: 'Rehabilitation and Restorative Care',
  EMSSHK: 'Shock and Resuscitation',
  MAPOPULCO: 'Special Populations',
  FRSPE: 'Special Populations - EMR',
  EMSPOP: 'Special Populations - EMT',
  MASPECCCO: 'Specimen Collection and Testing',
  MASURGPCO: 'Surgical Patient, The',
  FRTMA: 'Trauma - EMR',
  EMSTMA: 'Trauma - EMT',
  HSNUTRICO: 'Wellness and Nutrition',
  HSINFECCO_ES: 'Infection Control (Spanish)',
  ZZSTYLE: 'AES Style Guide',
  ALL_SAMPLE: 'ALL SAMPLES',
  ZZDRAGDROP: 'Drag Drop Samples',
  BHTEST: 'Test Copy Behavioral Health',
});

// eslint-disable-next-line import/prefer-default-export
export const getAllowedModules = (state) => {
  const allModules = getModules(state);
  if (isAesUser(state)) {
    return allModules;
  }
  return allModules.filter((value, key) => !AES_ONLY_MODULE_KEYS.has(key));
};
