import { usePreview } from 'react-dnd-multi-backend';
import React from 'react';

const DragDropPreview = () => {
  const { item = {}, display, style } = usePreview();
  const { term } = item;
  if (!display) {
    return null;
  }
  return (
    <div className="drag-drop-preview" style={style}>
      {term}
    </div>
  );
};

export default DragDropPreview;
