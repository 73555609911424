import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Link } from 'react-router-dom';
import './PageSetsList.scss';

const formatEditDate = (epoch) => {
  if (epoch) {
    const date = new Date(epoch);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const minutePadded = minute > 9 ? minute : `0${minute}`;
    return `${month}/${day}/${year} ${hour}:${minutePadded}`;
  }
  return null;
};

const PageSetsList = React.forwardRef(({
  apiKey, onClickDownload, onClickAesDownload, pageSets, isLessons, targetKey,
}, ref) => (
  <div className="list page-sets">
    <div className="row">
      <strong>Unit Number</strong>
      <strong>Unit Title</strong>
      <strong>PageSet Title</strong>
      <strong>PageSet Key</strong>
      <strong>Last Edited</strong>
      <strong>Download</strong>
    </div>
    {pageSets.map((modulePageSet) => {
      const pageSet = modulePageSet.get('page_set');
      const edited = pageSet.get('last_edited') * 1000;
      const title = pageSet.get('title');
      const unit = pageSet.get('unit_title');
      const unitNumber = modulePageSet.get('unit_number');
      const pageSetKey = pageSet.get('page_set_key');
      const isTarget = pageSetKey === targetKey;
      const moduleKey = pageSet.get('module_key');
      const path = `/${moduleKey}/${pageSetKey}`;
      const { REACT_APP_API_BASE_URL } = process.env;
      const textPath = `${REACT_APP_API_BASE_URL}pagesets/${moduleKey}/${pageSetKey}/text?api-key=${apiKey}`;

      if ((isLessons && !title) || (!isLessons && title)) {
        return null;
      }

      return (
        <div
          ref={isTarget ? ref : null}
          key={pageSetKey}
          className={`row page-set ${isTarget ? 'active' : null}`}
        >
          <Link to={path}>{unitNumber || '--'}</Link>
          <Link to={path}>{unit}</Link>
          <Link to={path}>{title}</Link>
          <Link to={path}>{pageSetKey}</Link>
          <Link to={path}>{formatEditDate(edited)}</Link>
          <a className="button assistive-listening" href={textPath} target="_blank" rel="noreferrer noopener">
            <i className="fa fa-assistive-listening-systems" />
          </a>
          <button type="button" onClick={() => onClickDownload(pageSetKey)}>
            iCEV
            {' '}
            <i className="fa fa-file-archive-o icev-zip" />
          </button>
          <button type="button" onClick={() => onClickAesDownload(pageSetKey)}>
            AES
            {' '}
            <i className="fa fa-file-archive-o aes-zip" />
          </button>
        </div>
      );
    })}
  </div>
));

PageSetsList.propTypes = {
  onClickDownload: PropTypes.func.isRequired,
  onClickAesDownload: PropTypes.func.isRequired,
  isLessons: PropTypes.bool,
  pageSets: PropTypes.instanceOf(List).isRequired,
  targetKey: PropTypes.string,
  apiKey: PropTypes.string.isRequired,
};

PageSetsList.defaultProps = {
  isLessons: false,
  targetKey: '',
};

export default PageSetsList;
