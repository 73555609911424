import { List, Map, OrderedMap } from 'immutable';

export const getModules = ({ data }) => data.get('modules');

export const getAllPageSets = ({ data }) => data.get('moduleOutline') || Map()
  .reduce((acc, pageSets) => acc.merge(pageSets), Map());

export const getOutlinePageSets = ({ data }, moduleKey) => data.getIn(['moduleOutline', moduleKey]) || OrderedMap();

export const getModulePageSets = ({ data }, moduleKey) => data.getIn(['modulePageSets', moduleKey]) || List();

export const getFirstPageSet = ({ data }) => data.get('pageSets').first() || Map();

export const getPageSet = ({ data }, pageSetKey) => data.getIn(['pageSets', pageSetKey]) || Map();

export const getPageSetFiles = ({ data }, pageSetKey) => data.getIn(['files', pageSetKey]) || List();

export const getGlossaryWord = (state, pageSetKey, glossaryWordKey) => {
  const { data } = state;
  const glossary = data.getIn(['pageSets', pageSetKey, 'glossary'])
    || getFirstPageSet(state).getIn(['linked_page_sets', pageSetKey, 'glossary'])
    || Map();
  return glossary.find(entry => entry.get('key') === glossaryWordKey);
};

export const getRootUrl = ({ data }) => data.get('rootUrl');

export const getMediaBaseUrl = (state, pageSetKey) => {
  const envUrl = process.env.REACT_APP_MEDIA_BASE_URL;
  const rootUrl = getRootUrl(state);
  return (envUrl && envUrl !== 'RELATIVE')
    ? `${envUrl}/${pageSetKey.toLowerCase()}`
    : `${rootUrl}/media/${pageSetKey.toLowerCase()}`;
};
