import React from 'react';
import PropTypes from 'prop-types';
import MarkdownInput from '@opuscapita/react-markdown';
import './AesMarkdownEditor.scss';

class AesMarkdownEditor extends React.Component {
  // Prevent updates after initial value is set, so that backing data changes don't override typing
  shouldComponentUpdate() {
    const { initialValue } = this.props;
    return !initialValue;
  }

  render() {
    const { additionalButtons, initialValue, onChange } = this.props;
    return (
      <MarkdownInput
        additionalButtons={additionalButtons}
        value={initialValue}
        onChange={onChange}
        spellCheck
      />
    );
  }
}

AesMarkdownEditor.propTypes = {
  additionalButtons: PropTypes.array,
  initialValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

AesMarkdownEditor.defaultProps = {
  additionalButtons: [],
  initialValue: '',
};

export default AesMarkdownEditor;
