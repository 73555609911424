import { Map } from 'immutable';
import { AUTHENTICATION_FAILURE, AUTHENTICATION_SUCCESS } from './actions';

const initialState = Map({
  apiKey: '',
  authenticated: false,
  email: '',
});

const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case (AUTHENTICATION_SUCCESS):
      return state.remove('errorMessage')
        .set('authenticated', true)
        .set('apiKey', action.result.get('auth_token'))
        .set('email', action.result.getIn(['user', 'email']));
    case (AUTHENTICATION_FAILURE):
      return state.remove('apiKey')
        .set('authenticated', false)
        .set('errorMessage', action.message);
    default:
      return state;
  }
};

export default authenticationReducer;
