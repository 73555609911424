export const AUTHENTICATION_REQUEST = 'AUTHENTICATION_REQUEST';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE';

export const authenticate = (email, password, googleToken) => ({
  type: AUTHENTICATION_REQUEST, email, password, googleToken,
});

export const succeeded = result => ({ type: AUTHENTICATION_SUCCESS, result });

export const failed = message => ({ type: AUTHENTICATION_FAILURE, message });
