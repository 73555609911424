import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { dismissModal } from 'aes-lesson-driver/src/redux/View/actions';
import { QUESTION_TYPES } from 'aes-lesson-driver/src/constants';
import Modal from 'aes-lesson-driver/src/containers/Modal';
import { createQuestion } from '../redux/Edits/actions';
import '../components/Picker.scss';
import { getPageQuestions, getQuestionPages } from '../redux/Edits/selectors';
import './CreatorModals.scss';


class QuestionCreator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentType: QUESTION_TYPES[0].key,
      currentNumber: props.lastQuestionNumber + 1,
    };
  }

  onChangeType(event) {
    const { value } = event.target;
    this.setState({ currentType: value });
  }

  onChangeNumber(event) {
    const { value } = event.target;
    this.setState({ currentNumber: parseInt(value, 10) });
  }

  onClickAdd(event) {
    const { currentType, currentNumber } = this.state;
    const { dispatch } = this.props;
    event.preventDefault();
    dispatch.createQuestion(currentNumber, currentType);
    dispatch.dismiss();
  }

  renderNumberOptions() {
    const { lastQuestionNumber, questions } = this.props;
    const options = [];

    let firstQuestionNumber;

    if (questions.isEmpty()) {
      firstQuestionNumber = lastQuestionNumber + 1;
    } else {
      const firstQuestion = questions.get(0);
      firstQuestionNumber = parseFloat(firstQuestion.get('number'));
    }

    for (let i = 0; i <= questions.size; i += 1) {
      const number = firstQuestionNumber + i;
      options.push(<option key={`question_${number}`} value={number}>{number}</option>);
    }
    return options;
  }

  render() {
    const { currentType, currentNumber } = this.state;
    return (
      <Modal>
        <section className="page-creator">
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label htmlFor="type_select">
            Type
            <select
              id="type_select"
              name="type_select"
              value={currentType}
              onChange={e => this.onChangeType(e)}
            >
              {QUESTION_TYPES.map(type => (
                <option key={type.key} value={type.key}>
                  {type.title}
                </option>
              ))}
            </select>
          </label>
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label htmlFor="number_select">
            Question Number
            <select
              id="number_select"
              name="number_select"
              value={currentNumber}
              onChange={e => this.onChangeNumber(e)}
            >
              {this.renderNumberOptions()}
            </select>
          </label>
          <div className="actions">
            <button type="button" onClick={e => this.onClickAdd(e)}>
              Add Question
            </button>
          </div>
        </section>
      </Modal>
    );
  }
}

QuestionCreator.propTypes = {
  dispatch: PropTypes.object.isRequired,
  questions: PropTypes.instanceOf(List).isRequired,
  lastQuestionNumber: PropTypes.number.isRequired,
  // False flag
  /* eslint-disable react/no-unused-prop-types */
  pageSetKey: PropTypes.string.isRequired,
  pageNumber: PropTypes.number.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

const mapStateToProps = (state, { pageSetKey, pageNumber }) => {
  const questions = getPageQuestions(state, pageSetKey, pageNumber);
  let lastQuestionNumber = 0;

  if (!questions.isEmpty()) {
    const lastQuestion = questions.last();
    lastQuestionNumber = parseFloat(lastQuestion.get('number'));
  } else {
    const questionPages = getQuestionPages(state, pageSetKey);
    const previousQuestionPage = questionPages.findLast(page => (
      parseFloat(page.get('page_number')) < pageNumber
    ));
    if (previousQuestionPage) {
      const lastQuestion = previousQuestionPage.get('questions').last();
      lastQuestionNumber = parseFloat(lastQuestion.get('number'));
    }
  }

  return {
    lastQuestionNumber,
    questions,
  };
};

const mapDispatchToProps = (dispatch, { pageSetKey, pageNumber }) => ({
  dispatch: {
    dismiss: () => dispatch(dismissModal()),
    createQuestion: (questionNumber, questionType) => dispatch(
      createQuestion(pageSetKey, pageNumber, questionNumber, questionType),
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionCreator);
