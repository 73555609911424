import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getMediaBaseUrl } from 'aes-lesson-driver/src/redux/Data/selectors';
import { getActiveModal, getActiveSlide } from 'aes-lesson-driver/src/redux/View/selectors';
import {
  activateDuplicator,
  activateFilePicker,
  activateFormLinkCreator,
  activateGlossaryWordPicker,
  activatePageCreator,
  activateQuestionCreator,
  activateSlideCreator,
  activateTitleEditor,
  setActiveSlide,
} from 'aes-lesson-driver/src/redux/View/actions';
import { LAYOUT_DRAG_DROP, LAYOUTS } from 'aes-lesson-driver/src/components/PageContent';
import { DRAGDROP_TYPE_MATCH_TEXT, DRAGDROP_TYPES } from 'aes-lesson-driver/src/constants';
import LoadingIndicator from 'aes-lesson-driver/src/components/LoadingIndicator';
import NavBar from 'aes-lesson-driver/src/components/NavBar';
import Page from 'aes-lesson-driver/src/components/Page';
import { getPageSet, getPageSetHasChanges } from '../redux/Edits/selectors';
import {
  changeDragDrop,
  changePageAltText,
  changePageLayout,
  changePageMarkdown,
  changeQuestion,
  deletePage,
  deleteQuestion,
  loadPageSet,
  moveQuestion,
  savePageSet,
} from '../redux/Edits/actions';
import AesMarkdownEditor from '../components/AesMarkdownEditor';
import QuestionEditor from '../components/QuestionEditor';
import './PageSetEditor.scss';
import DragDropEditor from '../components/DragDropEditor';
import ModalFactory from './ModalFactory';
import SlidesEditor from './SlidesEditor';

export class PageSetEditor extends React.Component {
  constructor(props) {
    super(props);
    const { currentPage } = props;
    const hash = currentPage ? currentPage.hashCode() : '';
    this.state = { hash };
  }

  componentDidMount() {
    const {
      dispatch,
      pageSetLoaded,
    } = this.props;

    if (!pageSetLoaded) {
      dispatch.loadPageSet();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      activeSlide,
      dispatch,
      pageNumber,
      pageSetLoaded,
    } = this.props;
    const { pageNumber: prevPageNumber, pageSetLoaded: prevLoaded } = prevProps;
    if (activeSlide > 0 && pageNumber !== prevPageNumber) {
      dispatch.resetActiveSlide();
    }
    if (pageSetLoaded !== prevLoaded) {
      this.rehash();
    }
  }

  onChangeAltText(event) {
    const { dispatch } = this.props;
    const { target: { value } } = event;
    dispatch.changePageAltText(value);
  }

  onChangeDragDrop(dragdrop) {
    const { dispatch } = this.props;
    dispatch.changeDragDrop(dragdrop);
  }

  onChangeDragDropType(event) {
    const { currentPage } = this.props;
    const { target: { value } } = event;
    const dragdrop = currentPage.get('dragdrop') || Map();
    this.onChangeDragDrop(dragdrop.set('type', value));
  }

  onChangeLayout(event) {
    const { dispatch } = this.props;
    const { target: { value } } = event;
    dispatch.changePageLayout(value);
  }

  onChangeMarkdown(markdown) {
    const { dispatch } = this.props;
    dispatch.changePageMarkdown(markdown);
  }

  onChangeQuestion(index, question) {
    const { dispatch } = this.props;
    dispatch.changeQuestion(index, question);
  }

  onMoveQuestion(index, delta) {
    const { dispatch } = this.props;
    dispatch.moveQuestion(index, delta);
    this.rehash();
  }

  onDuplicateClick() {
    const { dispatch } = this.props;
    dispatch.activateDuplicator();
  }

  onEditTitleClick() {
    const { dispatch } = this.props;
    dispatch.activateTitleEditor();
  }

  onSaveClick() {
    const { dispatch, pageSet } = this.props;
    dispatch.savePageSet(pageSet);
  }

  onFileSelectorClick(fieldPath) {
    const { dispatch } = this.props;
    dispatch.activateFilePicker(fieldPath);
  }

  onPageCreatorClick() {
    const { dispatch } = this.props;
    dispatch.activatePageCreator();
  }

  onPageDeleteClick() {
    const { dispatch } = this.props;
    dispatch.deletePage();
  }

  onQuestionCreatorClick() {
    const { dispatch } = this.props;
    dispatch.activateQuestionCreator();
  }

  onQuestionDeleteClick(questionNumber) {
    const { dispatch } = this.props;
    dispatch.deleteQuestion(questionNumber);
  }

  onGlossaryWordClick({ insertAtCursorPosition }) {
    const { dispatch } = this.props;
    const callback = (wordData) => {
      const key = wordData.get('key');
      const word = wordData.get('word');
      insertAtCursorPosition(`[${word}](GW:${key})`);
    };
    dispatch.activateGlossaryWordPicker(callback);
  }

  onPrintFormClick({ insertAtCursorPosition }) {
    const { dispatch } = this.props;
    const callback = ({ linkText, fileName }) => {
      insertAtCursorPosition(`[${linkText}](${encodeURI(fileName)})`);
    };
    dispatch.activateFormLinkCreator(callback);
  }

  onSlideClick({ insertAtCursorPosition }) {
    const { dispatch } = this.props;
    const callback = (slideNumber) => {
      insertAtCursorPosition(`[View](${slideNumber})`);
    };
    dispatch.activateSlideCreator(callback);
  }

  rehash() {
    const { currentPage } = this.props;
    this.setState({ hash: currentPage.hashCode() });
  }

  renderDragDropTypeSelect() {
    const { currentPage } = this.props;
    const currentType = currentPage.getIn(['dragdrop', 'type']);

    return (
      <label htmlFor="dragdrop_type_select">
        Type
        <select
          id="dragdrop_type_select"
          name="dragdrop_type_select"
          value={currentType}
          onChange={e => this.onChangeDragDropType(e)}
        >
          {DRAGDROP_TYPES.map(type => (
            <option key={type.key} value={type.key}>
              {type.title}
            </option>
          ))}
        </select>
      </label>
    );
  }

  renderLayoutSelect() {
    const { currentPage } = this.props;
    const currentLayout = currentPage.get('layout');

    return (
      <label htmlFor="layout_select">
        Layout
        <select
          id="layout_select"
          name="layout_select"
          value={currentLayout}
          onChange={e => this.onChangeLayout(e)}
        >
          {LAYOUTS.map(layout => (
            <option key={layout.key} value={layout.key}>
              {layout.title}
            </option>
          ))}
        </select>
      </label>
    );
  }

  renderEditors() {
    const { hash } = this.state;
    const {
      currentPage,
      mediaBaseUrl,
      pageSet,
      pageSetKey,
      pageNumber,
    } = this.props;
    const lastEdit = pageSet.get('last_edited') || Date.now() / 1000;
    const layout = currentPage.get('layout');
    const markdown = currentPage.get('markdown');
    const questions = currentPage.get('questions') || List();
    const dragdrop = currentPage.get('dragdrop') || Map({
      type: DRAGDROP_TYPE_MATCH_TEXT.key,
      terms: List(),
    });
    const slides = currentPage.get('slides');
    const editorButtons = [
      {
        label: 'Glossary Word',
        handleButtonPress: props => this.onGlossaryWordClick(props),
      },
      {
        label: 'Printed Form',
        handleButtonPress: props => this.onPrintFormClick(props),
      },
    ];

    if (layout.includes('Page')) {
      editorButtons.push(
        {
          label: 'Slide',
          handleButtonPress: props => this.onSlideClick(props),
        },
      );
    }

    const fillHeight = !(slides || layout.includes('Question') || layout === LAYOUT_DRAG_DROP.key);

    return (
      <div className={`editors ${fillHeight ? 'fill' : ''}`}>
        <div className="markdown">
          <AesMarkdownEditor
            key={`${currentPage.get('page_key')}_markdown`}
            additionalButtons={editorButtons}
            initialValue={markdown}
            onChange={value => this.onChangeMarkdown(value)}
          />
        </div>
        { layout.includes('Question')
          ? (
            <div className="questions">
              {questions.map((question, index) => (
                // NB: Key must be unique when moving questions, hence `size`, `hash` component
                <QuestionEditor
                  key={`question_${question.get('number')}_${questions.size}_${hash}`}
                  question={question}
                  isFirst={index === 0}
                  isLast={index === questions.size - 1}
                  onChange={data => this.onChangeQuestion(index, data)}
                  onMoveUpClick={() => this.onMoveQuestion(index, -1)}
                  onMoveDownClick={() => this.onMoveQuestion(index, 1)}
                  onDeleteClick={questionNumber => this.onQuestionDeleteClick(questionNumber)}
                />
              ))}
            </div>
          )
          : null
        }
        { layout === LAYOUT_DRAG_DROP.key
          ? (
            <DragDropEditor
              dragdrop={dragdrop}
              lastEdit={lastEdit}
              mediaBaseUrl={mediaBaseUrl}
              onChange={data => this.onChangeDragDrop(data)}
            />
          )
          : null
        }
        { slides
          ? (
            <SlidesEditor
              slides={slides}
              pageSetKey={pageSetKey}
              pageNumber={pageNumber}
            />
          )
          : null
        }
      </div>
    );
  }

  renderContent() {
    const {
      activeModal,
      activeSlide,
      currentPage,
      hasChanges,
      mediaBaseUrl,
      moduleKey,
      pages,
      pageSetKey,
      pageSet,
      pageNumber,
    } = this.props;

    const exitLink = `/${moduleKey}/${pageSetKey}/${pageNumber}`;
    const prevLink = pageNumber > 1
      ? `/${moduleKey}/${pageSetKey}/${pageNumber - 1}/edit`
      : '';
    const nextLink = pageNumber < pages.size
      ? `/${moduleKey}/${pageSetKey}/${pageNumber + 1}/edit`
      : '';

    const altText = currentPage.get('alt_text');
    const layout = currentPage.get('layout');

    return (
      <Fragment>
        <div className={`page-editor page-layout-${currentPage.get('layout').toLowerCase()}`}>
          <NavBar
            pageNumber={pageNumber}
            pageCount={pages.size}
            exitLink={exitLink}
            nextLink={nextLink}
            prevLink={prevLink}
          >
            <button
              type="button"
              className="navbar-link save"
              onClick={() => this.onSaveClick()}
              disabled={!hasChanges}
            >
              {hasChanges
                ? 'Save Page Set'
                : 'No Pending Changes'
              }
            </button>
          </NavBar>
          <div className="layout-7525">
            {this.renderEditors()}
            <div className="actions">
              <div className="top-actions">
                <button type="button" onClick={() => this.onEditTitleClick()}>
                  Change Unit/Lesson Title
                </button>
                <button type="button" onClick={() => this.onDuplicateClick()}>
                  Duplicate Lesson
                </button>
              </div>
              {this.renderLayoutSelect()}
              { layout === LAYOUT_DRAG_DROP.key
                ? this.renderDragDropTypeSelect()
                : null
              }
              <label htmlFor="alt_text">
                Image Alt Text
                <input
                  key={`alt_text_${pageNumber}`}
                  id="alt_text"
                  name="alt_text"
                  defaultValue={altText}
                  onChange={e => this.onChangeAltText(e)}
                />
              </label>
              <button
                type="button"
                onClick={() => {
                  const fieldPath = layout === LAYOUT_DRAG_DROP.key ? ['dragdrop', 'image'] : 'image';
                  this.onFileSelectorClick(fieldPath);
                }}
              >
                Change Image
              </button>
              <button type="button" onClick={() => this.onFileSelectorClick('audio')}>
                Change Audio
              </button>
              <button type="button" onClick={() => this.onFileSelectorClick('video')}>
                Change Video
              </button>
              <button type="button" className="add" onClick={() => this.onPageCreatorClick()}>
                Add New Page
              </button>
              { layout.includes('Question')
                ? (
                  <button type="button" className="add" onClick={() => this.onQuestionCreatorClick()}>
                    Add New Question
                  </button>
                )
                : null
              }
              <button type="button" className="delete" onClick={() => this.onPageDeleteClick()}>
                Delete Page
              </button>
            </div>
          </div>
        </div>
        <div className="preview">
          <Page
            editMode
            activeSlide={activeSlide}
            pageSet={pageSet}
            pageNumber={pageNumber}
            mediaBaseUrl={mediaBaseUrl}
            showHeader
          />
        </div>
        {
          activeModal
            ? (
              <ModalFactory
                editMode
                data={activeModal}
                pageNumber={pageNumber}
                pageSetKey={pageSetKey}
              />
            )
            : null
        }
      </Fragment>
    );
  }

  render() {
    const {
      moduleKey,
      pageNumber,
      pageSetKey,
      pageSetLoaded,
      currentPage,
    } = this.props;
    if (pageSetLoaded) {
      return (
        currentPage
          ? this.renderContent()
          : <Redirect to={`/${moduleKey}/${pageSetKey}/${pageNumber - 1}/edit`} />
      );
    }
    return <LoadingIndicator />;
  }
}

PageSetEditor.propTypes = {
  activeModal: PropTypes.instanceOf(Map),
  activeSlide: PropTypes.number,
  currentPage: PropTypes.instanceOf(Map),
  dispatch: PropTypes.object.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  mediaBaseUrl: PropTypes.string.isRequired,
  moduleKey: PropTypes.string.isRequired,
  pages: PropTypes.instanceOf(List),
  pageSet: PropTypes.instanceOf(Map).isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSetKey: PropTypes.string.isRequired,
  pageSetLoaded: PropTypes.bool.isRequired,
};

PageSetEditor.defaultProps = {
  activeModal: null,
  activeSlide: 0,
  currentPage: null,
  pages: null,
};

const mapStateToProps = (state, props) => {
  const {
    match:
      {
        params:
          {
            moduleKey,
            pageSetKey,
            pageNumber,
          },
      },
  } = props;
  const activeModal = getActiveModal(state);
  const activeSlide = getActiveSlide(state);
  const hasChanges = getPageSetHasChanges(state, pageSetKey);
  const mediaBaseUrl = getMediaBaseUrl(state, pageSetKey);
  const pageNumberInt = parseInt(pageNumber, 10) || 1;
  const pageSet = getPageSet(state, pageSetKey);
  const pageSetLoaded = !pageSet.isEmpty();
  const pages = pageSetLoaded ? pageSet.get('pages') : null;
  const currentPage = pageSetLoaded ? pages.get(pageNumberInt - 1) : null;

  return {
    activeModal,
    activeSlide,
    currentPage,
    hasChanges,
    mediaBaseUrl,
    moduleKey,
    pages,
    pageSet,
    pageSetLoaded,
    pageSetKey,
    pageNumber: pageNumberInt,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const {
    match:
      {
        params:
          {
            moduleKey,
            pageSetKey,
            pageNumber,
          },
      },
  } = props;
  return ({
    dispatch: {
      activateDuplicator: () => dispatch(activateDuplicator()),
      activateFilePicker: fieldName => dispatch(activateFilePicker(fieldName)),
      activateFormLinkCreator: callback => dispatch(activateFormLinkCreator(callback)),
      activateGlossaryWordPicker: callback => dispatch(activateGlossaryWordPicker(callback)),
      activatePageCreator: () => dispatch(activatePageCreator()),
      activateQuestionCreator: () => dispatch(activateQuestionCreator()),
      activateSlideCreator: callback => dispatch(activateSlideCreator(callback)),
      activateTitleEditor: () => dispatch(activateTitleEditor()),
      loadPageSet: () => dispatch(loadPageSet(moduleKey, pageSetKey)),
      savePageSet: pageSet => dispatch(savePageSet(pageSet)),
      changePageAltText: (text) => {
        dispatch(changePageAltText(pageSetKey, pageNumber, text));
      },
      changePageLayout: (layout) => {
        dispatch(changePageLayout(pageSetKey, pageNumber, layout));
      },
      changePageMarkdown: (markdown) => {
        dispatch(changePageMarkdown(pageSetKey, pageNumber, markdown));
      },
      changeDragDrop: (dragdrop) => {
        dispatch(changeDragDrop(pageSetKey, pageNumber, dragdrop));
      },
      changeQuestion: (questionIndex, question) => {
        dispatch(changeQuestion(pageSetKey, pageNumber, questionIndex, question));
      },
      moveQuestion: (questionIndex, delta) => {
        dispatch(moveQuestion(pageSetKey, pageNumber, questionIndex, delta));
      },
      deletePage: () => dispatch(deletePage(pageSetKey, pageNumber)),
      deleteQuestion: (questionNumber) => {
        dispatch(deleteQuestion(pageSetKey, pageNumber, questionNumber));
      },
      resetActiveSlide: () => dispatch(setActiveSlide(0)),
    },
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(PageSetEditor);
