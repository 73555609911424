export const EDITS_CHANGE_PAGE_ALT_TEXT = 'EDITS_CHANGE_PAGE_ALT_TEXT';

export const EDITS_CHANGE_PAGE_MARKDOWN_REQUEST = 'EDITS_CHANGE_PAGE_MARKDOWN_REQUEST';
export const EDITS_CHANGE_PAGE_MARKDOWN_COMPLETE = 'EDITS_CHANGE_PAGE_MARKDOWN_COMPLETE';

export const EDITS_CHANGE_PAGE_FILE_FIELD = 'EDITS_CHANGE_PAGE_FILE_FIELD';
export const EDITS_CHANGE_PAGE_LAYOUT = 'EDITS_CHANGE_PAGE_LAYOUT';

export const EDITS_CHANGE_DRAG_DROP_REQUEST = 'EDITS_CHANGE_DRAG_DROP_REQUEST';
export const EDITS_CHANGE_DRAG_DROP_COMPLETE = 'EDITS_CHANGE_DRAG_DROP_COMPLETE';

export const EDITS_CHANGE_QUESTION_REQUEST = 'EDITS_CHANGE_QUESTION_REQUEST';
export const EDITS_CHANGE_QUESTION_COMPLETE = 'EDITS_CHANGE_QUESTION_COMPLETE';

export const EDITS_CHANGE_SLIDE_CAPTION = 'EDITS_CHANGE_SLIDE_CAPTION';

export const EDITS_CHANGE_TITLES = 'EDITS_CHANGE_TITLES';

export const EDITS_CREATE_PAGE = 'EDITS_CREATE_PAGE';

export const EDITS_CREATE_GLOSSARY_WORD = 'EDITS_CREATE_GLOSSARY_WORD';

export const EDITS_CREATE_QUESTION_REQUEST = 'EDITS_CREATE_QUESTION_REQUEST';
export const EDITS_CREATE_QUESTION_COMPLETE = 'EDITS_CREATE_QUESTION_COMPLETE';

export const EDITS_CREATE_SLIDE = 'EDITS_CREATE_SLIDE';

export const EDITS_DELETE_PAGE = 'EDITS_DELETE_PAGE';

export const EDITS_DELETE_QUESTION_REQUEST = 'EDITS_DELETE_QUESTION_REQUEST';
export const EDITS_DELETE_QUESTION_COMPLETE = 'EDITS_DELETE_QUESTION_COMPLETE';

export const EDITS_DELETE_SLIDE = 'EDITS_DELETE_SLIDE';

export const EDITS_DECREMENT_QUESTION_NUMBER = 'EDITS_DECREMENT_QUESTION_NUMBER';
export const EDITS_INCREMENT_QUESTION_NUMBER = 'EDITS_INCREMENT_QUESTION_NUMBER';

export const EDITS_MOVE_QUESTION = 'EDITS_MOVE_QUESTION';

export const EDITS_LOAD_PAGE_SET_REQUEST = 'EDITS_LOAD_PAGE_SET_REQUEST';
export const EDITS_LOAD_PAGE_SET_SUCCESS = 'EDITS_LOAD_PAGE_SET_SUCCESS';
export const EDITS_LOAD_PAGE_SET_FAILURE = 'EDITS_LOAD_PAGE_SET_FAILURE';

export const EDITS_SAVE_PAGE_SET_REQUEST = 'EDITS_SAVE_PAGE_SET_REQUEST';
export const EDITS_SAVE_PAGE_SET_SUCCESS = 'EDITS_SAVE_PAGE_SET_SUCCESS';
export const EDITS_SAVE_PAGE_SET_FAILURE = 'EDITS_SAVE_PAGE_SET_FAILURE';

export const EDITS_UPDATE_GLOSSARY_WORD = 'EDITS_UPDATE_GLOSSARY_WORD';
export const EDITS_UPLOAD_GLOSSARY_WORD_FILE_REQUEST = 'EDITS_UPLOAD_GLOSSARY_WORD_FILE_REQUEST';

export const EDITS_UPLOAD_PAGE_SET_FILE_REQUEST = 'EDITS_UPLOAD_PAGE_SET_FILE_REQUEST';
export const EDITS_UPLOAD_PAGE_SET_FILE_SUCCESS = 'EDITS_UPLOAD_PAGE_SET_FILE_SUCCESS';
export const EDITS_UPLOAD_PAGE_SET_FILE_FAILURE = 'EDITS_UPLOAD_PAGE_SET_FILE_FAILURE';

export const EDITS_DUPLICATE_PAGE_SET_REQUEST = 'EDITS_DUPLICATE_PAGE_SET_REQUEST';
export const EDITS_DUPLICATE_PAGE_SET_FAILURE = 'EDITS_DUPLICATE_PAGE_SET_FAILURE';

export const loadPageSet = (moduleKey, pageSetKey) => ({
  type: EDITS_LOAD_PAGE_SET_REQUEST,
  moduleKey,
  pageSetKey,
});

export const loadPageSetSucceeded = (pageSetKey, result) => ({
  type: EDITS_LOAD_PAGE_SET_SUCCESS,
  pageSetKey,
  result,
});

export const loadPageSetFailed = message => ({ type: EDITS_LOAD_PAGE_SET_FAILURE, message });

export const changePageAltText = (pageSetKey, pageNumber, text) => ({
  type: EDITS_CHANGE_PAGE_ALT_TEXT,
  pageSetKey,
  pageNumber,
  text,
});

export const changePageMarkdown = (pageSetKey, pageNumber, markdown) => ({
  type: EDITS_CHANGE_PAGE_MARKDOWN_REQUEST,
  pageSetKey,
  pageNumber,
  markdown,
});

export const changePageMarkdownComplete = (pageSetKey, pageNumber, markdown) => ({
  type: EDITS_CHANGE_PAGE_MARKDOWN_COMPLETE,
  pageSetKey,
  pageNumber,
  markdown,
});

export const changePageFileField = (pageSetKey, pageNumber, fieldPath, fileName) => ({
  type: EDITS_CHANGE_PAGE_FILE_FIELD,
  pageSetKey,
  pageNumber,
  fieldPath,
  fileName,
});

export const changePageLayout = (pageSetKey, pageNumber, layout) => ({
  type: EDITS_CHANGE_PAGE_LAYOUT,
  pageSetKey,
  pageNumber,
  layout,
});

export const savePageSet = pageSet => ({
  type: EDITS_SAVE_PAGE_SET_REQUEST,
  moduleKey: pageSet.get('module_key'),
  pageSetKey: pageSet.get('page_set_key'),
  pageSet,
});

export const savePageSetFailed = message => ({ type: EDITS_SAVE_PAGE_SET_FAILURE, message });

export const savePageSetSucceded = (pageSetKey, result) => ({
  type: EDITS_SAVE_PAGE_SET_SUCCESS,
  pageSetKey,
  result,
});

export const uploadPageSetFile = (pageSetKey, pageNumber, fieldName, file) => ({
  type: EDITS_UPLOAD_PAGE_SET_FILE_REQUEST,
  pageSetKey,
  pageNumber,
  fieldName,
  file,
});

export const uploadPageSetFileFailed = message => ({
  type: EDITS_UPLOAD_PAGE_SET_FILE_FAILURE,
  message,
});

export const uploadPageSetFileSucceeded = (pageSetKey, fileName) => ({
  type: EDITS_UPLOAD_PAGE_SET_FILE_SUCCESS,
  pageSetKey,
  fileName,
});

export const createPage = (pageSetKey, pageNumber, layout) => ({
  type: EDITS_CREATE_PAGE,
  pageSetKey,
  pageNumber,
  layout,
});

export const duplicatePageSet = (
  moduleKey,
  pageSetKey,
  newModuleKey,
  newModuleTitle,
  newUnitKey,
  newUnitTitle,
  newPageSetKey,
  newPageSetTitle,
) => ({
  type: EDITS_DUPLICATE_PAGE_SET_REQUEST,
  moduleKey,
  pageSetKey,
  newModuleKey,
  newModuleTitle,
  newUnitKey,
  newUnitTitle,
  newPageSetKey,
  newPageSetTitle,
});

export const duplicatePageSetFailed = (pageSetKey, error) => ({
  type: EDITS_DUPLICATE_PAGE_SET_FAILURE,
  pageSetKey,
  error,
});

export const deletePage = (pageSetKey, pageNumber) => ({
  type: EDITS_DELETE_PAGE,
  pageSetKey,
  pageNumber,
});

export const createGlossaryWord = (pageSetKey, glossaryWord) => ({
  type: EDITS_CREATE_GLOSSARY_WORD,
  pageSetKey,
  glossaryWord,
});

export const updateGlossaryWord = (pageSetKey, glossaryWordIndex, glossaryWord) => ({
  type: EDITS_UPDATE_GLOSSARY_WORD,
  pageSetKey,
  glossaryWordIndex,
  glossaryWord,
});

export const uploadGlossaryWordFile = (pageSetKey, file) => ({
  type: EDITS_UPLOAD_GLOSSARY_WORD_FILE_REQUEST,
  pageSetKey,
  file,
});

export const incrementQuestionNumber = (pageSetKey, pageNumber, questionIndex) => ({
  type: EDITS_INCREMENT_QUESTION_NUMBER,
  pageSetKey,
  pageNumber,
  questionIndex,
});

export const decrementQuestionNumber = (pageSetKey, pageNumber, questionIndex, count = 1) => ({
  type: EDITS_DECREMENT_QUESTION_NUMBER,
  pageSetKey,
  pageNumber,
  questionIndex,
  count,
});

export const moveQuestion = (pageSetKey, pageNumber, questionIndex, delta) => ({
  type: EDITS_MOVE_QUESTION,
  pageSetKey,
  pageNumber,
  questionIndex,
  delta,
});


export const createQuestion = (pageSetKey, pageNumber, questionNumber, questionType) => ({
  type: EDITS_CREATE_QUESTION_REQUEST,
  pageSetKey,
  pageNumber,
  questionNumber,
  questionType,
});

export const createQuestionComplete = (pageSetKey, pageNumber, questionIndex, question) => ({
  type: EDITS_CREATE_QUESTION_COMPLETE,
  pageSetKey,
  pageNumber,
  questionIndex,
  question,
});

export const changeQuestion = (pageSetKey, pageNumber, questionIndex, question) => ({
  type: EDITS_CHANGE_QUESTION_REQUEST,
  pageSetKey,
  pageNumber,
  questionIndex,
  question,
});

export const changeQuestionComplete = (pageSetKey, pageNumber, questionIndex, question) => ({
  type: EDITS_CHANGE_QUESTION_COMPLETE,
  pageSetKey,
  pageNumber,
  questionIndex,
  question,
});

export const deleteQuestion = (pageSetKey, pageNumber, questionNumber) => ({
  type: EDITS_DELETE_QUESTION_REQUEST,
  pageSetKey,
  pageNumber,
  questionNumber,
});

export const deleteQuestionComplete = (pageSetKey, pageNumber, questionIndex) => ({
  type: EDITS_DELETE_QUESTION_COMPLETE,
  pageSetKey,
  pageNumber,
  questionIndex,
});

export const changeDragDrop = (pageSetKey, pageNumber, dragdrop) => ({
  type: EDITS_CHANGE_DRAG_DROP_REQUEST,
  pageSetKey,
  pageNumber,
  dragdrop,
});

export const changeDragDropComplete = (pageSetKey, pageNumber, dragdrop) => ({
  type: EDITS_CHANGE_DRAG_DROP_COMPLETE,
  pageSetKey,
  pageNumber,
  dragdrop,
});

export const createSlide = (pageSetKey, pageNumber, slideNumber, audio, image, caption) => ({
  type: EDITS_CREATE_SLIDE,
  pageSetKey,
  pageNumber,
  slideNumber,
  audio,
  image,
  caption,
});

export const deleteSlide = (pageSetKey, pageNumber, slideIndex) => ({
  type: EDITS_DELETE_SLIDE,
  pageSetKey,
  pageNumber,
  slideIndex,
});

export const changeSlideCaption = (pageSetKey, pageNumber, slideIndex, caption) => ({
  type: EDITS_CHANGE_SLIDE_CAPTION,
  pageSetKey,
  pageNumber,
  slideIndex,
  caption,
});

export const changeTitles = (pageSetKey, pageSetTitle, unitTitle) => ({
  type: EDITS_CHANGE_TITLES,
  pageSetKey,
  pageSetTitle,
  unitTitle,
});
