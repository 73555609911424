import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';
import { OrderedMap } from 'immutable';
import {
  getModules,
  getOutlinePageSets,
} from 'aes-lesson-driver/src/redux/Data/selectors';

import {
  DATA_LIST_MODULES_REQUEST,
  DATA_LIST_PAGE_SETS_REQUEST,
  DATA_LIST_PAGE_SET_FILES_REQUEST,
  DATA_LOAD_PAGE_SET_REQUEST,
  listModulesSucceeded,
  listPageSetsSucceeded,
  listPageSetsFailed,
  listPageSetFilesFailed,
  listPageSetFilesSucceeded,
  loadPageSetSucceeded,
  loadPageSetFailed,
  loadModuleOutlineSucceeded,
  loadModuleOutlineFailed,
} from 'aes-lesson-driver/src/redux/Data/actions';

import {
  getApiKey,
} from '../Authentication/selectors';

import {
  getModuleUnitOutline,
  listModules,
  listPageSets,
  listPageSetFiles,
  loadPageSet,
} from '../../services/API';

export function* doLoadModuleUnitOutline() {
  try {
    const modules = yield select(getModules);
    const result = yield call(getModuleUnitOutline);
    if (!result.isEmpty()) {
      // flatten outline to pageSets ordered by unit
      const moduleOutline = result.reduce((moduleMap, module, key) => (
        modules.has(key)
          ? moduleMap.set(key, module.get('units').reduce((pageSetMap, unit) => (
            unit.get('lessons').reduce((acc, pageSet) => (acc.set(
              pageSet.get('page_set_key'),
              pageSet.set('unit_number', unit.get('number')),
            )), pageSetMap)
          ), OrderedMap()))
          : moduleMap
      ), OrderedMap());

      yield put(loadModuleOutlineSucceeded(moduleOutline));
    }
  } catch (error) {
    yield put(loadModuleOutlineFailed(error));
  }
}

export function* doListModules() {
  const modules = yield call(listModules);
  yield put(listModulesSucceeded(modules));
  yield doLoadModuleUnitOutline();
}

export function* doListPageSets({ moduleKey }) {
  try {
    const apiKey = yield select(getApiKey);
    const result = yield call(listPageSets, moduleKey, apiKey);
    const error = result.get('error');
    if (error) {
      yield put(listPageSetsFailed(error));
    } else {
      const outlinePageSets = yield select(getOutlinePageSets, moduleKey);
      const pageSets = result.reduce((pageSetMap, modulePageSet) => (
        pageSetMap.mergeIn([modulePageSet.getIn(['page_set', 'page_set_key'])], modulePageSet)
      ), outlinePageSets)
        .toList()
        .filter(mPs => mPs.has('page_set'));
      yield put(listPageSetsSucceeded(moduleKey, pageSets.toList()));
    }
  } catch (error) {
    yield put(listPageSetsFailed('Error contacting API'));
  }
}

export function* doLoadPageSet({ moduleKey, pageSetKey }) {
  try {
    const apiKey = yield select(getApiKey);
    const result = yield call(loadPageSet, moduleKey, pageSetKey, apiKey);
    const error = result.get('error');
    if (error) {
      yield put(loadPageSetFailed(error));
    } else {
      yield put(loadPageSetSucceeded(pageSetKey, result));
    }
  } catch (error) {
    yield put(loadPageSetFailed('Error contacting API'));
  }
}

const cleanFilePath = path => (
  path.startsWith('/')
    ? path.slice(1)
    : path
);

export function* doListPageSetFiles({ moduleKey, pageSetKey }) {
  try {
    const apiKey = yield select(getApiKey);
    const result = yield call(listPageSetFiles, moduleKey, pageSetKey, apiKey);
    const error = result.get('error');
    if (error) {
      yield put(listPageSetFilesFailed(error));
    } else {
      yield put(listPageSetFilesSucceeded(pageSetKey, result.map(cleanFilePath)));
    }
  } catch (error) {
    yield put(listPageSetFilesFailed('Error contacting API'));
  }
}

export function* watchListModules() {
  yield takeLatest(DATA_LIST_MODULES_REQUEST, doListModules);
}

export function* watchListPageSets() {
  yield takeLatest(DATA_LIST_PAGE_SETS_REQUEST, doListPageSets);
}

export function* watchListPageSetFiles() {
  yield takeLatest(DATA_LIST_PAGE_SET_FILES_REQUEST, doListPageSetFiles);
}

export function* watchLoadPageSet() {
  yield takeLatest(DATA_LOAD_PAGE_SET_REQUEST, doLoadPageSet);
}

export default function* rootSaga() {
  yield all([
    watchListModules(),
    watchListPageSets(),
    watchListPageSetFiles(),
    watchLoadPageSet(),
  ]);
}
