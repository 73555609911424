import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import GlossaryWord from 'aes-lesson-driver/src/containers/GlossaryWord';
import ImageModal from 'aes-lesson-driver/src/components/ImageModal';
import FilePicker from './FilePicker';
import PageCreator from './PageCreator';
import GlossaryWordPicker from './GlossaryWordPicker';
import GlossaryWordEditor from './GlossaryWordEditor';
import QuestionCreator from './QuestionCreator';
import FormLinkCreator from './FormLinkCreator';
import SlideCreator from './SlideCreator';
import TitleEditor from './TitleEditor';
import PageSetDuplicator from './PageSetDuplicator';

const ModalFactory = ({
  data,
  editMode,
  pageSetKey,
  pageNumber,
}) => {
  const callback = data.get('callback');
  const type = data.get('type');
  const value = data.get('value');

  switch (type) {
    case 'IMAGE_MODAL':
      return <ImageModal src={value.src} alt={value.alt} />;
    case 'GLOSSARY_WORD':
      return editMode
        ? <GlossaryWordEditor glossaryWordKey={value} pageSetKey={pageSetKey} callback={callback} />
        : <GlossaryWord glossaryWordKey={value} pageSetKey={pageSetKey} />;
    case 'GLOSSARY_WORD_PICKER':
      return editMode
        ? <GlossaryWordPicker callback={callback} pageSetKey={pageSetKey} />
        : null;
    case 'FILE_PICKER':
      return editMode
        ? <FilePicker pageNumber={pageNumber} pageSetKey={pageSetKey} fieldPath={value} />
        : null;
    case 'FORM_LINK_CREATOR':
      return editMode
        ? <FormLinkCreator callback={callback} pageSetKey={pageSetKey} />
        : null;
    case 'PAGE_CREATOR':
      return editMode
        ? <PageCreator pageSetKey={pageSetKey} pageNumber={pageNumber} />
        : null;
    case 'QUESTION_CREATOR':
      return editMode
        ? <QuestionCreator pageSetKey={pageSetKey} pageNumber={pageNumber} />
        : null;
    case 'SLIDE_CREATOR':
      return editMode
        ? <SlideCreator pageSetKey={pageSetKey} pageNumber={pageNumber} callback={callback} />
        : null;
    case 'TITLE_EDITOR':
      return editMode
        ? <TitleEditor pageSetKey={pageSetKey} />
        : null;
    case 'PAGESET_DUPLICATOR':
      return <PageSetDuplicator pageSetKey={pageSetKey} />;
    default:
      return null;
  }
};

ModalFactory.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  editMode: PropTypes.bool,
  pageSetKey: PropTypes.string.isRequired,
  pageNumber: PropTypes.number.isRequired,
};

ModalFactory.defaultProps = {
  editMode: false,
};

export default ModalFactory;
