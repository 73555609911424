import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { activateGlossaryWord, activateImageModal, setActiveSlide } from '../redux/View/actions';

const protocols = ['http', 'https', 'mailto', 'tel', 'gw', 'link', 'slide'];

const onlyNumbers = str => /^\d+$/.test(str);

const getUriParts = (uri) => {
  const parts = uri.split(':');
  if (parts.length > 1) {
    const protocol = protocols.find(validProtocol => parts[0].toLowerCase() === validProtocol);
    if (protocol) {
      return { protocol, path: parts[1] };
    }
  }
  return { path: uri };
};

// Adapted from https://github.com/rexxars/react-markdown/blob/master/src/uri-transformer.js
export const uriTransformer = (uri, mediaBaseUrl) => {
  const url = (uri || '').trim();
  const first = url.charAt(0);

  if (onlyNumbers(url)) {
    return url;
  }

  if (first === '#' || first === '/') {
    return url;
  }

  const colon = url.indexOf(':');
  // If there is no set protocol, derive from other info
  if (colon === -1) {
    // If we see the pattern `_00`, assume linked PageSet
    const linkDigits = url.indexOf('_00');
    if (linkDigits > 0) {
      return `link:${url}`;
    }
    return `${mediaBaseUrl}/${url}`;
  }

  const parts = getUriParts(url);

  if (parts.protocol) {
    return url;
  }

  let index = url.indexOf('?');
  if (index !== -1 && colon > index) {
    return url;
  }

  index = url.indexOf('#');
  if (index !== -1 && colon > index) {
    return url;
  }

  return '#';
};

const AesLink = ({
  href, children, dispatch, moduleKey, pageNumber,
}) => {
  const parts = getUriParts(href);

  // if url is only numbers, assume it's a slide button.
  if (parts.protocol === 'slide' || onlyNumbers(href)) {
    const index = parseInt(href, 10) - 1;
    return (
      <button
        className="slide-number"
        type="button"
        onClick={() => dispatch.setActiveSlide(index)}
      >
        replay
      </button>
    );
  }

  if (parts.protocol === 'gw') {
    return (
      <button
        className="glossary-word"
        type="button"
        onClick={() => dispatch.activateGlossaryWord(parts.path)}
      >
        {children}
      </button>
    );
  }

  if (parts.protocol === 'link') {
    if (moduleKey) {
      return (<Link to={`/${moduleKey}/${parts.path}`}>{children}</Link>);
    }
    return (<Link to={`/page/${pageNumber}/link/${parts.path}`}>{children}</Link>);
  }

  const extension = parts.path.slice(-3).toLowerCase();

  switch (extension) {
    case 'pdf':
      return (
        <button
          className="print-form"
          type="button"
          onClick={() => window.open(parts.path, 'aes_form')}
        >
          {children}
          <i className="fas fa-print" />
        </button>
      );

    case 'gif':
    case 'jpg':
    case 'png':
      return (
        <button
          className="show-image"
          type="button"
          onClick={() => dispatch.activateImageModal(href, children[0].props.value)}
        >
          {children}
          <i className="fas fa-image" />
        </button>
      );

    default:
      return <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>;
  }
};

AesLink.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.object.isRequired,
  href: PropTypes.string.isRequired,
  moduleKey: PropTypes.string,
  pageNumber: PropTypes.string,
};

AesLink.defaultProps = {
  children: null,
  moduleKey: null,
  pageNumber: null,
};

const mapStateToProps = (state, { match: { params: { moduleKey, pageNumber } } }) => ({
  moduleKey,
  pageNumber,
});

const mapDispatchToProps = dispatch => ({
  dispatch: {
    activateGlossaryWord: key => dispatch(activateGlossaryWord(key)),
    activateImageModal: (src, alt) => dispatch(activateImageModal(src, alt)),
    setActiveSlide: index => dispatch(setActiveSlide(index)),
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AesLink));
