export const VIEW_MODAL_ACTIVATE = 'VIEW_MODAL_ACTIVATE';
export const VIEW_MODAL_DISMISS = 'VIEW_MODAL_DISMISS';
export const VIEW_CHECK_ANSWERS = 'VIEW_CHECK_ANSWERS';
export const VIEW_SET_ACTIVE_SLIDE = 'VIEW_SET_ACTIVE_SLIDE';
export const VIEW_SET_ANSWER = 'VIEW_SET_ANSWER';
export const VIEW_SET_ATTEMPTS = 'VIEW_SET_ATTEMPTS';
export const VIEW_SET_AUTOPLAY = 'VIEW_SET_AUTOPLAY';
export const VIEW_SET_INITIAL_LOAD = 'VIEW_SET_INITIAL_LOAD';
export const VIEW_UNSET_ANSWER = 'VIEW_UNSET_ANSWER';
export const VIEW_SCORM_COPY_DATA = 'VIEW_SCORM_COPY_DATA';
export const VIEW_SCORM_LOAD_ANSWERS = 'VIEW_SCORM_LOAD_ANSWERS';
export const VIEW_SCORM_LOAD_PAGE = 'VIEW_SCORM_LOAD_PAGE';
export const VIEW_SCORM_SAVE_DATA = 'VIEW_SCORM_SAVE_DATA';
export const VIEW_SCORM_SET_COMPLETE = 'VIEW_SCORM_SET_COMPLETE';
export const VIEW_SCORM_EXIT = 'VIEW_SCORM_EXIT';

export const activateModal = (type, value, callback) => ({
  type: VIEW_MODAL_ACTIVATE,
  data: {
    type,
    value,
    callback,
  },
});

export const dismissModal = () => ({ type: VIEW_MODAL_DISMISS });

export const activateAnswerPrompt = () => activateModal('ANSWER_PROMPT');

export const activateDuplicator = () => activateModal('PAGESET_DUPLICATOR');

export const activateGlossaryWord = key => activateModal('GLOSSARY_WORD', key);

export const activateGlossaryWordCreator = callback => activateModal('GLOSSARY_WORD', 'NEW_WORD', callback);

export const activateGlossaryWordPicker = callback => (
  activateModal('GLOSSARY_WORD_PICKER', null, callback)
);

export const activateFilePicker = (fieldPath) => {
  const path = typeof fieldPath === 'string'
    ? [fieldPath]
    : fieldPath;
  return activateModal('FILE_PICKER', path);
};

export const activateFormLinkCreator = callback => (
  activateModal('FORM_LINK_CREATOR', null, callback)
);

export const activateImageModal = (src, alt) => activateModal('IMAGE_MODAL', { src, alt });

export const activatePageCreator = () => activateModal('PAGE_CREATOR');

export const activateQuestionCreator = () => activateModal('QUESTION_CREATOR');

export const activateSlideCreator = callback => (
  activateModal('SLIDE_CREATOR', null, callback)
);

export const activateTitleEditor = () => activateModal('TITLE_EDITOR');

export const checkAnswers = (pageNumber, answerPath) => ({
  type: VIEW_CHECK_ANSWERS,
  pageNumber,
  answerPath,
});

export const clearInitialLoad = () => ({
  type: VIEW_SET_INITIAL_LOAD,
  value: false,
});

export const setActiveSlide = index => ({
  type: VIEW_SET_ACTIVE_SLIDE,
  index,
});

export const setAnswer = (pageNumber, answerPath, answer) => ({
  type: VIEW_SET_ANSWER,
  pageNumber,
  answerPath,
  answer,
});

export const setAutoplay = value => ({
  type: VIEW_SET_AUTOPLAY,
  value,
});

export const unsetAnswer = (pageNumber, answerPath) => ({
  type: VIEW_UNSET_ANSWER,
  pageNumber,
  answerPath,
});

export const setAttempts = (pageNumber, answerPath, attempts) => ({
  type: VIEW_SET_ATTEMPTS,
  attempts,
  answerPath,
  pageNumber,
});

export const loadAnswers = answers => ({
  type: VIEW_SCORM_LOAD_ANSWERS,
  answers,
});

export const loadPageNumber = pageNumber => ({
  type: VIEW_SCORM_LOAD_PAGE,
  pageNumber,
});

export const copyScormData = () => ({ type: VIEW_SCORM_COPY_DATA });

export const saveScormData = pageNumber => ({
  type: VIEW_SCORM_SAVE_DATA,
  pageNumber,
});

export const setComplete = () => ({ type: VIEW_SCORM_SET_COMPLETE });

export const exit = () => ({ type: VIEW_SCORM_EXIT });
