import { all } from 'redux-saga/effects';
import view from 'aes-lesson-driver/src/redux/View/sagas';
import authentication from './Authentication/sagas';
import data from './Data/sagas';
import edits from './Edits/sagas';


export default function* rootSaga() {
  yield all([
    authentication(),
    data(),
    edits(),
    view(),
  ]);
}
