import React, { Component } from 'react';
import { connect } from 'react-redux';
import Proptypes from 'prop-types';
import { authenticate } from '../redux/Authentication/actions';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
  }

  signIn(e) {
    const { dispatchAuthenticate } = this.props;
    const { email, password } = this.state;

    e.preventDefault();
    dispatchAuthenticate(email, password, null);
  }

  render() {
    return (
      <div className="sign-in">
        <h1>Please Sign In</h1>
        <p>Use your staging server credentials.</p>
        <form>
          <label htmlFor="email">
            Email:
            <input type="email" name="email" id="email" onChange={e => this.setState({ email: e.target.value })} />
          </label>
          <label htmlFor="password">
            Password:
            <input type="password" name="password" id="password" onChange={e => this.setState({ password: e.target.value })} />
          </label>
          <button type="submit" onClick={e => this.signIn(e)}>Sign In</button>
        </form>
      </div>
    );
  }
}

SignIn.propTypes = {
  dispatchAuthenticate: Proptypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  dispatchAuthenticate: (email, password, googleToken) => (
    dispatch(authenticate(email, password, googleToken))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
