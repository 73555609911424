import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { dismissModal } from 'aes-lesson-driver/src/redux/View/actions';
import { LAYOUTS } from 'aes-lesson-driver/src/components/PageContent';
import Modal from 'aes-lesson-driver/src/containers/Modal';
import { createPage } from '../redux/Edits/actions';
import '../components/Picker.scss';
import { getPageSet } from '../redux/Edits/selectors';
import './CreatorModals.scss';

class PageCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLayout: LAYOUTS[0].key,
      currentPage: props.pageNumber + 1,
    };
  }

  onChangeLayout(event) {
    const { value } = event.target;
    this.setState({ currentLayout: value });
  }

  onChangePage(event) {
    const { value } = event.target;
    this.setState({ currentPage: parseInt(value, 10) });
  }

  onClickAdd(event) {
    const { currentLayout, currentPage } = this.state;
    const {
      dispatch, history, moduleKey, pageNumber, pageSetKey,
    } = this.props;
    event.preventDefault();
    dispatch.createPage(pageSetKey, currentPage, currentLayout);
    dispatch.dismiss();
    if (pageNumber === currentPage) {
      history.push(`/${moduleKey}/${pageSetKey}/${currentPage + 1}/edit`);
      setTimeout(() => history.push(`/${moduleKey}/${pageSetKey}/${currentPage}/edit`), 100);
    } else {
      setTimeout(() => history.push(`/${moduleKey}/${pageSetKey}/${currentPage}/edit`), 100);
    }
  }

  renderPageOptions() {
    const { pageCount } = this.props;
    const options = [];
    for (let i = 1; i <= pageCount + 1; i += 1) {
      options.push(<option key={`page_${i}`} value={i}>{i}</option>);
    }
    return options;
  }

  render() {
    const { currentLayout, currentPage } = this.state;
    return (
      <Modal>
        <section className="page-creator">
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label htmlFor="layout_select">
            Layout
            <select
              id="layout_select"
              name="layout_select"
              value={currentLayout}
              onChange={e => this.onChangeLayout(e)}
            >
              {LAYOUTS.map(layout => (
                <option key={layout.key} value={layout.key}>
                  {layout.title}
                </option>
              ))}
            </select>
          </label>
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label htmlFor="page_select">
            Page Number
            <select
              id="page_select"
              name="page_select"
              value={currentPage}
              onChange={e => this.onChangePage(e)}
            >
              {this.renderPageOptions()}
            </select>
          </label>
          <div className="actions">
            <button type="button" onClick={e => this.onClickAdd(e)}>
              Add Page
            </button>
          </div>
        </section>
      </Modal>
    );
  }
}

PageCreator.propTypes = {
  dispatch: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  moduleKey: PropTypes.string.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageSetKey: PropTypes.string.isRequired,
  pageNumber: PropTypes.number.isRequired,
};

const mapStateToProps = (state, props) => {
  const { pageSetKey, pageNumber } = props;
  const pageSet = getPageSet(state, pageSetKey);
  const pages = pageSet.get('pages');
  const moduleKey = pageSet.get('module_key');
  return {
    moduleKey,
    pageCount: pages ? pages.size : 0,
    pageSetKey,
    pageNumber,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch: {
    dismiss: () => dispatch(dismissModal()),
    createPage: (pageSetKey, pageNumber, layout) => dispatch(
      createPage(pageSetKey, pageNumber, layout),
    ),
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(PageCreator);
