import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import DragDropTerm from 'aes-lesson-driver/src/containers/DragDropTerm';
import DragDropContainer from 'aes-lesson-driver/src/containers/DragDropContainer';

class LabelDragDropEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTerm: -1,
      image: { loaded: false },
    };
  }

  onChangeTerm(event, index) {
    const { target: { value } } = event;
    const { data, onChange } = this.props;

    onChange(data.setIn(['terms', index, 'term'], value));
  }

  onDrop(target) {
    const { image: { naturalWidth } } = this.state;
    const { offsetX, offsetY, containerRect } = target;
    const ratio = naturalWidth / containerRect.width;
    this.setTargetPosition(offsetX, offsetY, ratio);
  }

  onFigureClick(event) {
    const {
      nativeEvent: { offsetX, offsetY },
      target: { clientWidth, naturalWidth },
    } = event;
    const ratio = naturalWidth / clientWidth;
    this.setTargetPosition(offsetX, offsetY, ratio);
  }

  onFocusTerm(index) {
    this.setState({ activeTerm: index });
  }

  onImageLoad({ target: { naturalWidth, naturalHeight } }) {
    this.setState({ image: { loaded: true, naturalWidth, naturalHeight } });
  }

  setTargetPosition(offsetX, offsetY, ratio = 1) {
    const { activeTerm } = this.state;
    const { data, onChange } = this.props;
    const left = offsetX * ratio;
    const top = offsetY * ratio;
    onChange(data.mergeIn(['terms', activeTerm], { left, top }));
  }

  addTerm() {
    const { data, onChange } = this.props;
    const terms = data.get('terms') || List();
    onChange(data.set('terms', terms.push(Map({ term: '', left: 0, top: 0 }))));
  }


  renderDropTargets(terms) {
    const {
      activeTerm,
      image: {
        loaded,
        naturalWidth,
        naturalHeight,
      },
    } = this.state;
    if (loaded) {
      return (
        terms.map(
          (data, index) => {
            const term = data.get('term');
            const leftPx = parseFloat(data.get('left') || 0);
            const topPx = parseFloat(data.get('top') || 0);
            const leftPct = leftPx / naturalWidth * 100;
            const topPct = topPx / naturalHeight * 100;
            const style = { left: `${leftPct}%`, top: `${topPct}%` };
            if (index === activeTerm) {
              return (
                <DragDropTerm
                  key={`${term}_target_editor`}
                  index={index}
                  term={term}
                  isAnswered
                  onDrop={(initiator, target) => this.onDrop(target)}
                  style={style}
                />
              );
            }
            return null;
          },
        )
      );
    }
    return null;
  }

  renderTermList(terms) {
    const { baseClass, onDeleteTerm } = this.props;
    return (
      <section className="terms">
        <ul>
          {terms.map(
            (data, index) => {
              const key = `${baseClass}-term-${index}-${terms.size}`;
              const term = data.get('term');
              return (
                <li key={key}>
                  <input
                    type="text"
                    defaultValue={term}
                    onChange={e => this.onChangeTerm(e, index)}
                    onFocus={() => this.onFocusTerm(index)}
                  />
                  <button type="button" className="delete" onClick={() => onDeleteTerm(index)}>
                    <i className="fas fa-trash-alt" />
                  </button>
                </li>
              );
            },
          )
          }
          <li>
            <button type="button" onClick={() => this.addTerm()}>
              Add Term
            </button>
          </li>
        </ul>
      </section>
    );
  }

  render() {
    const { data, lastEdit, mediaBaseUrl } = this.props;
    const terms = data.get('terms') || List();
    const imgSrc = data.get('image');
    return (
      <div className="layout-7525">
        <section className="figure">
          <DragDropContainer id="figure">
            {/* TODO: I can't find an a11y alternative paradigm for labeling parts of an image */}
            {/* eslint-disable-next-line */}
            <div className="figure-wrapper" onClick={e => this.onFigureClick(e)}>
              { imgSrc
                ? (
                  <img
                    src={`${mediaBaseUrl}/${imgSrc}?t=${lastEdit}`}
                    alt="figure"
                    onLoad={e => this.onImageLoad(e)}
                  />
                )
                : null
              }
              { this.renderDropTargets(terms) }
            </div>
          </DragDropContainer>
        </section>
        { this.renderTermList(terms) }
      </div>
    );
  }
}

LabelDragDropEditor.propTypes = {
  baseClass: PropTypes.string,
  data: PropTypes.instanceOf(Map).isRequired,
  lastEdit: PropTypes.number.isRequired,
  mediaBaseUrl: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDeleteTerm: PropTypes.func.isRequired,
};

LabelDragDropEditor.defaultProps = {
  baseClass: 'label-editor',
};

export default LabelDragDropEditor;
