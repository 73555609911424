import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

const OpenEntryField = ({
  answer, questionData, onSetAnswer,
}) => {
  const number = Math.floor(questionData.get('number'));
  const inputId = `question_${number}_input`;
  const onChangeFunction = ({ target: { value } }) => onSetAnswer(value);

  return (
    <label htmlFor={`${inputId}`}>
      <span className="sr-only">answer</span>
      <textarea id={inputId} defaultValue={answer} rows={5} onChange={onChangeFunction} />
    </label>
  );
};

OpenEntryField.propTypes = {
  answer: PropTypes.string,
  onSetAnswer: PropTypes.func.isRequired,
  questionData: PropTypes.instanceOf(Map).isRequired,
};

OpenEntryField.defaultProps = {
  answer: '',
};

export default OpenEntryField;
