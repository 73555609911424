import React from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from 'aes-lesson-driver/src/components/LoadingIndicator';

const DownloadModal = ({
  downloading, downloadError, downloadLink, onClickDismiss,
}) => {
  if (downloading || downloadError || downloadLink) {
    return (
      <div aria-labelledby="modal_title" aria-modal className="modal download">
        <div className="modal-body">
          <h3 id="modal_title">Publishing Lesson</h3>
          { downloading
            ? <LoadingIndicator />
            : null }
          { downloadLink
            ? (
              <div className="published">
                <h5>Download Published:</h5>
                <a href={downloadLink} target="icev-download">{downloadLink}</a>
              </div>
            )
            : null }
          { downloadError
            ? (
              <div className="error">
                <h5>Error Publishing:</h5>
                <pre>{downloadError}</pre>
              </div>
            )
            : null }
          <button
            type="button"
            aria-label="close"
            className="modal-close"
            onClick={onClickDismiss}
          >
            ✕
          </button>
        </div>
      </div>
    );
  }
  return null;
};

DownloadModal.propTypes = {
  downloading: PropTypes.bool.isRequired,
  downloadError: PropTypes.string.isRequired,
  downloadLink: PropTypes.string.isRequired,
  onClickDismiss: PropTypes.func.isRequired,
};

export default DownloadModal;
