import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import AesMarkdown from './AesMarkdown';
import MultiChoiceFields from './MultiChoiceFields';
import {
  QUESTION_TYPE_FILL_IN_BLANK,
  QUESTION_TYPE_MULTIPLE_CHOICE,
  QUESTION_TYPE_OPEN_ENTRY,
  QUESTION_TYPE_TRUE_FALSE,
} from '../constants';
import BooleanFields from './BooleanFields';
import OpenEntryField from './OpenEntryField';
import FillInBlankField from './FillInBlankField';


class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showCheckButton: false };
  }

  componentDidUpdate(prevProps) {
    const { answer } = this.props;
    if (answer !== prevProps.answer) {
      this.showCheckButton();
    }
  }

  onCheckButtonClick() {
    const { onCheckAnswer } = this.props;
    this.hideCheckButton();
    onCheckAnswer();
  }

  showCheckButton() {
    this.setState({ showCheckButton: true });
  }

  hideCheckButton() {
    this.setState({ showCheckButton: false });
  }

  renderAnswerStatus() {
    const { answer, answerIsCorrect, questionData } = this.props;
    const { showCheckButton } = this.state;
    const isOpenEntry = questionData.get('type') === QUESTION_TYPE_OPEN_ENTRY.key;

    if (answer !== null && showCheckButton) {
      return (
        <button type="button" className="check-answer" onClick={() => this.onCheckButtonClick()}>
          { isOpenEntry ? 'Set Answer' : 'Check Answer' }
        </button>
      );
    }
    if (answerIsCorrect) {
      return (
        <div className="message success">
          <span>{ isOpenEntry ? 'answered' : 'correct' }</span>
          &nbsp;
          <i aria-hidden="true" className="fas fa-check-square" />
        </div>
      );
    }
    return null;
  }

  renderFields() {
    const {
      answer,
      attempts,
      onSetAnswer,
      questionData,
    } = this.props;
    const type = questionData.get('type');
    switch (type) {
      case QUESTION_TYPE_TRUE_FALSE.key:
        return (
          <BooleanFields
            questionData={questionData}
            onSetAnswer={onSetAnswer}
            answer={answer}
            attempts={attempts}
          />
        );
      case QUESTION_TYPE_MULTIPLE_CHOICE.key:
        return (
          <MultiChoiceFields
            questionData={questionData}
            onSetAnswer={onSetAnswer}
            answer={answer}
            attempts={attempts}
          />
        );
      case QUESTION_TYPE_OPEN_ENTRY.key:
        return (
          <OpenEntryField answer={answer} onSetAnswer={onSetAnswer} questionData={questionData} />
        );
      case QUESTION_TYPE_FILL_IN_BLANK.key:
        return (
          <FillInBlankField
            answer={answer}
            attempts={attempts}
            onSetAnswer={onSetAnswer}
            questionData={questionData}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const {
      hideStatus,
      mediaBaseUrl,
      questionData,
    } = this.props;
    const text = questionData.get('text').replace(/_/gi, '\\_');
    const number = Math.floor(questionData.get('number'));

    return (
      <section className="question">
        <h2>
          Question #
          {number}
        </h2>
        <AesMarkdown mediaBaseUrl={mediaBaseUrl} source={text} />
        <fieldset>
          {this.renderFields()}
        </fieldset>
        { hideStatus
          ? null
          : this.renderAnswerStatus()
        }
      </section>
    );
  }
}

Question.propTypes = {
  answer: PropTypes.string,
  answerIsCorrect: PropTypes.bool,
  attempts: PropTypes.instanceOf(List),
  hideStatus: PropTypes.bool,
  mediaBaseUrl: PropTypes.string.isRequired,
  onCheckAnswer: PropTypes.func.isRequired,
  questionData: PropTypes.instanceOf(Map).isRequired,
  onSetAnswer: PropTypes.func.isRequired,
};

Question.defaultProps = {
  answer: null,
  answerIsCorrect: false,
  attempts: List(),
  hideStatus: false,
};

export default Question;
