import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { dismissModal } from 'aes-lesson-driver/src/redux/View/actions';
import { getPageSetFiles } from 'aes-lesson-driver/src/redux/Data/selectors';
import { listPageSetFiles } from 'aes-lesson-driver/src/redux/Data/actions';
import Modal from 'aes-lesson-driver/src/containers/Modal';
import { createSlide, uploadPageSetFile } from '../redux/Edits/actions';
import Picker from '../components/Picker';
import { getPageSet } from '../redux/Edits/selectors';
import { createOptionsByType } from './FilePicker';
import './SlideCreator.scss';

class SlideCreator extends React.Component {
  constructor(props) {
    super(props);
    const { slideCount } = this.props;
    this.state = {
      caption: '',
      slideNumber: slideCount + 1,
      selectedFiles: Map(),
    };
  }

  componentDidMount() {
    const { dispatch, files } = this.props;
    if (files.isEmpty()) {
      dispatch.listPageSetFiles();
    }
  }

  onChangeCaption(event) {
    const caption = event.target.value;
    this.setState({ caption });
  }

  onChangeSlideNumber(event) {
    const slideNumber = event.target.value;

    event.preventDefault();
    this.setState({ slideNumber });
  }

  onClickSave(event) {
    const { caption, selectedFiles, slideNumber } = this.state;
    const { callback, dispatch } = this.props;
    const audio = selectedFiles.get('audio');
    const image = selectedFiles.get('image');

    event.preventDefault();
    dispatch.createSlide(slideNumber, audio, image, caption);
    dispatch.dismiss();

    callback(slideNumber);
  }

  onClickFile(event, type) {
    const { target: { value } } = event;
    const { selectedFiles } = this.state;
    this.setState({ selectedFiles: selectedFiles.set(type, value) });
  }

  onSelectFile(event, type) {
    const { dispatch } = this.props;
    const { selectedFiles } = this.state;
    const input = event.target;

    event.preventDefault();

    if (input.files.length > 0) {
      const file = input.files[0];
      this.setState({ selectedFiles: selectedFiles.set(type, file.name) });
      dispatch.uploadFile(input.files[0]);
      input.value = '';
    }
  }

  canSave() {
    const { selectedFiles } = this.state;
    return !!selectedFiles.get('audio')
      && !!selectedFiles.get('image');
  }

  renderSlideOptions() {
    const { slideCount } = this.props;
    const options = [];
    for (let i = 1; i <= slideCount + 1; i += 1) {
      options.push(<option key={`slide_${i}`} value={i}>{i}</option>);
    }
    return options;
  }

  render() {
    const { caption, slideNumber, selectedFiles } = this.state;
    const { files } = this.props;
    const audioFileName = selectedFiles.get('audio');
    const audioOptions = createOptionsByType(files, 'audio');
    const imageFileName = selectedFiles.get('image');
    const imageOptions = createOptionsByType(files, 'image');

    return (
      <Modal>
        <section className="slide-creator">
          <label htmlFor="slide_select">
            Slide Number
            <select
              id="slide_select"
              name="slide_select"
              value={slideNumber}
              onChange={e => this.onChangeSlideNumber(e)}
            >
              {this.renderSlideOptions()}
            </select>
          </label>
          <label htmlFor="caption">
            Alt Text
            <input
              id="caption"
              name="caption"
              value={caption}
              onChange={e => this.onChangeCaption(e)}
            />
          </label>
          <div className="file-selections">
            <div>
              <h4>Audio:</h4>
              {audioFileName
                ? <div className="selected-audio">{audioFileName}</div>
                : (
                  <Picker options={audioOptions} onOptionClick={e => this.onClickFile(e, 'audio')}>
                    <label htmlFor="audio_file" className="button">
                      Upload new audio
                      <input
                        type="file"
                        accept="audio/*"
                        id="audio_file"
                        name="file"
                        onChange={e => this.onSelectFile(e, 'audio')}
                      />
                    </label>
                  </Picker>
                )
              }
            </div>
            <div>
              <h4>Image:</h4>
              {imageFileName
                ? <div className="selected-image">{imageFileName}</div>
                : (
                  <Picker options={imageOptions} onOptionClick={e => this.onClickFile(e, 'image')}>
                    <label htmlFor="image_file" className="button">
                      Upload new image
                      <input
                        type="file"
                        accept="image/*"
                        id="image_file"
                        name="file"
                        onChange={e => this.onSelectFile(e, 'image')}
                      />
                    </label>
                  </Picker>
                )
              }
            </div>
          </div>
          <div className="actions">
            <button
              type="button"
              className="save"
              disabled={!this.canSave()}
              onClick={e => this.onClickSave(e)}
            >
              Add Slide
            </button>
          </div>
        </section>
      </Modal>
    );
  }
}

SlideCreator.propTypes = {
  callback: PropTypes.func.isRequired,
  dispatch: PropTypes.object.isRequired,
  files: PropTypes.instanceOf(List).isRequired,
  slideCount: PropTypes.number.isRequired,
  // false flag
  /* eslint-disable react/no-unused-prop-types */
  moduleKey: PropTypes.string.isRequired,
  pageSetKey: PropTypes.string.isRequired,
  pageNumber: PropTypes.number.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

const mapStateToProps = (state, { pageSetKey, pageNumber }) => {
  const files = getPageSetFiles(state, pageSetKey);
  const pageSet = getPageSet(state, pageSetKey);
  const moduleKey = pageSet.get('module_key');
  const slides = pageSet.getIn(['pages', pageNumber - 1, 'slides']) || List();
  const slideCount = slides.count();

  return {
    files,
    moduleKey,
    slideCount,
  };
};

const mapDispatchToProps = (dispatch, { moduleKey, pageSetKey, pageNumber }) => ({
  dispatch: {
    listPageSetFiles: () => dispatch(listPageSetFiles(moduleKey, pageSetKey)),
    uploadFile: file => dispatch(uploadPageSetFile(pageSetKey, null, null, file)),
    createSlide: (slideNumber, audio, image, caption) => (
      dispatch(createSlide(pageSetKey, pageNumber, slideNumber, audio, image, caption))
    ),
    dismiss: () => dispatch(dismissModal()),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SlideCreator);
