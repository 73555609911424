import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import PageContent from './PageContent';
import AudioPlayer from './AudioPlayer';
import NavBar from './NavBar';
import './Page.scss';

/* eslint-disable react/prop-types */
const AudioPlayerWrapper = ({
  pageData,
  activeSlide,
  autoPlay,
  lastEdit,
  mediaBaseUrl,
  setActiveSlide,
  setAutoPlay,
}) => {
  const audio = pageData.get('audio');
  const pageNumber = pageData.get('page_number');
  const slides = pageData.get('slides');

  if (slides) {
    const slideAudio = slides.getIn([activeSlide, 'audio']);
    const onEnded = activeSlide < slides.size - 1
      ? () => setActiveSlide(activeSlide + 1)
      : () => {};
    return (
      <AudioPlayer
        key={`slide_audio_${pageNumber}_${activeSlide}`}
        audio={slideAudio}
        lastEdit={lastEdit}
        mediaBaseUrl={mediaBaseUrl}
        autoPlay={autoPlay}
        onEnded={onEnded}
        setAutoPlay={setAutoPlay}
      />
    );
  }

  if (audio) {
    return (
      <AudioPlayer
        key={`audio_${pageNumber}_auto_${autoPlay}`}
        audio={audio}
        lastEdit={lastEdit}
        mediaBaseUrl={mediaBaseUrl}
        autoPlay={autoPlay}
        setAutoPlay={setAutoPlay}
      />
    );
  }

  return null;
};

const Page = ({
  activeSlide,
  allQuestionsAreCorrect,
  assessmentMode,
  autoPlay,
  baseUrl,
  checkAnswers,
  editMode,
  editLink,
  exitLink,
  extraHeaders,
  extraContent,
  showHeader,
  mediaBaseUrl,
  pageSet,
  pageNumber,
  setActiveSlide,
  setAutoPlay,
  showAnswerPrompt,
}) => {
  const unitTitle = pageSet.get('unit_title');
  const pageSetTitle = pageSet.get('page_set_title');
  const lastEdit = parseInt(pageSet.get('last_edited') || Date.now(), 10);
  const pages = pageSet.get('pages');
  const currentPage = pages.get(pageNumber - 1);

  const prevLink = !editMode && pageNumber > 1
    ? `${baseUrl}/${pageNumber - 1}`
    : '';
  const nextLink = !editMode && pageNumber < pages.size
    ? `${baseUrl}/${pageNumber + 1}`
    : '';

  return (
    <div className="curriculum-builder-page">
      { showHeader
        ? (
          <header>
            <h1>{unitTitle}</h1>
            <h2>{pageSetTitle}</h2>
          </header>
        )
        : null
      }

      <NavBar
        exitLink={exitLink}
        prevLink={prevLink}
        nextLink={nextLink}
        allQuestionsAreCorrect={allQuestionsAreCorrect}
        checkAnswers={checkAnswers}
        showAnswerPrompt={showAnswerPrompt}
        pageNumber={pageNumber}
        pageCount={pages.size}
      >
        { editLink
          ? <Link to={editLink} className="navbar-link edit">Edit</Link>
          : null
        }
        <AudioPlayerWrapper
          pageData={currentPage}
          activeSlide={activeSlide}
          autoPlay={autoPlay}
          lastEdit={lastEdit}
          mediaBaseUrl={mediaBaseUrl}
          setActiveSlide={setActiveSlide}
          setAutoPlay={setAutoPlay}
        />
        { setAutoPlay
          ? (
            <label htmlFor="toggle_autoplay">
              <input
                type="checkbox"
                id="toggle_autoplay"
                checked={autoPlay}
                onChange={({ target: { checked } }) => setAutoPlay(checked)}
              />
              <span>Autoplay</span>
            </label>
          )
          : null }
        { extraHeaders }
      </NavBar>
      <PageContent
        pageData={currentPage}
        pageNumber={pageNumber}
        mediaBaseUrl={mediaBaseUrl}
        activeSlide={activeSlide}
        assessmentMode={assessmentMode}
      >
        {extraContent}
      </PageContent>
    </div>
  );
};

Page.propTypes = {
  activeSlide: PropTypes.number,
  allQuestionsAreCorrect: PropTypes.bool,
  assessmentMode: PropTypes.bool,
  autoPlay: PropTypes.bool,
  baseUrl: PropTypes.string,
  checkAnswers: PropTypes.func,
  editMode: PropTypes.bool,
  editLink: PropTypes.string,
  exitLink: PropTypes.string,
  extraHeaders: PropTypes.node,
  extraContent: PropTypes.node,
  showHeader: PropTypes.bool,
  mediaBaseUrl: PropTypes.string.isRequired,
  pageSet: PropTypes.instanceOf(Map).isRequired,
  pageNumber: PropTypes.number.isRequired,
  setActiveSlide: PropTypes.func,
  setAutoPlay: PropTypes.func,
  showAnswerPrompt: PropTypes.func,
};

Page.defaultProps = {
  activeSlide: 0,
  allQuestionsAreCorrect: true,
  assessmentMode: false,
  autoPlay: false,
  baseUrl: '',
  checkAnswers: () => {},
  editMode: false,
  editLink: null,
  exitLink: null,
  extraHeaders: null,
  extraContent: null,
  showHeader: true,
  setActiveSlide: () => {},
  setAutoPlay: null,
  showAnswerPrompt: () => {},
};

export default Page;
