import React from 'react';
import PropTypes from 'prop-types';
import { List, Map, OrderedMap } from 'immutable';
import { decode } from 'he';
import { shuffleMap } from '../util';

const getChoices = (questionData) => {
  const choices = questionData.filter((value, key) => (
    key.startsWith('choice_') && typeof value === 'string'
  ));
  const randomize = questionData.get('randomize') === 'Yes';
  const sequence = questionData.get('sequence');

  if (sequence) {
    return sequence.reduce((orderedChoices, answerKey) => {
      const choiceKey = `choice_${answerKey.toLowerCase()}`;
      const choice = choices.get(choiceKey);
      if (choice) {
        return orderedChoices.set(choiceKey, choice);
      }
      return orderedChoices;
    }, OrderedMap());
  }
  if (randomize) {
    return shuffleMap(choices);
  }
  return choices;
};

class MultiChoiceFields extends React.Component {
  constructor(props) {
    super(props);
    const { questionData } = props;
    const choices = getChoices(questionData);
    this.state = ({ choices });
  }

  onChange(event) {
    const { onSetAnswer } = this.props;
    const { target: { value } } = event;
    onSetAnswer(value);
  }

  render() {
    const { answer, attempts, questionData } = this.props;
    const { choices } = this.state;
    const number = Math.floor(questionData.get('number'));
    const correctAnswer = questionData.get('answer');

    return choices.map((title, key) => {
      const value = key.replace('choice_', '').toUpperCase();
      const name = `question_${number}`;
      const fullKey = `${name}_${key}`;
      const checked = value === answer;
      const disabled = value !== correctAnswer && attempts.contains(value);
      return (
        <label key={fullKey} htmlFor={fullKey}>
          <input
            id={fullKey}
            name={name}
            type="radio"
            value={value}
            checked={checked}
            disabled={disabled}
            onChange={e => this.onChange(e)}
          />
          {disabled
            ? <span><del>{decode(title)}</del></span>
            : <span>{decode(title)}</span>
          }
        </label>
      );
    }).toList();
  }
}


MultiChoiceFields.propTypes = {
  answer: PropTypes.string,
  attempts: PropTypes.instanceOf(List),
  questionData: PropTypes.instanceOf(Map).isRequired,
  onSetAnswer: PropTypes.func.isRequired,
};

MultiChoiceFields.defaultProps = {
  answer: null,
  attempts: List(),
};


export default MultiChoiceFields;
