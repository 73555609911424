import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { activateGlossaryWordCreator, dismissModal } from 'aes-lesson-driver/src/redux/View/actions';
import Modal from 'aes-lesson-driver/src/containers/Modal';
import Picker from '../components/Picker';
import { getGlossaryWords } from '../redux/Edits/selectors';

const createOptions = wordList => (
  wordList.map(word => (
    word.mapKeys((key) => {
      switch (key) {
        case ('word'):
          return 'name';
        case ('key'):
          return 'value';
        default:
          return key;
      }
    })
  )));

class GlossaryWordPicker extends React.Component {
  onClickWord(event) {
    const {
      callback,
      dispatch,
      words,
    } = this.props;
    const selectedWord = words.find(word => word.get('key') === event.target.value);

    event.preventDefault();
    callback(selectedWord);
    dispatch.dismiss();
  }

  onClickAdd(event) {
    const { dispatch } = this.props;
    event.preventDefault();
    dispatch.activateGlossaryWordCreator();
  }

  render() {
    const { words } = this.props;
    const options = createOptions(words);
    return (
      <Modal>
        <Picker options={options} onOptionClick={e => this.onClickWord(e)}>
          <button type="button" onClick={e => this.onClickAdd(e)}>Add Word</button>
        </Picker>
      </Modal>
    );
  }
}

GlossaryWordPicker.propTypes = {
  callback: PropTypes.func.isRequired,
  dispatch: PropTypes.object.isRequired,
  words: PropTypes.instanceOf(List).isRequired,
};

const mapStateToProps = (state, { pageSetKey }) => {
  const words = getGlossaryWords(state, pageSetKey);
  return {
    pageSetKey,
    words,
  };
};

const mapDispatchToProps = (dispatch, { callback }) => ({
  dispatch: {
    activateGlossaryWordCreator: () => dispatch(activateGlossaryWordCreator(callback)),
    dismiss: () => dispatch(dismissModal()),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GlossaryWordPicker);
